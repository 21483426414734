import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Form from 'react-bootstrap/Form';
import AddNewaddress from '../../Common-Component/Commonmodal/AddNewaddress/AddNewaddress';
library.add(fas);

const Billing_information = ({ handleNext, setAddressSelect, addressSelect }) => {
    const [newAddress, setNewaddress] = useState(false);

    const address = [
        { id: 1, hometype: "Home", image: process.env.PUBLIC_URL + "/assest/image/Profile/map.png" },
        { id: 2, hometype: "Work", image: process.env.PUBLIC_URL + "/assest/image/Profile/map.png" },
    ];

    const handleNextStep = (data) => {
        setAddressSelect(data);
        handleNext();
    };

    const handleAddressSave = (data) => {
        setAddressSelect(data);
        setNewaddress(false);
        handleNext();
    };

    return (
        <>
            <section className='stepp-inner-main'>
                <div className='address-div'>
                    <div className='title-div'>
                        <h6 className='choss-add'>Choose Address</h6>
                    </div>
                    <div className='row mt-5'>
                        <Form.Check
                            type="checkbox"
                            label="Is shipping address same as billing address"
                            className='stepOnecheckbox mb-3'
                        />
                        {address.map((value, index) => (
                            <div className='col-lg-4 mb-3' key={index}>
                                <div className='addd-card'>
                                    <div className='row'>
                                        <div className='col-10'>
                                            <div className='card-flex'>
                                                <div className='loc-img-div'>
                                                    <img className='map-img' src={value.image} alt="map" />
                                                </div>
                                                <div className='cont-div'>
                                                    <h6 className='hom'>{value.hometype}</h6>
                                                    <p className='name'><b>John Doe</b></p>
                                                    <p className='adddd'>E/92 Apmc Market Yard, Turbhe, Vashi, Mumbai, Maharashtra - 400703</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-2 text-end'>
                                            <Form.Check type="radio" label="" name="address" />
                                            <p className='edit mb-0' onClick={handleNextStep} style={{ cursor: "pointer" }}>Edit</p>
                                        </div>
                                        <div className='dlttt-div'>
                                            <FontAwesomeIcon icon="fa-solid fa-trash-can" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className='col-lg-4 mb-3'>
                            <div className='addd-card-new-add'>
                                <button type='button' className='addnewaddbtn' onClick={() => setNewaddress(true)}>Add New Address</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="save-btn-div mt-5">
                    <button type='button' className="continue-btn" onClick={handleNextStep}>
                        Proceed
                    </button>
                </div>
            </section>
            <AddNewaddress show={newAddress} onHide={() => setNewaddress(false)} onSave={handleAddressSave} />
        </>
    );
};

export default Billing_information;
