import React, { useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Offcanvas from 'react-bootstrap/Offcanvas';
import "./FilterOffcanvas.css";
import { Form } from 'react-bootstrap';
import InputRange from 'react-input-range';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const FilterOffcanvas = (props) => {
    const [value, setValue] = useState(10);
    return (
        <>
            <Offcanvas show={props.show} onHide={props.handleClose} {...props}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <section className='Filteroffcanvas'>
                        <div className='Headeraccord'>
                            <div className="sidebar">
                                <Form>
                                    <Form.Group controlId="customizable">
                                        <Form.Check type="checkbox" label="Customizable (514)" />
                                    </Form.Group>
                                    <Form.Group controlId="freeShipping">
                                        <Form.Check type="checkbox" label="Free Shipping" />
                                    </Form.Group>

                                    <div className='productsBycat'>
                                        <h3 className='productsBycatHead'>Products by Category</h3>

                                        <div className='categoryItemssec'>
                                            <div className='categoryItem'>
                                                <p>Bags</p>
                                                <p className='lessthanicon'><FontAwesomeIcon icon="fa-solid fa-angle-right" /></p>
                                            </div>

                                            <div className='categoryItem'>
                                                <p>T-shirts</p>
                                                <p className='lessthanicon'><FontAwesomeIcon icon="fa-solid fa-angle-right" /></p>
                                            </div>
                                            <div className='categoryItem'>
                                                <p>Copperware</p>
                                                <p className='lessthanicon'><FontAwesomeIcon icon="fa-solid fa-angle-right" /></p>
                                            </div>

                                            <div className='categoryItem'>
                                                <p>Frames</p>
                                                <p className='lessthanicon'><FontAwesomeIcon icon="fa-solid fa-angle-right" /></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="price-range">
                                        <h3 className='productsBycatHead'>Price Range</h3>
                                        <InputRange
                                            maxValue={20}
                                            minValue={0}
                                            value={value}
                                            onChange={value => setValue(value)} />

                                    </div>

                                    <Form.Group controlId="quantity">
                                        <h3 className='productsBycatHead'>Quantity</h3>
                                        <div className='quantityform'>
                                            <Form.Control type="number" placeholder="Enter Quantity" />
                                            <div className='onfieldtxt'>
                                                <Form.Check
                                                    type="checkbox"
                                                    label="No min qty"
                                                />
                                            </div>

                                        </div>

                                    </Form.Group>

                                    <Accordion>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>
                                                Brand
                                            </Accordion.Header>
                                            <Accordion.Body >
                                                <div className='accordBodycontent'>
                                                    <p>Gift Wrap</p>
                                                    <p>Customisable</p>

                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>
                                                Colour
                                            </Accordion.Header>
                                            <Accordion.Body >
                                                <div className='accordBodycontent'>
                                                    <p>Gift Wrap</p>
                                                    <p>Customisable</p>

                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header>
                                                Occasion
                                            </Accordion.Header>
                                            <Accordion.Body >
                                                <div className='accordBodycontent'>
                                                    <p>Gift Wrap</p>
                                                    <p>Customisable</p>

                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="5">
                                            <Accordion.Header>
                                                Material
                                            </Accordion.Header>
                                            <Accordion.Body >
                                                <div className='accordBodycontent'>
                                                    <p>Gift Wrap</p>
                                                    <p>Customisable</p>

                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="6">
                                            <Accordion.Header>
                                                Categories
                                            </Accordion.Header>
                                            <Accordion.Body >
                                                <div className='accordBodycontent'>
                                                    <p>Gift Wrap</p>
                                                    <p>Customisable</p>

                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="7">
                                            <Accordion.Header>
                                                Deals & Discount
                                            </Accordion.Header>
                                            <Accordion.Body >
                                                <div className='accordBodycontent'>
                                                    <p>Gift Wrap</p>
                                                    <p>Customisable</p>

                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="8">
                                            <Accordion.Header>
                                                Ordering Options
                                            </Accordion.Header>
                                            <Accordion.Body >
                                                <div className='accordBodycontent'>
                                                    <p>Gift Wrap</p>
                                                    <p>Customisable</p>

                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Form>
                            </div>
                        </div>
                    </section>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default FilterOffcanvas