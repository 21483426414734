import React from 'react'
import CustomizableProduct from '../CustomizableProduct/CustomizableProduct'

const EmplyementSatisfaction = () => {
    return (
        <>
            <CustomizableProduct heading={"Employement Appreciation"} />
        </>
    )
}

export default EmplyementSatisfaction