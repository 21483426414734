import React from 'react'
import Table from 'react-bootstrap/Table';
import "./OrderTracking.css";
import Breadcrumbs from '../Common-Component/Breadcrumbs/Breadcrumbs';

const OrderTracking = () => {
    const items = [
        { id: 1, text: "Home", link: "/" },
        { id: 1, text: "Order tracking" },
    ]
    return (
        <section className='OrderTracking'>
            <div className='container'>
                <Breadcrumbs items={items} />
                <div className='trackTable'>
                    <Table responsive striped bordered hover>
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Order No.</th>
                                <th>Order Date</th>
                                <th>Delivery Date</th>
                                <th>Total</th>
                                <th>Order Status</th>
                                <th>Payment Type</th>
                                <th>Payment Status</th>
                                <th>Order Status</th>
                                <th>Order Progress</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Mark</td>
                                <td>Otto</td>
                                <td>@mdo</td>
                                <td>-</td>
                                <td>@mdo</td>
                                <td>@mdo</td>
                                <td style={{ color: "#43AA00" }}>Paid</td>
                                <td>Delivery</td>
                                <td>View</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Jacob</td>
                                <td>Thornton</td>
                                <td>@fat</td>
                                <td>-</td>
                                <td>@fat</td>
                                <td>@fat</td>
                                <td>Paid</td>
                                <td style={{ color: "#43AA00" }}>Delivery</td>
                                <td>View</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td >Larry the Bird</td>
                                <td>@twitter</td>
                                <td>@twitter</td>
                                <td>-</td>
                                <td>@twitter</td>
                                <td>@twitter</td>
                                <td style={{ color: "#EC0000" }}>Unpaid</td>
                                <td>Delivery</td>
                                <td>View</td>
                            </tr>
                        </tbody>
                    </Table>
                    <div className='showingentrytxt'>
                        <p>Showing 3 of 3 entries</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OrderTracking