import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import SuccessfullyModal from '../../Common-Component/Commonmodal/SuccessfullyModal/SuccessfullyModal'

const Order_review = ({ handleNext, handlePrevious, setAddressSelect, addressSelect }) => {
    const [orderplace, setOrderplace] = useState(false);
    const cart = [
        { id: 1, image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png", product_title: "Nike Dri-FIT Legend Tee - Men's", selling_price: 3000, mrp: 4500 },
    ]

    const placeOrder = () => {
        setOrderplace(true);
        setTimeout(() => {
            setOrderplace(false);
            window.location = "/home";
        }, 3000)
    }
    return (
        <>
            <section className='stepp-inner-main'>
                <div className='orde-status-dv'>
                    <div className='orderReviewmain'>
                        <div className=' colwidth'>
                            <p>Order Number</p>
                            <p>0005-12122023</p>
                        </div>
                        <div className=' colwidth'>
                            <p>Status</p>
                            <p>Ordered</p>
                        </div>
                        <div className=' colwidth'>
                            <p>Date</p>
                            <p>{new Date().toLocaleDateString('en-US', {
                                day: 'numeric', month: 'short', year: 'numeric'
                            })}</p>
                        </div>
                        <div className=' colwidth'>
                            <p>Total</p>
                            <p>₹. 5000</p>
                        </div>
                        <div className=' colwidth'>
                            <p>Payment method</p>
                            <p>Razorpay Transfer</p>

                        </div>
                    </div>
                </div>

                <div className='orderr-dettt'>
                    <h6 className='jhber'>Order Details</h6>

                    <div className='row'>

                        {cart?.map((post, index) => (
                            <>
                                <div className='col-lg-6 col-md-6 col-sm-8 col-10 detailssss-flex mt-3' key={post.id}>

                                    <div className='img-div'>
                                        <img className='userimgg' src={post?.image} alt="Product" />
                                    </div>
                                    <div className='contdivvv'>
                                        <p className='name'>{post.product_title}</p>
                                        {/* <p className='sizenumm'>38</p> */}
                                    </div>

                                </div>

                                <div className='col-lg-6 col-md-6 col-sm-4 col-2 my-auto'>
                                    <p className='mb-0 Prodprice' >₹000</p>
                                </div>

                            </>
                        ))}


                    </div>


                    <div className='row orde-status-dv'>
                        <div className='col-lg-8'></div>
                        <div className='col-lg-4 text-end'>
                            <Row>
                                <Col lg={6} md={10}>
                                    <p className='mb-0'>Subtotal</p>

                                </Col>

                                <Col lg={6} md={2}>
                                    <p className='mb-0'><strong>₹. 000</strong></p>

                                </Col>
                            </Row>

                        </div>
                    </div>


                    <div className='totalprieee'>
                        <div className='row mb-4'>
                            <div className='col-lg-8 col-6'>
                                <h6>Shipping & Handling (Flat Rate)</h6>
                            </div>
                            <div className='col-lg-4'>
                                <div className='row flatrateMain'>
                                    <div className='col-lg-6 col-6'>
                                        <h6 className='flatRate'>Flat Rate</h6>
                                    </div>
                                    <div className='col-lg-6 col-6'>
                                        <h6 className='itemPrice'>₹. 00.00</h6>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className='row mb-4'>
                            <div className='col-lg-8 col-6'>
                                <h6>Payment Method</h6>
                            </div>
                            <div className='col-lg-4 col-6'>
                                <h6 className='razorpaytxt'>Razorpay Transfer</h6>
                            </div>

                        </div>
                        <div className='row totalbg mb-3'>
                            <div className='col-lg-8 col-6'>
                                <h6><b>Total</b></h6>
                            </div>

                            <div className='col-lg-4 col-6'>
                                <h6 className='totalorderPrice'>₹. 5000</h6>
                            </div>
                        </div>

                        <p className='earnPoints'>You will Earn: 71 Points</p>

                        <div className='row mb-4'>
                            <Col lg={6} md={6}>
                                <h6><b>Billing Address</b></h6>
                                <hr></hr>

                                <p className='finaladddd'>1st Floor, Office No 1, M No. 734 Opp High Class Society, Dr. Homi Bhabha Rd, near Suryadutta College, Bavdhan, Pune, Maharashtra 411021</p>

                            </Col>
                            <Col lg={6} md={6}>
                                <h6><b>Shipping Address</b></h6>
                                <hr></hr>
                                <p className='finaladddd'>1st Floor, Office No 1, M No. 734 Opp High Class Society, Dr. Homi Bhabha Rd, near Suryadutta College, Bavdhan, Pune, Maharashtra 411021</p>
                            </Col>
                        </div>
                    </div>

                </div>

                <div className="save-btn-div mt-5">
                    <button type='button' className="back-btn" onClick={handlePrevious}>Back</button>
                    <button type='button' className="placeOrderbtn" onClick={placeOrder}>Place Order</button>
                    <p className='editforgettxt'>
                        <Link to={"/mycart"}>
                            <span>Forgot an Item?</span> <span>Edit Your Cart</span>
                        </Link>
                    </p>
                </div>
            </section >

            <SuccessfullyModal show={orderplace}
                onHide={() => setOrderplace(false)} text={"Order Placed Successfully"} />
        </>
    )
}

export default Order_review
