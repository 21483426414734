import React from 'react'
import "./RelevantNews.css"
import Breadcrumbs from '../Common-Component/Breadcrumbs/Breadcrumbs'
const RelevantNews = () => {
    const items = [
        { id: 1, text: "HOME" },
        { id: 1, text: "BLOG" },
    ]
    return (
        <>
            <section className='RelevantNews'>
                <div className='container'>
                <Breadcrumbs items={items} />

                    <div className='row'>
                        <div className='col-lg-7'>
                            <div className='articlmainn'>
                            <div className='articleshead'>
                                <h3>
                                    <span>Latest Updates</span>
                                    <span className='nevermiss'>You Never Want to Miss</span>
                                </h3>

                            </div>
                            <div className='articlesImg1'>
                                <img src={process.env.PUBLIC_URL + "/assest/image/home/articlesImg2.png"} />
                                <div className='overlay-color'></div>
                                <div className='imageinfo1'>
                                    <h3>Luxury Articles to Gift</h3>
                                    <p>Indulge in the art of gifting with our exquisite selection of luxury articles,<br /> meticulously curated to elevate any occasion.</p>
                                    <p>By <span style={{color: "#234C9E"}}>Samantha</span>  and <span style={{color: "#234C9E"}}>William</span> </p>
                                </div>

                            </div>
                            </div>

                        </div>
                        <div className='col-lg-5'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className='articlesImg2'>
                                        <img src={process.env.PUBLIC_URL + "/assest/image/home/articlesImg1.png"} />

                                        <div className='imageinfo2'>
                                            <span>01 JUN 2024</span>
                                            <p>Luxury Articles to Gift Antique Collections</p>
                                            <p>By <span style={{color: "#234C9E"}}>Samantha</span>  and <span style={{color: "#234C9E"}}>William</span> </p>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-lg-6'>
                                    <div className='articlesImg2'>
                                        <img src={process.env.PUBLIC_URL + "/assest/image/home/articlesImg1.png"} />
                                        <div className='imageinfo2'>
                                            <span>01 JUN 2024</span>
                                            <p>Gift a piece of history with our timeless coin collections, each one a tangible...</p>
                                            <p>By <span style={{color: "#234C9E"}}>Samantha</span>  and <span style={{color: "#234C9E"}}>William</span> </p>
                                        </div>
                                    </div>

                                </div>

                                <div className='col-lg-6'>
                                    <div className='articlesImg2'>
                                        <img src={process.env.PUBLIC_URL + "/assest/image/home/articlesImg1.png"} />
                                        <div className='imageinfo2'>
                                            <span>01 JUN 2024</span>
                                            <p>Gift a piece of history with our timeless coin collections, each one a tangible...</p>
                                            <p>By <span style={{color: "#234C9E"}}>Samantha</span>  and <span style={{color: "#234C9E"}}>William</span> </p>
                                        </div>
                                    </div>

                                </div>

                                <div className='col-lg-6'>
                                    <div className='articlesImg2'>
                                        <img src={process.env.PUBLIC_URL + "/assest/image/home/articlesImg1.png"} />
                                        <div className='imageinfo2'>
                                            <span>01 JUN 2024</span>
                                            <p>Gift a piece of history with our timeless coin collections, each one a tangible...</p>
                                            <p>By <span style={{color: "#234C9E"}}>Samantha</span>  and <span style={{color: "#234C9E"}}>William</span> </p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RelevantNews