import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import RewardDetails from '../../RewardDetails/RewardDetails';

const Payment_info = ({ handleNext, handlePrevious, setCheckout, checkout }) => {
    const [selectedPayment, setSelectedPayment] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [showRewardDetails, setShowRewardDetails] = useState(false);

    const handlePaymentChange = (event) => {
        setSelectedPayment(event.target.value);
    };

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleLearnMoreClick = () => {
        setShowRewardDetails(true);
        setCheckout(false);
    };

    return (
        <>
            <section className='stepp-inner-main'>
                <div className='row'>
                    {
                        !showRewardDetails && (
                            <div className='col-lg-10 mx-auto'>
                                <Row className='step4check'>
                                    <Col lg={6} md={6}>
                                        <div className='bankTransfer'>
                                            <Form.Check
                                                type="radio"
                                                label="Bank Transfer Payment"
                                                name="paymentMethod"
                                                value="bankTransfer"
                                                checked={selectedPayment === 'bankTransfer'}
                                                onChange={handlePaymentChange}
                                            />

                                        </div>
                                    </Col>
                                    <Col lg={6} md={6}>
                                        <div className='razorpaycheck'>
                                            <Form.Check
                                                type="radio"
                                                label="Razorpay Checkout"
                                                name="paymentMethod"
                                                value="razorpay"
                                                checked={selectedPayment === 'razorpay'}
                                                onChange={handlePaymentChange}
                                            />

                                        </div>
                                    </Col>
                                </Row>
                                {selectedPayment === 'bankTransfer' && (
                                    <div className='row'>
                                        <Col lg={6}>
                                            <div className='bankInput'>
                                                <Form.Check
                                                    type='checkbox'
                                                    label={
                                                        <>
                                                            <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                                                                <Form.Control type="email" placeholder="Enter your email" />
                                                            </Form.Group>
                                                        </>
                                                    }
                                                    onChange={handleCheckboxChange}
                                                />
                                                {isChecked && (
                                                    <div>
                                                        <p className='step5Paymentlink'>
                                                            <span>Each of 1 Point gets ₹1.00 discount. You need to earn more 1 point(s) to use this rule.</span>
                                                            <span className='click-here' onClick={handleLearnMoreClick} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
                                                                Please click here to learn about it.
                                                            </span>
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        </Col>
                                    </div>
                                )}
                                {selectedPayment === 'razorpay' && (
                                    <Row>
                                        <Col xl={6} className='ms-auto'>
                                            <div className='razorpayDetails'>
                                                <p>You will be redirected to Razorpay Checkout page when you place an order.</p>
                                            </div>

                                        </Col>
                                    </Row>
                                )}
                            </div>
                        )
                    }
                </div>
                {!showRewardDetails && (
                    <div className="save-btn-div mt-5">
                        <button type='button' className="back-btn" onClick={handlePrevious}>Back</button>
                        <button type='button' className="continue-btn" onClick={handleNext}>Continue</button>
                    </div>
                )}
            </section>

            {showRewardDetails && <RewardDetails />}
        </>
    );
}

export default Payment_info;
