import React, { useContext, useEffect, useState } from 'react'
import { fas } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
library.add(fas);



const PersonalInformation = ({ handleNext, handlePrevious, setAddressSeAddNewaddresslect, addressSelect }) => {
    const [isSaved, setIsSaved] = useState(false);
    console.log(addressSelect);
    return (
        <>
            <section className='stepp-inner-main'>

                <Row>
                    <Col lg={6} className='mx-auto'>
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>First Name*</Form.Label>
                                <Form.Control type="text" placeholder="name@example.com" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Last Name*</Form.Label>
                                <Form.Control type="text" placeholder="name@example.com" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control type="email" placeholder="name@example.com" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Password*</Form.Label>
                                <Form.Control type="password" placeholder="name@example.com" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Confirm Password*</Form.Label>
                                <Form.Control type="password" placeholder="name@example.com" />
                            </Form.Group>

                        </Form>
                    </Col>
                </Row>
                <div className="save-btn-div">
                    <Common_Button onClick={handleNext} type='button' text={isSaved ? 'SAVE & NEXT' : 'Next'} className="continue-btn" />


                </div>
                <div className="signup-link">
                    <p>
                        By signing up, I agree to the{" "}
                        <Link to={"/terms-condition"}>
                            Terms and Conditions
                        </Link>
                    </p>

                </div>
                <div className="social-login pb-5">
                    <p>You can also login by using your</p>
                    <div className="social-icons">
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/assest/image/login/facebook.png"
                            }
                            alt="Google"
                        />
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/assest/image/login/google.png"
                            }
                            alt="Google"
                        />
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/assest/image/login/twitter.png"
                            }
                            alt="Google"
                        />
                    </div>
                </div>


            </section>



        </>
    )
}

export default PersonalInformation