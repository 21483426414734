import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Welcomepage from '../Welcomepage/Welcomepage'
import Home from '../home/Home'
import Login from '../Login/Login'
import Register from '../Register/Register'
import Terms_condition from '../Terms_condition/Terms_condition'
import Profile from '../Profile/Profile'
import MyOrders from '../MyOrders/MyOrders'
import ProductDetails from '../ProductDetails/ProductDetails'
import MyCart from '../MyCart/MyCart'
import RelevantNews from '../RelevantNews/RelevantNews'
import Offers from '../Offers/Offers'
import CustomizableProduct from '../CustomizableProduct/CustomizableProduct'
import ReturnOrder from '../ReturnOrder/ReturnOrder'
import Payment_checkout from '../payment-checkout-form/Payment_checkout'
import Wishlist from '../Wishlist/Wishlist'
import ProductCustomize from '../ProductCustomize/ProductCustomize'
import ForgetPassword from '../Login/ForgetPassword/ForgetPassword'
import SubmitSuggestion from '../SubmitSuggestion/SubmitSuggestion'
import OrderTracking from '../OrderTracking/OrderTracking'
import ShopPromotional from '../ShopPromotional/ShopPromotional'
import Blogs from '../Blogs/Blogs'
import BlogDetails from '../Blogs/BlogDetails/BlogDetails'
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy'
import ContactUs from '../Contact-us/ContactUs'
import FestiveGifts from '../FestiveGifts/FestiveGifts'
import EmplyementSatisfaction from '../EmplyementSatisfaction/EmplyementSatisfaction'
import SustainableProduct from '../SustainableProduct/SustainableProduct'
import LegalNotice from '../LegalNotice/LegalNotice'
import AboutUs from '../AboutUs/AboutUs'
import Faq from '../Faq/Faq'
import AllCategories from '../AllCategories/AllCategories'
import GiftCustomization from '../GiftCustomization/GiftCustomization'
import ProductCare from '../ProductCare/ProductCare'
import Responsibilites from '../Responsibilites/Responsibilites'
import MadetoOrder from '../MadetoOrder/MadetoOrder'
import RequestQuotation from '../RequestQuotation/RequestQuotation'
import GiftAutomation from '../GiftAutomation/GiftAutomation'
import MultiRecipient from '../MultiRecipient/MultiRecipient'
import Careers from '../Careers/Careers'
import RewardDetails from '../RewardDetails/RewardDetails'
import PromotionalProduct from '../PromotionalProduct/PromotionalProduct'
import Mob_search from '../home/mobsearch/Mob_search'
import PageNotfound from '../PageNotfound/PageNotfound'

const AllRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<Welcomepage />} />
                <Route path="/home" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/terms-condition" element={<Terms_condition />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/myOrders" element={<MyOrders />} />
                <Route path="/productDetails" element={<ProductDetails />} />
                <Route path="/mycart" element={<MyCart />} />
                <Route path="/relevantNews" element={<RelevantNews />} />
                <Route path="/offers" element={<Offers />} />
                <Route path="/customizable-product" element={<CustomizableProduct heading={"Customizable Product"} />} />
                <Route path="/returnOrder" element={<ReturnOrder />} />
                <Route path="/checkout" element={<Payment_checkout />} />
                <Route path="/wishlist" element={<Wishlist />} />
                <Route path="/product-customize" element={<ProductCustomize />} />
                <Route path="/forgetpassword" element={<ForgetPassword />} />
                <Route path="/submitsuggestion" element={<SubmitSuggestion />} />
                <Route path="/order-tracking" element={<OrderTracking />} />
                <Route path="/shop-promotional" element={<ShopPromotional />} />
                <Route path="/blogs" element={<Blogs />} />
                <Route path="/blog-details" element={<BlogDetails />} />
                <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                <Route path="/contactus" element={<ContactUs />} />
                <Route path="/festivegifts" element={<FestiveGifts />} />
                <Route path="/emp-satifaction" element={<EmplyementSatisfaction />} />
                <Route path="/sustainable-product" element={<SustainableProduct />} />
                <Route path="/legalNotice" element={<LegalNotice />} />
                <Route path="/aboutUs" element={<AboutUs />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/all-category" element={<AllCategories />} />
                <Route path="/gift-customization" element={<GiftCustomization />} />
                {/* <Route path="/product-care" element={<ProductCare />} /> */}
                <Route path="/responsibility" element={<Responsibilites />} />
                <Route path="/made-to-order" element={<MadetoOrder />} />
                <Route path="/request-quotation" element={<RequestQuotation />} />
                <Route path="/multi-recipient" element={<MultiRecipient />} />
                <Route path="/gift-automation" element={<GiftAutomation />} />
                <Route path="/careers" element={<Careers />} />
                <Route path="/promotional-product" element={<PromotionalProduct />} />
                <Route path="/search-products" element={<Mob_search />} />
                <Route path="/pageNotfound" element={<PageNotfound />} />
            </Routes>

        </>
    )
}

export default AllRoutes