import React, { useState } from 'react'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import "./RewardDetails.css";
import { Button, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate } from 'react-router-dom';
import SuccessfullyModal from '../Common-Component/Commonmodal/SuccessfullyModal/SuccessfullyModal';
const RewardDetails = ({ setCheckout }) => {
    const navigate = useNavigate();
    const [successshow, setsuccessshow] = useState(false);

    const saveClick = () => {
        setsuccessshow(true);
        // setTimeout(() => {
        //     setsuccessshow(false);
        //     setCheckout(true)
        // }, 3000)
    }
    return (
        <>
            <section className='RewardDetails'>
                <div className='container'>


                    <div className='rewardMaintabs'>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="1">

                            <Nav variant="pills" className="flex-row rewardNavvv">
                                <Nav.Item>
                                    <Nav.Link eventKey="1">My Rewards</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="2">Point Transactions</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="3">Settings</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <p style={{ fontSize: "12px", cursor: "pointer" }} onClick={() => setCheckout(true)}>BACK</p>
                            <Tab.Content>
                                <Tab.Pane eventKey="1">
                                    <div className='RewardInfo mb-4'>
                                        <h3>My Rewards</h3>
                                    </div>
                                    <div className='Tab-first'>

                                        <div className='RewardInfo'>
                                            <h3><img src={process.env.PUBLIC_URL + "/assest/image/Careers/circleStar.png"} className='circleStar' />Reward information</h3>
                                            <p>Your balance is 0 Point</p>
                                        </div>

                                        <div className='spendPoints mb-5'>
                                            <h3>HOW TO EARN POINTS</h3>
                                            <p>Points will be added to your reward balance after you take certain activities. For example, every time you make a purchase you will earn points based on the price of products purchased.</p>
                                            <ul className='spendList'>
                                                <li>Each ₹100.00 spent for your order will earn 2 Points.</li>
                                            </ul>
                                        </div>
                                        <div className='spendPoints'>
                                            <h3>HOW TO SPEND POINTS</h3>
                                            <p>You can use points in your reward balance as discount for your future purchases at our store. Please note that redeeming to cash is not allowed.</p>
                                            <ul className='spendList'>
                                                <li>Each 1 Point can be redeemed for ₹1</li>
                                            </ul>
                                        </div>

                                        <div className='recentTransaction '>
                                            <h3 className='tabhead'>
                                                <img src={process.env.PUBLIC_URL + "/assest/image/Careers/arrowside.png"} className='circleStar' />Recent Transactions
                                            </h3>
                                            <Table bordered responsive>
                                                <thead>
                                                    <tr>
                                                        <th>Sr. No.</th>
                                                        <th>Title</th>
                                                        <th>Points</th>
                                                        <th>Earning Date</th>
                                                        <th>Expiration Date</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Coinzkart1</td>
                                                        <td>2 Points</td>
                                                        <td>5/1/2024</td>
                                                        <td>4/2/2024</td>
                                                        <td className='validtxt'>Valid</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>Jacob</td>
                                                        <td>Thornton</td>
                                                        <td>5/1/2024</td>
                                                        <td>4/2/2024</td>
                                                        <td className='Invalidtxt'>InValid</td>
                                                    </tr>

                                                </tbody>
                                            </Table>

                                        </div>
                                    </div>

                                </Tab.Pane>
                                <Tab.Pane eventKey="2">
                                    <div className='Tab-second'>
                                        <h3 className='tabhead'>
                                            <img src={process.env.PUBLIC_URL + "/assest/image/Careers/arrowside.png"} className='iconImg' />Point Transactions
                                        </h3>

                                        <div className='Tabsecondtable'>
                                            <Table bordered responsive>
                                                <thead>
                                                    <tr>
                                                        <th>Sr. No.</th>
                                                        <th>Action</th>
                                                        <th>Point Balance Change</th>
                                                        <th>Earning Date</th>
                                                        <th>Expiration Date</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Mark</td>
                                                        <td>Otto</td>
                                                        <td>@mdo</td>
                                                        <td>@mdo</td>
                                                        <td>@mdo</td>
                                                    </tr>


                                                </tbody>
                                            </Table>

                                        </div>

                                        <p className='tabhead'> <img src={process.env.PUBLIC_URL + "/assest/image/Careers/triangle.png"} className='iconImg' />No transaction found!</p>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="3">
                                    <div className='Tab-third'>
                                        <div className='headddd'>
                                            <h3 className='tabhead'><FontAwesomeIcon icon="fa-solid fa-gear" className="me-2" /> Reward Points Settings</h3>

                                        </div>

                                        <div className='pointsubscribe'>
                                            <h3>REWARD POINTS SUBSCRIPTIONS</h3>

                                            <Form.Check type='checkbox' label={
                                                <>
                                                    <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                                        <Form.Control type="email" />
                                                    </Form.Group>

                                                </>
                                            } />

                                            <Form.Check type='checkbox' label={
                                                <>
                                                    <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                                                        <Form.Control type="email" />
                                                    </Form.Group>

                                                </>
                                            } />
                                        </div>

                                        <div className='savebtn text-center'>
                                            <Button className="btn" type="button" onClick={saveClick}>Save</Button>
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>

                        </Tab.Container>

                        <SuccessfullyModal show={successshow} onHide={() => setsuccessshow(false)} text={'Your Settings has Been Updated Successfully!'} />
                    </div>
                </div>
            </section>
        </>
    )
}

export default RewardDetails