import React from 'react'
import "./PrivacyPolicy.css";
const PrivacyPolicy = () => {
    return (
        <>
            <section className='Privacypolicy pb-5'>
                <div className='container'>
                    <div className='PrivacyHead mb-5'>
                        <h3>Privacy Policy</h3>
                        <p>The website ("Website") is owned and operated by MCW.com, Inc. ("MCW"). MCW acknowledges the significance of upholding the privacy of our corporate customers ("Customers"), gift recipients ("Recipients"), and our sellers of those gifts ("Sellers"), collectively referred to as "Users." MCW publishes this privacy policy ("Policy") to outline our procedures concerning the gathering and utilization of Information from Users through both our ecommerce Marketplace and Gifting Platform software ("Services").</p>

                        <p> We reserve the right to modify our Policy periodically. In case substantial alterations are made to this Policy impacting our Gifting Platform corporate accounts, we will strive to notify you directly via email or within your Account before the change takes effect. By utilizing our Services, you implicitly agree to this Policy and any forthcoming amendments. If you do not agree to this Privacy Policy, you must deactivate your account and discontinue using MCW Services.</p>
                    </div>

                    <div className='Privacyquestion mb-5'>
                        <h3>1. WHAT INFORMATION WE COLLECT</h3>
                        <p>Creating an account at MCW is entirely voluntary. Should you choose to create an account with MCW, you will be requested to provide certain personally identifiable information (PII), such as your name, address, phone number, email address, username, password, and company of employment ("Information"). Additionally, we collect the physical and/or email addresses of your registered Recipients. By providing Information, you confirm that it is accurate & authentic, and that you have the authorization to share it with MCW and our third-party Marketplace sellers involved in fulfilling your orders.</p>

                        <p>  MCW gathers Information about you through various means, including when you send an E-Gift, place an order, or browse the Website. We may also automatically collect Information using cookie technology or web beacons to capture your location or IP address when you interact with our website or social media platforms. Periodically, MCW might contact you via email, postal mail, or phone to conduct questionnaires and surveys.</p>

                        <p> Survey Information is handled similarly to other Information. If you reach out to us by phone for customer support, please note that such calls may be recorded for quality assurance and training purposes.</p>
                    </div>


                    <div className='Privacyquestion mb-5 '>
                        <h3>2. ACCESS TO & CHOICES ABOUT YOUR INFORMATION</h3>
                        <p>You have the option to: (1) opt out of receiving promotional materials via mail, phone, or email; (2) access, review, verify, update, or rectify your personal Information; or (3) deactivate your account. To exercise any of these options, just get in touch with MCW, as detailed herein. You can unsubscribe from marketing emails by visiting the Communication Preferences page.</p>
                    </div>

                    <div className='Privacyquestion mb-5'>
                        <h3>3. SECURITY</h3>
                        <p>The security of your personal information is a priority for us. Our Marketplace and Gifting Platform comply with ISO 27001 standards. The ISO 27001 certification, conducted by an accredited certification body, confirms our adherence to international standards for information security management and data protection. MCW has implemented technical, administrative, and physical security measures to prevent unauthorized access or use of your Information. We also ensure secure data transmission of payment information, such as credit card numbers & bank transfers, through encryption and PCI compliance within our checkout Service. However, it's important to note that no electronic transmission or storage method is entirely foolproof, so we cannot guarantee the absolute security of any Information you provide, and you do so at your own discretion.</p>

                        <p> Users bear the responsibility of maintaining the confidentiality of their password and account, as well as creating strong passwords, updating them regularly, and logging out of services when not in use, especially in shared or insecure environments. Users are accountable for any activities conducted under their account and should promptly notify MCW of any unauthorized use.</p>

                        <p>MCW is not accountable for the policies and practices of third-party websites or our Marketplace sellers. If you have inquiries concerning the privacy policies of any seller in our Marketplace, please don't hesitate to contact us.</p>
                    </div>


                </div>
            </section>
        </>
    )
}

export default PrivacyPolicy