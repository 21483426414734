import React, { useContext, useEffect, useState } from "react";
import './SellerStepform.css'
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, } from "@fortawesome/free-solid-svg-icons";
import Address from "./step3/Address";
import PersonalInformation from "./step1/PersonalInformation";
import SellerInformation from "./step2/SellerInformation";

const SellerStepform = () => {
    const [current, setCurrent] = useState(1);
    const [addressSelect, setAddressSelect] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("online");
    const stepLabels = [
        { id: 1, label: "Payment Information" },
        { id: 1, label: "Seller Information" },
        { id: 1, label: "Address" },

    ]


    const handleNext = () => {
        if (current < 5) {
            setCurrent(current + 1);
        }
    };

    const handlePrevious = () => {
        if (current > 1) {
            setCurrent(current - 1);
        }
    };

    const isStepCompleted = (step) => {
        return step < current;
    };

    const breadcrumbItems = [
        { text: "Home", link: "/product-cart-inner", className: "active" },
        { text: "My Cart", link: "/product-cart-inner", className: "active" },
        { text: "Checkout", link: "/product-cart-inner", className: "active" },
    ];




    return (
        <>
            <section className='SellerStepform'>
                <div className="container">
                    <div className="">
                        <section className="Payment_stepform_sec">
                            <Form>
                                <div className="signup-form ">
                                    <div className="progress-bar-container">
                                        <div className="step-row">

                                            {stepLabels.map((item, index) => (
                                                <div key={index + 1} className={`step-container ${index + 1 === current ? "active" : ""}`}>
                                                    <div className="main-flex-div">
                                                        <div className="circle-center">
                                                            <div
                                                                className={`line ${isStepCompleted(index + 1)
                                                                    ? "completed"
                                                                    : ""
                                                                    } ${index + 1 === current ? "active" : ""}`}
                                                            >
                                                                <FontAwesomeIcon icon={faCheck} className="checkIcon" />
                                                            </div>
                                                        </div>
                                                        <div className="centerDivcircle">
                                                            <span className={`step-text ${isStepCompleted(index + 1) ? "completed" : ""} ${index + 1 === current ? "active" : ""}`}>{item.label}</span>
                                                        </div>
                                                    </div>
                                                    <div className='circle-center'>
                                                        <div className={`circle ${isStepCompleted(index + 1) ? "completed" : ""}`}></div>
                                                    </div>

                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="wizard-form mt-3">
                                    {current === 1 && (
                                        <PersonalInformation handleNext={handleNext} addressSelect={addressSelect} setAddressSelect={setAddressSelect} />
                                    )}
                                    {current === 2 && (
                                        <SellerInformation
                                            handleNext={handleNext}
                                            handlePrevious={handlePrevious}
                                            addressSelect={addressSelect}
                                            paymentMethod={paymentMethod}
                                            setPaymentMethod={setPaymentMethod}
                                        />
                                    )}
                                    {current === 3 && (
                                        <Address
                                            handleNext={handleNext}
                                            handlePrevious={handlePrevious}
                                            addressSelect={addressSelect}
                                            paymentMethod={paymentMethod}
                                        />
                                    )}

                                </div>
                            </Form>
                        </section>
                    </div>

                </div>
            </section>
        </>
    )
}

export default SellerStepform