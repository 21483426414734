import React, { useState } from 'react'
import "./ReturnOrder.css";
import { Button } from 'react-bootstrap';
import { Rating } from 'react-simple-star-rating'
import Returnmodal from '../Common-Component/Commonmodal/Returnmodal/Returnmodal';
import Ordercancel from '../Common-Component/Commonmodal/Ordercancel/Ordercancel';
import Breadcrumbs from '../Common-Component/Breadcrumbs/Breadcrumbs';
import { Link } from 'react-router-dom';
const ReturnOrder = () => {
    const [rating, setRating] = useState(0)
    const [showReturn, setShowreturn] = useState(false);
    const [showCancel, setShowCancel] = useState(false);

    const handleRating = (rate: number) => {
        setRating(rate)
    }

    const items = [
        { id: 1, text: "PROFILE", },
        { id: 1, text: "RETURN ORDER", }
    ]
    return (
        <>
            <section className='ReturOrder'>
                <div className='container'>
                    <Breadcrumbs items={items} />
                    <div className='row mt-5'>
                        <div className='col-lg-3'>
                            <div className='ProductImgsec'>
                                <img src={process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png"} className='returnproductImg' />
                            </div>
                        </div>
                        <div className='col-lg-9'>
                            <div className='returnOrderdetails'>
                                <div className='row ProductTitle'>
                                    <div className='col-lg-6'>
                                        <div className='productHead'>
                                            <h3>Nike Dri-FIT Legend Tee - Men's</h3>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 text-end'>
                                        <div className='viewproductDetailsbtn'>
                                            <Link to={"/productDetails"}>
                                                <Button className='btn' type='button'>View Product Details</Button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <div className='row ProductTitle'>
                                    <div className='col-lg-6'>
                                        <div className='productHead'>
                                            <h3>Order Number: 0005-12122023</h3>
                                            <p>Payment : Online</p>
                                            <p>Rate a Product : <span className='ms-3'><Rating onClick={handleRating} initialValue={rating} size={20} /></span> </p>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 text-end'>
                                        <div className='return-cancel-btn'>
                                            <Button className='btn me-3' type='button' onClick={() => setShowreturn(true)}>Return Order</Button>
                                            <Button className='btn' type='button' onClick={() => setShowCancel(true)}>Cancel Order</Button>
                                        </div>
                                    </div>
                                </div>

                                <div className='OrderTracking'>
                                    <h3>Order Tracking</h3>

                                    <div className='TrackingProcess'>
                                        <div className='Order'>
                                            <div className='orderIconImg'>
                                                <div className='orderIcon'>
                                                    <img src={process.env.PUBLIC_URL + "/assest/image/ReutnOrder/Icon.png"} />

                                                </div>
                                                <div className='orderplacehead'>
                                                    <h3>Order Placed</h3>

                                                </div>
                                            </div>
                                            <p>9 Jun, 2024, 11:45 am</p>
                                        </div>
                                        <hr className='dottedLine' />
                                        <div className='Order'>
                                            <div className='orderIconImg'>
                                                <div className='orderIcon'>
                                                    <img src={process.env.PUBLIC_URL + "/assest/image/ReutnOrder/Icon.png"} />

                                                </div>
                                                <div className='orderplacehead'>
                                                    <h3>Shipping</h3>

                                                </div>
                                            </div>
                                            <p>9 Jun, 2024, 11:45 am</p>
                                        </div>
                                        <hr className='dottedLine' />
                                        <div className='Order'>
                                            <div className='orderIconImg'>
                                                <div className='orderIcon'>
                                                    <img src={process.env.PUBLIC_URL + "/assest/image/ReutnOrder/Icon.png"} />

                                                </div>
                                                <div className='orderplacehead'>
                                                    <h3>Delivery On</h3>

                                                </div>
                                            </div>
                                            <p>9 Jun, 2024, 11:45 am</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='OrderSummary'>
                                    <div className='OrderDetailshead'>
                                        <h3>Order Details</h3>
                                    </div>

                                    <div className='row summaryDetails'>
                                        <div className='col-lg-5'>
                                            <div className='Pricedetailsmain'>
                                                <h3>Price Details :</h3>
                                                <p>1 item</p>
                                            </div>

                                            <div className='Pricedetailsmain'>
                                                <h3>Total Price :</h3>
                                                <p>₹ 0000</p>
                                            </div>
                                            <div className='Pricedetailsmain'>
                                                <h3>Total Additional Charges :</h3>
                                                <p>₹ 50</p>
                                            </div>

                                        </div>

                                    </div>
                                    <div className='text-end grossPrice'>
                                        <p>Gross Total Price : <span>₹ 0000</span> </p>
                                    </div>


                                </div>

                                <div className='Delivery_status'>
                                    <h3>Delivery Address</h3>
                                    <p>Shubhamkumar Yadav (9876543210)</p>
                                    <p>Teerth Techno Space, Unit B-505, 5th Floor, Pune Banglore Highway Pashan Exit, Baner, Pune, Maharashtra 411045, India</p>
                                </div>

                                <div className='Delivery_status'>
                                    <h3>Support</h3>
                                    <p>Coinzkart</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Returnmodal show={showReturn} onHide={() => setShowreturn(false)} />
            <Ordercancel show={showCancel} onHide={() => setShowCancel(false)} />
        </>
    )
}

export default ReturnOrder