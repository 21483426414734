import React, { useState } from 'react'
import "./ProductCustomize.css";
import { Button, Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import PhoneInput from 'react-phone-input-2';
import { Link } from 'react-router-dom';
import { Uploader } from 'uploader';
import { UploadButton } from 'react-uploader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const ProductCustomize = () => {
    const [value, setValue] = useState()
    const [selectedOption, setSelectedOption] = useState(null);

    const [customdesign, setCustomdesign] = useState(false);
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
    ];


    const handleClick = () => {
        setCustomdesign(true)
    }

    const uploader = Uploader({ apiKey: 'free' });
    const uploadOptions = { multi: true };
    return (
        <>
            <section className='productCustomize'>
                <div className='container'>
                    <div className='customizeMain'>
                        <div className='row'>
                            <div className='col-lg-3 col-md-5'>
                                <div className='customizeLeft'>
                                    <div className='productImg'>
                                        <img src={process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png"} />
                                    </div>

                                    <div className='productTitle'>
                                        <h3><strong>Nike Dri-FIT Legend Tee - Men's</strong></h3>
                                    </div>

                                    <div className='productdetailMain'>
                                        <div className='productdetail'>
                                            <p>MEDIUM x 24</p>
                                            <p>₹ 0000</p>
                                        </div>
                                        <div className='productdetail'>
                                            <div>
                                                <p className='mb-0'>SETUP CHARGES</p>
                                                <span>(Applicable on your added image)</span>

                                            </div>
                                            <p>₹ 0000</p>
                                        </div>
                                        <div className='productdetail'>
                                            <p><strong>Total</strong></p>
                                            <p><strong>₹ 0000</strong></p>
                                        </div>

                                        <div className='addtoCartsec'>
                                            <Link to={"/mycart"}>
                                                <Button className='addtocartbtn' >Add to cart</Button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-9 col-md-7'>

                                <>
                                    <div className='customizeRight'>
                                        <div className='customizeHead'>
                                            <h3>Customize Your T-Shirt</h3>
                                        </div>

                                        <div className='formRow'>
                                            <Form>
                                                <div className='formRowhead'>
                                                    <h3>Nike Dri-FIT Legend Tee - Men's</h3>
                                                    <p>₹ 1,962.00</p>
                                                </div>
                                                <Row>
                                                    <Col lg={6} md={6}>
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                            <Form.Control type="email" placeholder="Enter Name" />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={6} md={6}>
                                                        <PhoneInput
                                                            country="in"
                                                            value={value}
                                                            onChange={setValue}
                                                            placeholder='Enter Contact No.'
                                                            className="mb-3"

                                                        />

                                                    </Col>
                                                    <Col lg={6} md={6}>
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                            <Form.Control type="email" placeholder="Enter Email Id" />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={6} md={6}>
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                            <Form.Control type="email" placeholder="name@example.com" />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={6} md={6}>
                                                        <Select
                                                            defaultValue={selectedOption}
                                                            onChange={setSelectedOption}
                                                            options={options}
                                                            classNamePrefix="customizeSelect"
                                                            className='mb-3'
                                                        />
                                                    </Col>
                                                </Row>

                                                <div className='customCheck'>
                                                    <Form.Check label={
                                                        <>
                                                            <p className="addcustomdesign" onClick={handleClick}>Add Custom Design</p>
                                                            <UploadButton
                                                                uploader={uploader}
                                                                options={uploadOptions}
                                                                onComplete={(files) => alert(files.map(x => x.fileUrl).join('\n'))}
                                                            >
                                                                {({ onClick }) => (
                                                                    <button className='uploadDiv' onClick={onClick}>
                                                                        <FontAwesomeIcon icon='fa-solid fa-circle-plus' />
                                                                        <p className='mb-1'>Upload here</p>
                                                                    </button>
                                                                )}
                                                            </UploadButton>
                                                        </>
                                                    } type='radio' />
                                                </div>

                                                <div className='specialRequest'>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                        <Form.Label className='mb-0'>Add Special Requests</Form.Label>
                                                        <Form.Control as="textarea" placeholder='Type your special request here...' />
                                                    </Form.Group>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProductCustomize