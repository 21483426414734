import React, { useState } from 'react';
import './Careers.css';
import { Button, Col, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ResumeModal from '../Common-Component/Commonmodal/ResumeModal/ResumeModal';

const jobListings = [
    {
        title: '1. Jr. H.R. Executive',
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        details: {
            pay: '₹ 2,00,000 - ₹ 7,00,000 per annum',
            shift: 'Day-Shift',
            location: 'Bavdhan, Pune',
            experience: '1 - 3 years',
            language: 'English, Hindi, Marathi',
            jobType: 'Full Time',
            vacancy: '1',
            skills: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
            deadline: '20/10/2023',
            qualification: 'Graduated, Certification in Ui Ux design',
        },
        contact: {
            name: 'Deepti Deshmukh (HR Manager)',
            company: 'Mittal Craftworks Pvt. Ltd.',
            phone: '+91 9876543210',
            email: 'employer@mittalcraftworks.com',
        },
        responsibilities: [
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
        ],
    },
    {
        title: '2. Sr. Marketing Executive',
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        details: {
            pay: '₹ 2,00,000 - ₹ 7,00,000 per annum',
            shift: 'Day-Shift',
            location: 'Bavdhan, Pune',
            experience: '1 - 3 years',
            language: 'English, Hindi, Marathi',
            jobType: 'Full Time',
            vacancy: '1',
            skills: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
            deadline: '20/10/2023',
            qualification: 'Graduated, Certification in Ui Ux design',
        },
        contact: {
            name: 'Deepti Deshmukh (HR Manager)',
            company: 'Mittal Craftworks Pvt. Ltd.',
            phone: '+91 9876543210',
            email: 'employer@mittalcraftworks.com',
        },
        responsibilities: [
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
        ],
    },
];

const Careers = () => {

    const [resumemodal, setResumemodal] = useState(false);
    return (
        <section className='Careers'>
            <div className='container'>
                <div className='careerHead'>
                    <h3>Careers @ MITTAL</h3>
                </div>
                <div className='Careerscard'>
                    <Accordion>
                        {jobListings.map((job, index) => (
                            <Accordion.Item eventKey={index.toString()} key={index}>
                                <Accordion.Header >
                                    <div className='blueCard '>
                                        <div className='bluecard_head'>
                                            <Row>
                                                <Col lg={6} md={6} xs={8}>
                                                    <h3>{job.title}</h3>
                                                </Col>
                                                <Col lg={6} md={6} xs={4}>
                                                    <div className='viewtxt text-end'>
                                                        <span>View</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <p>{job.description}</p>
                                        </div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div className='accordDetailsMain'>
                                        <h3>Job Details</h3>
                                        <div className='jobInfodetails'>
                                            <Row>
                                                {Object.entries(job.details).map(([key, value], detailIndex) => (
                                                    <Col lg={6} md={6} key={detailIndex}>
                                                        <div className='JobHeading'>
                                                            <div>
                                                                <img src={`${process.env.PUBLIC_URL}/assest/image/Careers/icon_${detailIndex + 1}.png`} className='Jobdetailsicons' />
                                                            </div>
                                                            <div>
                                                                <h3>{key.charAt(0).toUpperCase() + key.slice(1)}</h3>
                                                                <p>{value}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </div>
                                        <div className='RolesResponsbility'>
                                            <div className='responsibilityhead'>
                                                <h3>
                                                    <FontAwesomeIcon icon="fa-solid fa-user-gear" /> Roles and responsibilities:
                                                </h3>
                                            </div>
                                            <div className='responsibilityList'>
                                                <ul>
                                                    {job.responsibilities.map((responsibility, respIndex) => (
                                                        <li key={respIndex}>
                                                            <img src={`${process.env.PUBLIC_URL}/assest/image/Careers/tickIcon.png`} className='tickicon' alt='Tick icon' />
                                                            {responsibility}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>

                                        <div className='RolesResponsbility contactInfo'>
                                            <div className='responsibilityhead'>
                                                <h3>Contact:</h3>
                                            </div>
                                            <div className='userName'>
                                                <p>{job.contact.name}</p>
                                                <p>{job.contact.company}</p>
                                            </div>
                                            <Row>
                                                <Col lg={6}>
                                                    <Row className='emailContactinfo'>
                                                        <Col lg={6}>
                                                            <p className='requtertext'><b>Recruiter Contact Number:</b></p>
                                                            <p className='requtertext'><b>Recruiter Email:</b></p>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <p>{job.contact.phone}</p>
                                                            <p>{job.contact.email}</p>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className='Applyforjob text-center mb-4'>
                                            <Button className='btn' type='button' onClick={() => setResumemodal(true)}>Apply For Job</Button>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </div>

                <ResumeModal show={resumemodal} onHide={() => setResumemodal(false)} />
            </div>
        </section>
    );
};

export default Careers;
