import React from "react";
import "./AboutUs.css";
import { Col, Row } from "react-bootstrap";
import CountUp from "react-countup";
import WeExpress from "../home/WeExpress/WeExpress";
import Customerlove from "../home/Customerlove/Customerlove";
import OurLatestreward from "./OurLatestreward/OurLatestreward";
import ValuesSection from "./ValueSection/ValueSection";

const AboutUs = () => {
  const cardsData = [
    {
      key: 1,
      count: 50,
      suffix: "K +",
      title: "Happy Customer",
      description: "buy, manage, and distribute HR gifts",
    },
    {
      key: 2,
      count: 1,
      suffix: "M +",
      title: "Hours Saved",
      description: "buy, manage, and distribute HR gifts",
    },
    {
      key: 3,
      count: 5,
      suffix: "M +",
      title: "Grateful Recipient",
      description: "buy, manage, and distribute HR gifts",
    },
  ];

  return (
    <section className="AboutUs">
      <div className="AboutusMain">
        <div className="container">
          <div className="aboutushead">
            <h3>About Us</h3>
            <p>
              <span>We’ve made it </span>
              <span className="">
                <strong className="spanfont">easy to appreciate </strong>employees
              </span>
            </p>
          </div>
          <Row>
            {cardsData.map((card) => (
              <Col lg={4} md={6} sm={6} className="mx-auto" key={card.key}>
                <div className="aboutuscard">
                  <div className="cardContent text-center">
                    <h3>
                      <CountUp
                        start={0}
                        end={card.count}
                        suffix={card.suffix}
                        duration={3.5}
                      />
                    </h3>
                    <h4>{card.title}</h4>
                    <p>{card.description}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>

          <div className="paraInfo">
            <p>
              It took the vision of an innovator & the knowledge of experts to
              build a virtual market where people could explore, discover & buy
              various kinds of genuine coins, with guaranteed peace of-mind, in
              their own luxurious confines.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="whoweAre">
          <div className="whoweareHEad">
            <div className="row whoweareInfo">
              <Col lg={6} md={5}>
                <h3>Who We Are</h3>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                  Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                  It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
                <p> It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more
                  recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p></Col>
              <Col lg={6} md={7}>
                <Row>
                  <Col xl={10}>
                    <div className="rightsecImage">

                      <img src={process.env.PUBLIC_URL + "/assest/image/Aboutus/aboutUvalue.png"} />


                      <div className="overlayImg"></div>
                    </div>

                  </Col>
                </Row>
              </Col>

            </div>
          </div>
        </div>
      </div>

      {/* <div className="container">
        <div className="valueStandby">
          <div className="valueStandbyHead">
            <h3>The values we stand by</h3>
          </div>

          <div className="row">
            <div className="col-lg-3">
              <div className="card valuestandCard">
                <div className="cardImgsec">
                  <img src={process.env.PUBLIC_URL + "/assest/image/Aboutus/valueIcon1.png"} />
                </div>
                <div className="cardHead">
                  <h3>Be Grateful</h3>
                  <p>We deeply appreciate our customers and focus on fostering trust and dependability.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card valuestandCard">
                <div className="cardImgsec">
                  <img src={process.env.PUBLIC_URL + "/assest/image/Aboutus/valueIcon2.png"} />
                </div>
                <div className="cardHead">
                  <h3>Be Transparent</h3>
                  <p>We deeply appreciate our customers and focus on fostering trust and dependability.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card valuestandCard">
                <div className="cardImgsec">
                  <img src={process.env.PUBLIC_URL + "/assest/image/Aboutus/valueIcon3.png"} />
                </div>
                <div className="cardHead">
                  <h3>Reduce Waste</h3>
                  <p>Our goal is to ensure your recipients are content, your workload is minimized, and your budget is optimized.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card valuestandCard">
                <div className="cardImgsec">
                  <img src={process.env.PUBLIC_URL + "/assest/image/Aboutus/valueIcon4.png"} />
                </div>
                <div className="cardHead">
                  <h3>Listen Radically</h3>
                  <p>We deeply appreciate our customers and focus on fostering trust and dependability.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <ValuesSection />

      <WeExpress />

      <OurLatestreward />

      <Customerlove />
    </section>
  );
};

export default AboutUs;
