import React from 'react'
import ProductCard from '../Common-Component/ProductCard/ProductCard'
import { Col, Row } from 'react-bootstrap'
import Breadcrumbs from '../Common-Component/Breadcrumbs/Breadcrumbs'
import "./Wishlist.css";
const Wishlist = () => {
    const orders = [
        {
            id: 1,
            image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
            name: 'Nike Dri-FIT Legend Tee - Men\'s',
            date: '3.JUN.24',
            price: '0000'
        },
        {
            id: 2,
            image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
            name: 'White Coffee Mug',
            date: '2.JUN.24',
            price: '0000'
        },
        {
            id: 3,
            image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
            name: 'Lather Cover Executive Diary and 2 more items',
            date: '1.JUN.24',
            price: '0000'
        },
        {
            id: 4,
            image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
            name: 'Nike Dri-FIT Legend Tee - Men\'s',
            date: '25.MAY.24',
            price: '0000'
        }
    ];

    const items = [
        { id: 1, text: "PEOPLE" },
        { id: 1, text: "ALL ORDERS" },
    ]
    return (
        <>
            <section className='Wishlist'>
                <div className='container'>
                    <Breadcrumbs items={items} />
                    <div className='wishlistHead'>
                        <h3>Wishlist <span><sub>(10)</sub></span></h3>
                    </div>


                    <Row className='wishlistMainrow' >
                        {orders.map(order => (
                            <Col key={order.id} md={4} lg={3} xs={6} className="my-3">
                                <ProductCard order={order} />
                            </Col>
                        ))}
                    </Row>
                </div>
            </section>
        </>
    )
}

export default Wishlist