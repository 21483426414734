import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import Success_Modalss from '../../Common_Component/Success_Modal/Success_Modalss'
import useRazorpay from "react-razorpay";
import Form from 'react-bootstrap/Form';
import Cookies from 'js-cookie';
import Common_Button from '../../Common-Component/Common_Button/Common_Button';
import { useNavigate } from 'react-router-dom';

const Shipping_method = ({ handleNext, handlePrevious, paymentMethod, addressSelect }) => {
    const [modalShow, setModalShow] = useState(false);
    const navigate = useNavigate();
    const [Razorpay, openRazorpay] = useRazorpay();
    const [razorid, setRazorId] = useState();
    const [coupon, setCoupon] = useState("");
    const [discount, setDiscount] = useState(0);

    const [giftOptionsEntireOrder, setGiftOptionsEntireOrder] = useState(false);
    const [giftOptionsIndividualItems, setGiftOptionsIndividualItems] = useState(false);

    const cart = [
        { id: 1, image: process.env.PUBLIC_URL + "/assest/image/Customizable/medalProduct.png" }
    ]

    return (
        <>
            <section className='stepp-inner-main'>
                <div className='ShippingMethod'>
                    <h3>Flat Rate : ₹ 300</h3>

                    <Form.Check type="checkbox" onChange={(e) => setGiftOptionsIndividualItems(e.target.checked)} label="DO YOU HAVE ANY GIFT ITEMS IN YOUR ORDER?" />

                    {giftOptionsIndividualItems && (
                        <>
                            <div className='shippingMethod'>
                                <div className='giftShipping'>
                                    <h3>GIFT OPTIONS FOR THE ENTIRE ORDER</h3>
                                    <Form.Check
                                        type="checkbox"
                                        label="Add gift options for the Entire Order"
                                        onChange={(e) => setGiftOptionsEntireOrder(e.target.checked)}
                                    />
                                </div>

                                <div className='giftShipping'>
                                    <h3>GIFT OPTIONS FOR INDIVIDUAL ITEMS</h3>
                                    <Form.Check
                                        type="checkbox"
                                        label="Add gift options for Individual Items"

                                    />


                                </div>
                            </div>

                        </>
                    )}
                </div>

                <div className="save-btn-div mt-5">
                    <button className="back-btn" onClick={handlePrevious}> Back</button>
                    <button className="continue-btn" onClick={handleNext}> Continue </button>
                </div>
            </section>
        </>
    )
}

export default Shipping_method
