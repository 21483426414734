import React from 'react'
import Table from 'react-bootstrap/Table';
import './ProdcuctOrder.css'

const ProductOrder = () => {
    return (
        <section className='product-order'>
            <img src={process.env.PUBLIC_URL + "/assest/image/Careers/crossIcon.png"} className='crossIcon' />
            <Table responsive>
                <thead>
                    <tr>
                        <th>PRODCUT/S</th>
                        <th>QUANTITY</th>
                        <th>PRICE</th>
                        <th>SUBTOTAL</th>
                    </tr>
                </thead>


                <div className='row'>
                    <div className='col-lg-4 col-md-3 col-8'>
                        <div className='white-bgg me-4'>
                            <img src={process.env.PUBLIC_URL + '/assest/image/Profile/productImg.png'} className='pro-img' />
                        </div>
                    </div>
                    <div className='col-lg-8 col-md-9 col-12'>
                        <div className='text-start'>
                            <p className='pro-name'>Nike Dri-FIT Legend Tee - Men's</p>
                            <p className='colour-name'>Colour : Game Royal</p>
                            <p className='size-name'>Size : XL</p>
                        </div>
                    </div>
                </div>

                <td>24 UPDATE</td>
                <td>₹ 0000</td>
                <td>₹ 0000</td>


            </Table>
        </section>
    )
}

export default ProductOrder