import React from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Form, Container, Row, Col } from 'react-bootstrap';
import "./Talktous.css";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Link } from 'react-router-dom';

const Talktous = (props) => {
    const [value, setValue] = useState();
    
    return (
        <section className=''>
            <Offcanvas className="Offcanvastalktous" show={props.show} onHide={props.handleClose} {...props}>

                <Offcanvas.Body>
                    <div className='talktousbody'>

                        <h2 className="contact-form-title">Talk to Us</h2>
                        <Form className='talktousform'>
                            <Row>
                                <Col>
                                    <Form.Group controlId="formFirstName">

                                        <Form.Control className='mb-3' type="text" placeholder="Enter First Name" />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="formLastName">

                                        <Form.Control className='mb-3' type="text" placeholder="Enter Last Name" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group controlId="formEmail">

                                <Form.Control className='mb-3' type="email" placeholder="Enter Email Id" />
                            </Form.Group>
                            <Form.Group controlId="formContactNumber">

                                <Row>
                                    <Col md="auto" className="country-code">
                                        <PhoneInput
                                            country="in"
                                            value={value}
                                            onChange={setValue}

                                        />
                                    </Col>
                                    <Col className="contact-number">
                                        <Form.Control className='mb-3' type="text" placeholder="Enter Contact No." />
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group className="radio-options">
                                <Form.Label>Help us direct you to the right expert:</Form.Label>
                                <Form.Check
                                    type="radio"
                                    label="I want a demo of your gifting platform for my company's ongoing gifting needs."
                                    name="expertOption"
                                    id="option1"
                                />
                                <Form.Check
                                    type="radio"
                                    label="I need help with a one-time gift project."
                                    name="expertOption"
                                    id="option2"
                                />
                            </Form.Group>
                            <Form.Group controlId="formDiscussion">
                                <Form.Control placeholder='Type here what you would like to discuss (optional)' as="textarea" rows={2} className="optional-textarea" />
                            </Form.Group>
                            <Form.Group controlId="formConsent" className="consent-checkbox">
                                <Form.Check
                                    type="checkbox"
                                    label="I agree to receive communication about our products, services and related content."
                                />
                            </Form.Group>
                            <div className="submit-section">
                                <Button variant="primary" type="button" className="submit-button" onClick={props.handleClose}>
                                    Submit
                                </Button>
                                <span className="mx-3">OR</span>
                                <Link href="#" className="btn btn-link request-callback">Request a Call Back</Link>
                            </div>

                            <div className=''>
                                <Form.Check label="Add Custom Design" type='radio' />
                            </div>
                        </Form>

                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </section>
    )
}

export default Talktous