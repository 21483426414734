import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Container, InputGroup, Row } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import Common_Button from "../Common-Component/Common_Button/Common_Button";
const Footer = () => {
  return (
    <>
      <section className="footer">
        <Container>
          <Row>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="Heading-holder">
                <h2 className="footer-title">THE COMPANY</h2>
                <Link to={"/aboutUs"}>
                  <p>ABOUT US</p>
                </Link>

                <Link to={"/relevantNews"}>
                  <p>LATEST NEWS</p>
                </Link>

                <Link to={"/legalNotice"}>
                  <p>LEGAL NOTICE</p>
                </Link>

                <Link to={"/responsibility"}>
                  <p>RESPONSIBILITY</p>
                </Link>

                <Link to={"/privacypolicy"}>
                  <p>PRIVACY POLICY</p>
                </Link>

                <Link to={"/pageNotfound"}>
                  <p>CAREERS @ MITTAL</p>
                </Link>

              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="Heading-holder">
                <h2 className="footer-title">OFFERINGS</h2>
                <Link to={"/all-category"}>
                  <p>PRODUCTS</p>
                </Link>

                <p>GIFT BY PRICE</p>

                <Link to={"/gift-automation"}>
                  <p>GIFT AUTOMATION</p>
                </Link>

                <Link to={"/sustainable-product"}>
                  <p>SUSTAINABLE GIFTS</p>
                </Link>

                <Link to={"/gift-customization"}>
                  <p>GIFT CUSTOMISATION</p>
                </Link>

                <Link to={'/multi-recipient'}>
                  <p>MULTI-RECIPIENT SENDING</p>
                </Link>

                <p>GIFT BY OCCASIONS / EVENTS</p>

              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="Heading-holder">
                <h2 className="footer-title">POPULAR LINKS</h2>
                <Link to={"/blogs"}>
                  <p>BLOGS</p>

                </Link>

                <Link to={"/festivegifts"}>
                  <p>FESTIVE GIFTING</p>

                </Link>
                <p>⁠RETIREMENT GIFTS</p>
                <p>EMPLOYEE WELCOME KITS</p>
                <p>EMPLOYEE BIRTHDAY GIFTS</p>
                <Link to={"/emp-satifaction"}>
                  <p>EMPLOYEE APPRECIATION GIFTS</p>

                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-5 col-sm-6 col-6">
              <div className="Heading-holder">
                <h2 className="footer-title">SUPPORT</h2>
                <Link to={"/faq"}>
                  <p>FAQ</p>
                </Link>

                <Link to={"/contactus"}>
                  <p>CONTACT US</p>
                </Link>

                <p>UNSUBSCRIBE</p>

                {/* <Link to={"/product-care"}>
                  <p>PRODUCT CARE</p>
                </Link> */}

                <Link to={"/made-to-order"}>
                  <p>MADE TO ORDER</p>
                </Link>

                <Link to={"/request-quotation"}>
                  <p>⁠SUBMIT YOUR RFQ</p>
                </Link>

                <Link to={"/order-tracking"}>
                  <p>⁠TRACK YOUR ORDER</p>
                </Link>

                <p>CUSTOMISE WITH US</p>

                <Link to={"/submitsuggestion"}>
                  <p>⁠SUBMIT YOUR SUGGESTIONS</p>
                </Link>

              </div>
            </div>
            <div className="col-lg-5 col-md-5">
              <div className="social-link mt-3">
                <h2 className="footer-title mb-4">FOLLOW US</h2>
                <div className="social-linksss d-flex">
                  <div className="social-icon text-center">
                    <FontAwesomeIcon icon={faLinkedin} className="fa-logo" />

                  </div>
                  <div className="social-icon text-center">
                    <img className="x-logo" src={process.env.PUBLIC_URL + '/assest/image/logo/x-mark.png'} alt="Logo" />

                  </div>
                  <div className="social-icon text-center">
                    <FontAwesomeIcon icon={faFacebookF} className="fa-logo" />
                  </div>
                  <div className="social-icon text-center">
                    {/* <FontAwesomeIcon icon={faTwitter} className="fa-logo" /> */}
                    <FontAwesomeIcon icon={faInstagram} className="fa-logo" />
                  </div>
                  <div className="social-icon text-center">
                    <FontAwesomeIcon icon={faYoutube} className="fa-logo" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-9">
              <div className="news-letter-box mt-3">
                <h2 className="footer-title mb-4">NEWSLETTER</h2>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="enter email"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                  />
                  <InputGroup.Text id="basic-addon2">
                    <Common_Button
                      text="  Subscribe"
                      className='subscribe-btn'
                    />
                  </InputGroup.Text>
                </InputGroup>
              </div>
            </div>
            <div className="col-md-12 mobcenttt">
              <div className="copyright-holder  text-center">
                <p>
                  Copyright ©2024 All Rights Reserved. Developed by  <Link to="https://profcymaglobal.com/" target="___blank"><span>Profcyma </span>  </Link>{" "}
                </p>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Footer;
