import React from 'react'
import "./ResumeModal.css";
import Modal from 'react-bootstrap/Modal';
import { Button, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const ResumeModal = (props) => {
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='Resumemodal'
            >

                <Modal.Body>
                    <section>
                        <div>
                            <div className='resumeHead'>
                                <h3>Resume:</h3>
                                <p>Resume is the most important document recruiters look for. Recruiters generally do not look at profiles without resumes.</p>
                                <div>
                                    <div className='text-end'>
                                        <FontAwesomeIcon icon="fa-solid fa-download" className='trashIcon' />
                                        <FontAwesomeIcon icon="fa-solid fa-trash" className='trashIcon' />
                                    </div>
                                </div>
                            </div>

                            <div className='row uploadResumebtn'>
                                <Col lg={6}>
                                    <Button className='btn uploadbtn'>Upload Resume</Button>
                                    <p>Supported Formats: doc, docx, rtf, pdf, upto 2 MB</p>

                                </Col>
                                <Col lg={6}>
                                    <div className='uploadLink'>
                                        <p>Upload Resume Link <FontAwesomeIcon icon="fa-solid fa-upload" className='ms-2' /></p>

                                    </div>
                                </Col>
                            </div>
                        </div>
                    </section>
                </Modal.Body>

            </Modal>


        </>
    )
}

export default ResumeModal