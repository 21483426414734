import React from 'react'
import './OrderSummary.css'
import { Col, Row } from 'react-bootstrap'
import { faClipboard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'


const OrderSummary = () => {
    return (
        <section className='order-summary'>
            <div className='title-order-sumarryy d-flex'>
                <FontAwesomeIcon icon={faClipboard} className='pad-icon me-2' />
                <h4>Order Summary</h4>
                <div className='blk-borderr'></div>
            </div>
            <div className='main-blue-bggg'>
                <Row>
                    <Col md={6}>
                        <p className='txttt-order-summary'>Total amount</p>
                    </Col>
                    <Col md={6}>
                        <p className='price-order-summary'>₹ 0000</p>
                    </Col>
                </Row>
                <div className='brown-border'></div>
                <Row>
                    <Col md={6}>
                        <p className='txttt-order-summary'>Discount</p>
                    </Col>
                    <Col md={6}>
                        <p className='price-order-summary'>₹ 0000</p>
                    </Col>
                </Row>
                <div className='brown-border'></div>
                <Row>
                    <Col md={6}>
                        <p className='txttt-order-summary'>Tax</p>
                    </Col>
                    <Col md={6}>
                        <p className='price-order-summary'>₹ 0000</p>
                    </Col>
                </Row>
                <div className='brown-border'></div>
                <Row>
                    <Col md={6}>
                        <p className='txttt-order-summary'>Shipping</p>
                    </Col>
                    <Col md={6}>
                        <p className='price-order-summary'>₹ 0000</p>
                    </Col>
                </Row>
                <div className='brown-border'></div>
                <Row>
                    <Col md={6}>
                        <p className='price-totaltxt'>Total</p>
                    </Col>
                    <Col md={6}>
                        <p className='price-totaltxt'><b>₹ 0000</b></p>
                    </Col>
                </Row>
            </div>
            <div className='mt-4'>
                <Link to={"/checkout"}>
                    <button type='button' className='proceed-btnnn'>PROCEED TO CHECKOUT (1)</button>

                </Link>

                <Link to={"/promotional-product"}>
                    <p className='continue-shopping mt-3'>Continue Shopping</p>
                </Link>
            </div>

        </section>
    )
}

export default OrderSummary