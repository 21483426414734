import React, { useEffect, useState } from 'react'
import './ShopByOccasion.css'
import { Container, Row } from 'react-bootstrap'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { occasion } from '../../../utils/apis/Home/Homeapi';
import { Link } from 'react-router-dom';
const ShopByOccasion = () => {
    const [occasionprod, setOcassionprod] = useState([]);
    const getOcassion = async () => {
        const res = await occasion();
        if (res?.success) {
            setOcassionprod(res?.data)
        }
    }

    useEffect(() => {
        getOcassion();
    }, [])
    const weExprss = [
        {
            text: "NEW JOINING",
            image: process.env.PUBLIC_URL + "/assest/image/home/birthday-imga.png",

        },
        {

            text: "BIRTHDAY",
            image: process.env.PUBLIC_URL + "/assest/image/home/birthday-imga.png",

        },
        {
            text: "WORK ANNIVERSARY",
            image: process.env.PUBLIC_URL + "/assest/image/home/birthday-imga.png",
        },
        {
            text: "GRATITUDE",
            image: process.env.PUBLIC_URL + "/assest/image/home/birthday-imga.png",
        },
        {
            text: "NEW JOINING",
            image: process.env.PUBLIC_URL + "/assest/image/home/birthday-imga.png",

        },
        {

            text: "BIRTHDAY",
            image: process.env.PUBLIC_URL + "/assest/image/home/birthday-imga.png",

        },



    ];
    return (
        <>
            <section className='shop-by-occasion'>
                <Container>
                    <Row>
                        <div className='heading-holder mb-5'>
                            <div className='d-flex'>
                                <h2 className='first-heading'>Shop by </h2>
                                <h3 className='second-heading'>Occasion / Events</h3>
                            </div>
                            <span className='text-para'>Find The Perfect Match For Every Moment!</span>
                        </div>
                        <Swiper
                            loop="true"
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                486: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 20,
                                },
                                992: {
                                    slidesPerView: 3,
                                    spaceBetween: 20,
                                },
                                1200: {
                                    slidesPerView: 4,
                                    spaceBetween: 30,
                                },
                                1400: {
                                    slidesPerView: 4,
                                    spaceBetween: 20,
                                },

                            }}
                            pagination={{
                                dynamicBullets: true,
                                clickable: true
                            }}
                            modules={[Pagination]}
                            className="mySwiper"
                        >
                            {weExprss.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <div className='main-div mb-5'>
                                        <div className='image-holder'>
                                            <Link to={"/promotional-product"}>
                                                <img src={item.image} className='birthday-image' />

                                            </Link>
                                        </div>
                                        <h3>{item.text}</h3>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Row>
                </Container>
            </section >
        </>
    )
}

export default ShopByOccasion
