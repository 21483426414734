import React from 'react'
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import "./Returnmodal.css";
const Returnmodal = (props) => {
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='Returnmodal'
            >

                <Modal.Body>
                    <Container className="order-return-container">
                        <Row className="justify-content-center">
                            <Col md={6}>
                                <Form className="order-return-form">
                                    <div className='returnHead'>
                                        <h3>Order Return</h3>

                                    </div>
                                    <Form.Group className="form-group">
                                        <Form.Label>Select a reason for cancelation</Form.Label>
                                        <div>
                                            {['Product not required anymore', 'Product price increased', 'Wrong contact number /address added', 'Incorrect product placed', 'Delivery time too long', 'Incorrect payment method selected', 'Other'].map((reason, idx) => (
                                                <Form.Check
                                                    type="radio"
                                                    id={`reason-${idx}`}
                                                    label={reason}
                                                    name="cancelationReason"
                                                    key={idx}
                                                    className="form-check"
                                                />
                                            ))}
                                        </div>
                                        <Form.Control
                                            as="textarea"
                                            placeholder="I ordered same product again."
                                            className="form-control"
                                        />
                                    </Form.Group>
                                    <div className="btn-container">
                                        <Button variant="secondary" onClick={props.onHide} className="cancelbtn">Cancel</Button>
                                        <Button variant="primary" className="submitbtn">Submit</Button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default Returnmodal