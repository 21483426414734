import React, { useState } from 'react'
import "./CustomizableProduct.css";
import { Col, Row } from 'react-bootstrap';
import ProductCard from '../Common-Component/ProductCard/ProductCard';
import {NavDropdown } from "react-bootstrap";
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Form, Accordion } from 'react-bootstrap';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import Breadcrumbs from "../Common-Component/Breadcrumbs/Breadcrumbs"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Paginationss from '@mui/material/Pagination';
import FilterOffcanvas from '../Common-Component/Offcanvas/FilterOffcanvas/FilterOffcanvas';

const CustomizableProduct = ({ heading }) => {
    const [value, setValue] = useState(10);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const orders = [
        {
            id: 1,
            image: process.env.PUBLIC_URL + "/assest/image/Customizable/medalProduct.png",
            name: 'Nike Dri-FIT Legend Tee - Men\'s',
            date: '3.JUN.24',
            price: '0000'
        },
        {
            id: 2,
            image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
            name: 'White Coffee Mug',
            date: '2.JUN.24',
            price: '0000'
        },
        {
            id: 3,
            image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
            name: 'Lather Cover Executive Diary and 2 more items',
            date: '1.JUN.24',
            price: '0000'
        },
        {
            id: 4,
            image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
            name: 'Lather Cover Executive Diary and 2 more items',
            date: '1.JUN.24',
            price: '0000'
        }

    ];


    const items = [
        { id: 1, text: "HOME", link: "/" },
        { id: 1, text: "CUSTOMIZABLE PRODUCTS" }
    ]
    return (
        <>
            <section className='CustomizableProduct'>
                <div className='container'>
                    <Row>
                        <Col xl={6} lg={6} md={6} xs={12}>
                            <Breadcrumbs items={items} />

                        </Col>
                        <Col xl={6} lg={6} md={6} xs={12}>
                            <div className='sortingsec'>

                                <NavDropdown title={<>
                                    <p>SORT BY <img src={process.env.PUBLIC_URL + "/assest/image/Careers/bars.png"} className='barsImage' /></p>
                                </>} id="basic-nav-dropdown" >
                                    <NavDropdown.Item as={Link} to="" >Price</NavDropdown.Item>
                                    <NavDropdown.Item >Popularity</NavDropdown.Item>
                                    <NavDropdown.Item > New Arrivals</NavDropdown.Item>
                                    <NavDropdown.Item >Occasions / Events</NavDropdown.Item>
                                </NavDropdown>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={6} xs={9}>
                            <div className='custoizablehead '>
                                <h3>{heading}</h3>
                            </div>

                        </Col>
                        <Col xl={6} xs={2}>
                            <div className='filtericonmain text-end'>
                                <FontAwesomeIcon icon="fa-solid fa-filter" onClick={handleShow} />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={3} md={4}>
                            <div className="sidebar">
                                <Form>
                                    <Form.Group controlId="customizable">
                                        <Form.Check type="checkbox" label="Customizable (514)" />
                                    </Form.Group>
                                    <Form.Group controlId="freeShipping">
                                        <Form.Check type="checkbox" label="Free Shipping" />
                                    </Form.Group>

                                    <div className='productsBycat formbottomline'>
                                        <h3 className='productsBycatHead'>Products by Category</h3>

                                        <div className='categoryItemssec'>
                                            <div className='categoryItem'>
                                                <p>Bags</p>
                                                <p className='lessthanicon'><FontAwesomeIcon icon="fa-solid fa-angle-right" /></p>
                                            </div>

                                            <div className='categoryItem'>
                                                <p>T-shirts</p>
                                                <p className='lessthanicon'><FontAwesomeIcon icon="fa-solid fa-angle-right" /></p>
                                            </div>
                                            <div className='categoryItem'>
                                                <p>Copperware</p>
                                                <p className='lessthanicon'><FontAwesomeIcon icon="fa-solid fa-angle-right" /></p>
                                            </div>

                                            <div className='categoryItem'>
                                                <p>Frames</p>
                                                <p className='lessthanicon'><FontAwesomeIcon icon="fa-solid fa-angle-right" /></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="price-range">
                                        <h3 className='productsBycatHead'>Price Range</h3>
                                        <InputRange
                                            maxValue={20}
                                            minValue={0}
                                            value={value}
                                            onChange={value => setValue(value)} />

                                    </div>

                                    <Form.Group controlId="quantity">
                                        <h3 className='productsBycatHead'>Quantity</h3>
                                        <div className='quantityform'>
                                            <Form.Control type="text" placeholder="Enter Quantity" />
                                            <div className='onfieldtxt'>
                                                <Form.Check
                                                    type="checkbox"
                                                    label="No min qty"
                                                />
                                            </div>

                                        </div>

                                    </Form.Group>

                                    <Accordion>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>
                                                Brand
                                            </Accordion.Header>
                                            <Accordion.Body >
                                                <div className='accordBodycontent'>
                                                    <p>Gift Wrap</p>
                                                    <p>Customisable</p>

                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>
                                                Colour
                                            </Accordion.Header>
                                            <Accordion.Body >
                                                <div className='accordBodycontent'>
                                                    <p>Gift Wrap</p>
                                                    <p>Customisable</p>

                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header>
                                                Occasion
                                            </Accordion.Header>
                                            <Accordion.Body >
                                                <div className='accordBodycontent'>
                                                    <p>Gift Wrap</p>
                                                    <p>Customisable</p>

                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="5">
                                            <Accordion.Header>
                                                Material
                                            </Accordion.Header>
                                            <Accordion.Body >
                                                <div className='accordBodycontent'>
                                                    <p>Gift Wrap</p>
                                                    <p>Customisable</p>

                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="6">
                                            <Accordion.Header>
                                                Categories
                                            </Accordion.Header>
                                            <Accordion.Body >
                                                <div className='accordBodycontent'>
                                                    <p>Gift Wrap</p>
                                                    <p>Customisable</p>

                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="7">
                                            <Accordion.Header>
                                                Deals & Discount
                                            </Accordion.Header>
                                            <Accordion.Body >
                                                <div className='accordBodycontent'>
                                                    <p>Gift Wrap</p>
                                                    <p>Customisable</p>

                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="8">
                                            <Accordion.Header>
                                                Ordering Options
                                            </Accordion.Header>
                                            <Accordion.Body >
                                                <div className='accordBodycontent'>
                                                    <p>Gift Wrap</p>
                                                    <p>Customisable</p>

                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Form>
                            </div>
                        </Col>

                        <Col lg={9} md={8}>
                            <Row>
                                {orders.map(order => (
                                    <Col xl={4} md={4} xs={6}>
                                        <ProductCard order={order} />
                                    </Col>
                                ))}
                            </Row>



                            <Stack spacing={2}>
                                <Paginationss count={4} />
                            </Stack>
                        </Col>
                    </Row>

                    <FilterOffcanvas placement="start" name={"start"} show={show} handleClose={handleClose} />
                </div>
            </section>
        </>
    )
}

export default CustomizableProduct