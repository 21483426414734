import React from 'react'
import "./SuccessfullyModal.css";
import Modal from 'react-bootstrap/Modal';
import Success from '../../Animation/Success';
const SuccessfullyModal = (props) => {
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="logOutmodal"
            >

                <Modal.Body>
                    <section className='LogoutMain'>
                        <div className='succussFlex'>
                            <Success />
                            <div className='logoutinfo text-center'>
                                <p>{props.text}</p>
                            </div>

                        </div>
                    </section>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default SuccessfullyModal