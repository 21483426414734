import React, { useState } from 'react'
import "./MadetoOrder.css"
import Breadcrumbs from '../Common-Component/Breadcrumbs/Breadcrumbs';
import Form from 'react-bootstrap/Form';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Button, Col, Row } from 'react-bootstrap';
import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const MadetoOrder = () => {
    const [value, setValue] = useState();
    const items = [
        { id: 1, text: "HOME", link: "/home" },
        { id: 2, text: "MADE TO ORDER" },
    ]

    const uploader = Uploader({
        apiKey: "free"
    });

    const options = { multi: true };
    return (
        <>
            <section className='Madetoorder'>
                <div className='container'>
                    <Breadcrumbs items={items} />
                    <div className='MadetoOrderhead'>
                        <h3>Made to Order</h3>
                    </div>

                    <Row >
                        <Col lg={6} md={7}>
                            <div className=' Onbannerform'>

                                <Form>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Control type="email" placeholder="Enter Name" />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Control type="email" placeholder="Enter Email Id" />
                                    </Form.Group>

                                    <PhoneInput
                                        country="in"
                                        value={value}
                                        onChange={setValue}
                                        className="mb-3"
                                        placeholder='Enter Contact No'

                                    />

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Control type="email" placeholder="Enter Company Name" />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Control type="email" placeholder="Enter a Desired Product (e.g.: Medal / Coin)" />
                                    </Form.Group>

                                    <UploadButton uploader={uploader}
                                        options={options}
                                        onComplete={files => alert(files.map(x => x.fileUrl).join("\n"))}>
                                        {({ onClick }) =>
                                            <button className='uploadDiv' onClick={onClick}>
                                                <FontAwesomeIcon icon="fa-solid fa-circle-plus" />
                                                <p>Upload here</p>
                                            </button>
                                        }
                                    </UploadButton>

                                    <div className='uploaddesign'>
                                        <p className='mb-0'><b>Upload your Design sample/s, so that we will get to your idea.</b></p>
                                        <p>(Upload preferences: 10 Mb max PDF, AI, EPS, JPG, PNG, JPEG)</p>

                                    </div>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Control className='requestarea' placeholder='Type you requirement in brief' as="textarea" />
                                    </Form.Group>

                                    <div className="sendRequest">
                                        <Button type="button" className="sendbtn">Send Request</Button>
                                    </div>

                                </Form>


                            </div>
                        </Col>

                        <Col lg={6} md={5} className='my-auto'>
                            <div className='madeOrderimg'>
                                <img src={process.env.PUBLIC_URL + "/assest/image/Made_to_Order/made_to_order.png"} />
                            </div>
                        </Col>

                    </Row>


                </div>
            </section>
        </>
    )
}

export default MadetoOrder