import React from 'react'
import OrderSummary from './OrderSummary/OrderSummary'
import ProductOrder from './ProductOrder/ProductOrder'
import { Col, Container, Row } from 'react-bootstrap'

const MyCart = () => {
    return (
        <section>
            <Container>
                <div className='my-cart-titleee'>
                    <h3>My Cart</h3>
                </div>
                <Row>
                    <Col xl={9} lg={9} md={12} sm={12} xs={12}>
                        <ProductOrder />
                    </Col>
                    <Col xl={3} lg={3} md={12} sm={12} xs={12} >
                        <OrderSummary />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default MyCart