import React from 'react'
import CustomizableProduct from '../CustomizableProduct/CustomizableProduct'

const SustainableProduct = () => {
    return (
        <>
            <CustomizableProduct heading={"Sustainable Product"}/>
        </>
    )
}

export default SustainableProduct