import React from 'react'
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "./Offers.css"
import Breadcrumbs from '../Common-Component/Breadcrumbs/Breadcrumbs';
const Offers = () => {

    const offers = [
        { id: 1, image: process.env.PUBLIC_URL + "/assest/image/Offer/OfferImg1.png" },
        { id: 1, image: process.env.PUBLIC_URL + "/assest/image/Offer/OfferImg2.png" },
        { id: 1, image: process.env.PUBLIC_URL + "/assest/image/Offer/OfferImg1.png" },
        { id: 1, image: process.env.PUBLIC_URL + "/assest/image/Offer/OfferImg1.png" },
        { id: 1, image: process.env.PUBLIC_URL + "/assest/image/Offer/OfferImg1.png" }
    ]

    const items = [
        { text: "HOME", link: "/" },
        { text: "OFFERS" },
    ]
    return (
        <>
            <section className='Offers'>
                <div className='container'>
                    <Breadcrumbs items={items} />
                    <Swiper
                        slidesPerView={"auto"}
                        modules={[Autoplay, Pagination]}
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            370: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            485: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                            575: {
                                slidesPerView: 2,
                                spaceBetween: 30,
                            },

                            768: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                            992: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                            1200: {
                                slidesPerView: 4,
                                spaceBetween: 30,
                            },
                            1440: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                            2000: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                        }}

                        pagination={{ clickable: true }}
                        className="mySwiper"
                    >
                        {offers.map(offer => (
                            <SwiperSlide>
                                <img src={offer?.image} className='offerImg' />


                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </section>
        </>
    )
}

export default Offers