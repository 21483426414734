import React, { useContext, useEffect, useState } from 'react';
import './Login.css';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Link, useNavigate } from 'react-router-dom';
import { userLogin } from '../../utils/apis/login/Login';
import { useForm } from 'react-hook-form';
import { Context } from '../../utils/context';
import Cookies from "js-cookie";
import { faEye } from '@fortawesome/free-solid-svg-icons';

const Login = () => {
    const [showEmail, setShowEmail] = useState(true);
    // const { signin, setSignin } = useContext(Context);
    const [showPassword, setShowPassword] = useState(false);
    const [iconOne, setIconOne] = useState(faEye);
    const [phoneValue, setPhoneValue] = useState("");
    const navigate = useNavigate();
    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
        reset,
        trigger,
    } = useForm();




    const onSubmit = async (data) => {
        const loginData = {
            ...data,
            email_or_contact: showEmail ? data.email_or_contact : phoneValue,
        };

        const res = await userLogin(loginData);
        if (res?.success) {
            await Cookies.set("ecommerce2_security", res?.data?.token);
            // await setSignin(true);
            navigate('/');
            reset();
        } else {
            if (res?.message?.errors) {
                res.message.errors.forEach(error => {
                    setError(error.path, { message: error.msg });
                });
            }
        }
    };

    return (
        <div className="login-container">

            <div className="login-box">
                <div className='logosection'>
                    <img src={process.env.PUBLIC_URL + "/assest/image/logo/header-logo.png"} />
                    <Link to={"/"}>
                        <p className='backtohome'>Back to Home</p>

                    </Link>
                </div>

                <Row className="justify-content-center">
                    <Col xxl={4} xl={4} md={8}>
                        <div className="login-header">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                                <Nav variant="pills">
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <Nav.Item>
                                                <Nav.Link eventKey="first">User Log-in</Nav.Link>
                                            </Nav.Item>

                                        </div>
                                        <div className='col-lg-6'>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second">Seller Log-in</Nav.Link>
                                            </Nav.Item>

                                        </div>
                                    </div>
                                </Nav>

                                <Tab.Content className='tablogin'>
                                    <Tab.Pane eventKey="first">
                                        <form>
                                            <div className="form-group">
                                                <label htmlFor="email" className='emailtxt'     >Email or Mobile Number</label>
                                                <input type="email" className="form-control" id="email" placeholder="Enter Email or Mobile Number" />
                                            </div>
                                            <div className="form-group">
                                                <label className='emailtxt' htmlFor="password">Password</label>
                                                <div className="password-container">
                                                    <input type="password" className="form-control" id="password" placeholder="Enter Password" />
                                                    <span className="password-icon">👁️</span>
                                                </div>
                                            </div>
                                            <div className="forgot-password">
                                                <Link to={"/forgetpassword"}>Forgot Password?</Link>
                                            </div>
                                            <button type="submit" className="btn btn-primary btn-block">Log-in</button>
                                        </form>
                                        <div className="signup-link">
                                            <p>Don't have an account? <Link to={"/register"} ><span style={{ color: "#234C9E", fontWeight: "600" }}>Create Account</span></Link></p>
                                        </div>
                                        <div className="social-login">
                                            <p>You can also login by using your</p>
                                            <div className="social-icons">
                                                <img src={process.env.PUBLIC_URL + "/assest/image/login/facebook.png"} alt="Google" />
                                                <img src={process.env.PUBLIC_URL + "/assest/image/login/google.png"} alt="Google" />
                                                <img src={process.env.PUBLIC_URL + "/assest/image/login/twitter.png"} alt="Google" />
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <form>
                                            <div className="form-group">
                                                <label className='emailtxt' htmlFor="email">Email or Mobile Number</label>
                                                <input type="email" className="form-control" id="email" placeholder="Enter Email or Mobile Number" />
                                            </div>
                                            <div className="form-group">
                                                <label className='emailtxt' htmlFor="password">Password</label>
                                                <div className="password-container">
                                                    <input type="password" className="form-control" id="password" placeholder="Enter Password" />
                                                    <span className="password-icon">👁️</span>
                                                </div>
                                            </div>
                                            <div className="forgot-password">
                                                <a href="#">Forgot Password?</a>
                                            </div>
                                            <button type="submit" className="btn btn-primary btn-block">Log-in</button>
                                        </form>
                                        <div className="signup-link">
                                            <p>Don't have an account? <Link to={"/register"} style={{ color: "blue" }}>Create Account</Link></p>
                                        </div>
                                        <div className="social-login">
                                            <p>You can also login by using your</p>
                                            <div className="social-icons">
                                                <img src={process.env.PUBLIC_URL + "/assest/image/login/facebook.png"} alt="Google" />
                                                <img src={process.env.PUBLIC_URL + "/assest/image/login/google.png"} alt="Google" />
                                                <img src={process.env.PUBLIC_URL + "/assest/image/login/twitter.png"} alt="Google" />
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>

                            </Tab.Container>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Login;
