import React from 'react'
import { Button, Form } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import "./ReviewModal.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Uploader } from 'uploader';
import { UploadButton } from 'react-uploader';
const ReviewModal = (props) => {

    const uploader = Uploader({ apiKey: 'free' });
    const uploadOptions = { multi: true };
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='AddreviewModalMain'
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Review
                    </Modal.Title>
                    <div className='x-markMain'>
                        <FontAwesomeIcon icon="fa-solid fa-xmark" className='crossIcon' onClick={props.onHide} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <section className='AddreviewModal'>
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Example textarea</Form.Label>
                                <Form.Control as="textarea" placeholder='Enter Review' />
                            </Form.Group>
                        </Form>


                        <div className='uploadreviewMain'>
                            <div className='uploadReviewsec'>

                                <div className='Reviewsec'>
                                    <img src={process.env.PUBLIC_URL + "/assest/image/Careers/reviewImg.png"} className='reviewImg' />
                                </div>
                                <UploadButton
                                    uploader={uploader}
                                    options={uploadOptions}
                                    onComplete={(files) => alert(files.map(x => x.fileUrl).join('\n'))}
                                >
                                    {({ onClick }) => (
                                        <button className='uploadDiv' onClick={onClick}>
                                            <FontAwesomeIcon icon='fa-solid fa-circle-plus' />
                                            <p className='mb-1'>Upload here</p>
                                        </button>
                                    )}
                                </UploadButton>

                            </div>

                            <div className='reviewVideo'>
                                <p><b>Upload your review video </b>(optional)</p>
                                <p>(Upload preferences: 10 Mb max)</p>
                            </div>
                        </div>

                        <div className='btnsecmain'>
                            <Button className="submitbtn">Submit</Button>
                        </div>

                    </section>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default ReviewModal