import React, { useContext, useEffect, useState } from 'react'
import { fas } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Select from "react-select";
library.add(fas);



const Address = ({ handleNext, handlePrevious, setAddressSeAddNewaddresslect, addressSelect }) => {
    const [isSaved, setIsSaved] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [value, setValue] = useState();
    console.log(addressSelect);


    const options = [
        { value: "chocolate", label: "Chocolate" },
        { value: "strawberry", label: "Strawberry" },
        { value: "vanilla", label: "Vanilla" },
    ];
    return (
        <>
            <section className='stepp-inner-main'>

                <Row>
                    <Col lg={8} className='mx-auto'>
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Street Address*</Form.Label>
                                <Form.Control type="text" placeholder="Enter Store Name" />
                            </Form.Group>

                            <Row>
                                <Col lg={6}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Country*</Form.Label>
                                        <Select
                                            defaultValue={selectedOption}
                                            onChange={setSelectedOption}
                                            options={options}
                                            className='mb-3'
                                            classNamePrefix="manualSelect"
                                        />
                                    </Form.Group>

                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>State / Province*</Form.Label>
                                        <Select
                                            defaultValue={selectedOption}
                                            onChange={setSelectedOption}
                                            options={options}
                                            className='mb-3'
                                            classNamePrefix="manualSelect"
                                        />
                                    </Form.Group>

                                </Col>
                            </Row>

                            <Row>
                                <Col lg={6}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>City*</Form.Label>
                                        <Select
                                            defaultValue={selectedOption}
                                            onChange={setSelectedOption}
                                            options={options}
                                            className='mb-3'
                                            classNamePrefix="manualSelect"
                                        />
                                    </Form.Group>

                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>ZIP / Postal Code*</Form.Label>
                                        <Form.Control type="email" placeholder="Enter GST Number" />
                                    </Form.Group>

                                </Col>

                                <Col lg={6}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Contact*</Form.Label>
                                        <PhoneInput
                                            country="in"
                                            value={value}
                                            onChange={setValue}

                                        />
                                    </Form.Group>

                                </Col>
                            </Row>


                        </Form>
                    </Col>
                </Row>
                <div className="save-btn-div">
                    <Common_Button onClick={handlePrevious} type='button' text={"Back"} className="back-btn" />
                    <Common_Button  type='button' text={isSaved ? 'SAVE & NEXT' : 'Next'} className="continue-btn" />


                </div>
                <div className="signup-link">
                    <p>
                        By signing up, I agree to the{" "}
                        <Link to={"/terms-condition"}>
                            Terms and Conditions
                        </Link>
                    </p>

                </div>
                <div className="social-login pb-5">
                    <p>You can also login by using your</p>
                    <div className="social-icons">
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/assest/image/login/facebook.png"
                            }
                            alt="Google"
                        />
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/assest/image/login/google.png"
                            }
                            alt="Google"
                        />
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/assest/image/login/twitter.png"
                            }
                            alt="Google"
                        />
                    </div>
                </div>


            </section>



        </>
    )
}

export default Address