import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { fas } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Select from "react-select";
import classNames from "classnames";
const Shipping_information = ({ handlePrevious, handleNext, addressSelect, paymentMethod, setPaymentMethod }) => {
    const handleMethod = async (value) => {
        await setPaymentMethod(value);
    }

    const [showPassword, setShowPassword] = useState(false);
    const [value, setValue] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
    ];
    return (
        <>
            <section className='Addnewaddress stepp-inner-main'>
                <div className='row'>
                    <div className='col-lg-9 mx-auto'>
                        <Form>
                            <Form.Group controlId="formFirstName">
                                <div className="formflex">
                                    <div className=" nameLabel mb-3">
                                        <Form.Label className="emailtxt">
                                            Prefix*
                                        </Form.Label>
                                        <Form.Select
                                            className="form-control"
                                            aria-label="Default select example"
                                            placeholder="Select Prefix"
                                        >
                                            <option>Select Prefix</option>
                                            <option value="1">Ms</option>
                                            <option value="2">Mrs</option>
                                            <option value="3">Mr</option>
                                        </Form.Select>
                                    </div>
                                    <div className="nameLabel">
                                        <Form.Label className="emailtxt">
                                            First Name*
                                        </Form.Label>
                                        <Form.Control
                                            className="mb-3"
                                            type="text"
                                            placeholder="Enter First Name"
                                        />
                                    </div>
                                    <div className="nameLabel">
                                        <Form.Label className="emailtxt">
                                            Middle Name*
                                        </Form.Label>
                                        <Form.Control
                                            className="mb-3"
                                            type="text"
                                            placeholder="Enter Middle Name"
                                        />
                                    </div>
                                    <div className="nameLabel">
                                        <Form.Label className="emailtxt">
                                            Last Name*
                                        </Form.Label>
                                        <Form.Control
                                            className="mb-3"
                                            type="text"
                                            placeholder="Enter Last Name"
                                        />
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group controlId="formMiddleName">
                                <Form.Label className="emailtxt">
                                    Company
                                </Form.Label>
                                <div className="formfieldregister">
                                    <Form.Control
                                        className="mb-3"
                                        type="text"
                                        placeholder="Enter Company Name"
                                    />


                                </div>
                            </Form.Group>

                            <Form.Group controlId="formFirstName">
                                <Form.Label className="emailtxt">Address*</Form.Label>
                                <Form.Control
                                    className="mb-3"
                                    type="text"
                                    placeholder="Enter Last Name"
                                />
                            </Form.Group>


                            <Row>
                                <Col lg={4}>
                                    <Form.Group controlId="formFirstName">
                                        <Form.Label className="emailtxt">Country*</Form.Label>
                                        <Select
                                            defaultValue={selectedOption}
                                            onChange={setSelectedOption}
                                            options={options}
                                            className='mb-3'
                                            classNamePrefix="manualSelect"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group controlId="formFirstName">
                                        <Form.Label className="emailtxt">State/Province*</Form.Label>
                                        <Select
                                            defaultValue={selectedOption}
                                            onChange={setSelectedOption}
                                            options={options}
                                            className='mb-3'
                                            classNamePrefix="manualSelect"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>

                                    <Form.Group controlId="formFirstName">
                                        <Form.Label className="emailtxt">Pincode*</Form.Label>
                                        <Form.Control
                                            className="mb-3"
                                            type="text"
                                            placeholder="Enter Last Name"
                                        />
                                    </Form.Group>

                                </Col>
                                <Col lg={4}>
                                    <Form.Group controlId="formFirstName">
                                        <Form.Label className="emailtxt">City*</Form.Label>
                                        <Select
                                            defaultValue={selectedOption}
                                            onChange={setSelectedOption}
                                            options={options}
                                            className='mb-3'
                                            classNamePrefix="manualSelect"
                                        />
                                    </Form.Group>
                                </Col>

                                <Col lg={4}>
                                    <Form.Group controlId="formFirstName">
                                        <Form.Label className="emailtxt">Telephone*</Form.Label>
                                        <Form.Control
                                            className="mb-3"
                                            type="text"
                                            placeholder="Enter Last Name"
                                        />
                                    </Form.Group>
                                </Col>


                            </Row>



                        </Form>

                    </div>
                </div>


                <div className="save-btn-div mt-5">
                    <button className="back-btn" onClick={handlePrevious}>Back</button>
                    <button className="continue-btn" onClick={handleNext}> Continue </button>
                </div>
            </section>
        </>
    )
}

export default Shipping_information