import React from 'react'
import { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Offcanvas from 'react-bootstrap/Offcanvas';
import "./HeaderOffcanvas.css";
import { Link } from 'react-router-dom';



const HeaderOffcanvas = (props) => {
    
    const handleLinkClick = () => {
        props.handleClose();
    };

    return (
        <>
            <Offcanvas className="mobheadoffcanvas" show={props.show} onHide={props.handleClose} {...props}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <img className='mobloggoo' src={process.env.PUBLIC_URL + '/assest/image/logo/header-logo.png'} />
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <section>
                        <div className='Headeraccord'>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Medal</Accordion.Header>
                                    <Accordion.Body>
                                        <div className='secondMobiledrop mb-0'>
                                            <div className="subaccmenu"><Link to={"/shop-promotional"} onClick={handleLinkClick}>Silver</Link></div>
                                            <div className="subaccmenu"><Link to={"/shop-promotional"} onClick={handleLinkClick}>Bronze</Link></div>
                                            <div className="subaccmenu"><Link to={"/shop-promotional"} onClick={handleLinkClick}>Copeer</Link></div>
                                            <div className="subaccmenu"><Link to={"/shop-promotional"} onClick={handleLinkClick}>Customize</Link></div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Trophies</Accordion.Header>
                                    <Accordion.Body>
                                        <div className='secondMobiledrop mb-0'>
                                            <div className="subaccmenu"><Link to={"/product-customize"} onClick={handleLinkClick}>Customize</Link></div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>DrinkWare</Accordion.Header>
                                    <Accordion.Body>
                                        <div className='secondMobiledrop mb-0'>
                                            <div className="subaccmenu"><Link to={"/product-customize"} onClick={handleLinkClick}>Customize</Link></div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>Photoframes</Accordion.Header>
                                    <Accordion.Body>
                                        <div className='secondMobiledrop mb-0'>
                                            <div className="subaccmenu"><Link to={"/product-customize"} onClick={handleLinkClick}>Customize</Link></div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>Silver Plated Artefacts</Accordion.Header>
                                    <Accordion.Body>
                                        <div className='secondMobiledrop mb-0'>
                                            <div className="subaccmenu"><Link to={"/shop-promotional"} onClick={handleLinkClick}>Silver</Link></div>
                                            <div className="subaccmenu"><Link to={"/shop-promotional"} onClick={handleLinkClick}>Bronze</Link></div>
                                            <div className="subaccmenu"><Link to={"/shop-promotional"} onClick={handleLinkClick}>Copeer</Link></div>
                                            <div className="subaccmenu"><Link to={"/shop-promotional"} onClick={handleLinkClick}>Customize</Link></div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>Shop Promotional Products</Accordion.Header>
                                    <Accordion.Body>
                                        <div className='secondMobiledrop mb-0'>
                                            <div className="subaccmenu"><Link to={"/shop-promotional"} onClick={handleLinkClick}>Coins</Link></div>
                                            <div className="subaccmenu"><Link to={"/shop-promotional"} onClick={handleLinkClick}>Medals</Link></div>
                                            <div className="subaccmenu"><Link to={"/shop-promotional"} onClick={handleLinkClick}>Plaques</Link></div>
                                            <div className="subaccmenu"><Link to={"/shop-promotional"} onClick={handleLinkClick}>Gadgets</Link></div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>Shop By</Accordion.Header>
                                    <Accordion.Body>
                                        <div className='secondMobiledrop mb-0'>
                                            <div className="subaccmenu"><Link to={"/shop-promotional"} onClick={handleLinkClick}>Price</Link></div>
                                            <div className="subaccmenu"><Link to={"/shop-promotional"} onClick={handleLinkClick}>Interest</Link></div>
                                            <div className="subaccmenu"><Link to={"/shop-promotional"} onClick={handleLinkClick}>Categories</Link></div>
                                            <div className="subaccmenu"><Link to={"/shop-promotional"} onClick={handleLinkClick}>Best Sellers</Link></div>
                                            <div className="subaccmenu"><Link to={"/shop-promotional"} onClick={handleLinkClick}>Occasions / Events</Link></div>
                                            <div className="subaccmenu"><Link to={"/shop-promotional"} onClick={handleLinkClick}>Customise</Link></div>
                                            <div className="subaccmenu"><Link to={"/shop-promotional"} onClick={handleLinkClick}>Offers</Link></div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="7">
                                    <Accordion.Header><Link to={"/product-customize"} onClick={handleLinkClick}>Customise</Link></Accordion.Header>                                    
                                </Accordion.Item>

                                <Accordion.Item eventKey="8">
                                    <Accordion.Header>Offers</Accordion.Header>
                                    <Accordion.Body>
                                        <div className='secondMobiledrop mb-0'>
                                            <div className="subaccmenu"><Link to={"/shop-promotional"} onClick={handleLinkClick}>Silver</Link></div>
                                            <div className="subaccmenu"><Link to={"/shop-promotional"} onClick={handleLinkClick}>Bronze</Link></div>
                                            <div className="subaccmenu"><Link to={"/shop-promotional"} onClick={handleLinkClick}>Copeer</Link></div>
                                            <div className="subaccmenu"><Link to={"/shop-promotional"} onClick={handleLinkClick}>Customize</Link></div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </section>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default HeaderOffcanvas;