import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import './ProductCard.css';
import { Link } from 'react-router-dom';
import Heart_Component from "../Heart_Component/Heart_Component";
import ReviewModal from '../Commonmodal/ReviewModal/ReviewModal';
import AOS from "aos";
import "aos/dist/aos.css";
import { Rating } from "react-simple-star-rating";

const ProductCard = ({ order, showRating, handleRating, rating, review }) => {
    const [modalShow, setModalShow] = useState(false);
    const [rateShow, setrateShow] = useState(false);
    const getStatusColor = () => {
        switch (order.status) {
            case 'Ordered':
                return '#234C9E';
            case 'Delivered':
                return '#00940A';
            case 'Returned':
                return '#D60101';
            case 'Shipped':
                return '#234C9E';
            default:
                return '#000';
        }
    };

    useEffect(() => {
        AOS.init({ once: true });
    }, []);

    return (
        <section className='Myorders'>
            <Card className="order-card" data-aos="fade-up"
                data-aos-duration="3000">
                <Heart_Component />
                <div className='productImg'>
                    <Link to={"/productDetails"}>
                        <Card.Img variant="top" src={order.image} />
                    </Link>

                </div>
                <div className='orderedstatusMain'>
                    <Link to={"/returnOrder"}>
                        <div className='orderedstatus' style={{ color: getStatusColor() }}>{order.status}</div>
                    </Link>
                </div>
                <Card.Body>
                    <Card.Title>{order.name}</Card.Title>
                    <Card.Text className='mb-0'>
                        <div className='productPrice'>
                            ₹ {order.price}
                        </div>
                    </Card.Text>
                    <div className='Orderdate'>{order.date}</div>

                    {
                        order.status === "Delivered" && (
                            <>
                                <div className={`ratethisproduct ${rateShow ? "showreview" : "removeReview"}`} onClick={() => setrateShow(true)}>
                                    <div className='starImg_txt'>
                                        <span> Rate this Product</span>
                                        <span><img src={process.env.PUBLIC_URL + "/assest/image/Careers/gropStars.png"} className='groupStars' /></span>
                                    </div>
                                </div>
                            </>
                        )
                    }

                    {
                        rateShow && (
                            <>
                                <Rating
                                    onClick={handleRating}
                                    initialValue={rating}
                                    fillColor="#DF4223"
                                    className="react-simple-star-rating start"
                                    SVGclassName="star-svg"
                                    size={20}
                                />
                                <div className='addreview  mt-1' onClick={() => setModalShow(true)}>{review}</div>


                            </>

                        )
                    }
                </Card.Body>
            </Card>

            <ReviewModal show={modalShow} onHide={() => setModalShow(false)} />
        </section >
    );
};

export default ProductCard;
