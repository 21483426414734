import { React, useState, useContext } from "react";
import "./ContactUs.css";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const ContactUs = () => {
  const [showForm, setShowForm] = useState(false);
  const [showBg, setShowBg] = useState(false);
  const [showContactTextHolder, setShowContactTextHolder] = useState(true);
  const [value, setValue] = useState();

  const handleMapTextClick = () => {
    setShowForm(true);
    setShowBg(true);
    setShowContactTextHolder(false);
  };
  return (
    <>
      <section className="contact-us  ">
        <div className="map-section">
          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe
                className="gmap_iframe"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=pune&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
              <a href="https://pdflist.com/" alt="pdflist.com"></a>
            </div>
          </div>
        </div>

        <div
          className={`contact_form_holder ${showBg ? "contact_form_bg" : ""}`}
        >
          <div className="container">
            <div className={`contactusmain ${showBg ? "contact_form_none" : ""}`}>
              <div className="heading-holder mb-4">
                <h2>Contact us</h2>
              </div>
              <div className="row justify-content-end">
                <div className="col-lg-5 col-md-6">
                  <div className={`form-holder ${showBg ? "slide-out-left" : ""}`}>
                    <div
                      className={`arrow-holder ${showForm ? "arrow-holder" : "d-none"
                        }`}
                    >

                      <img
                        src={
                          process.env.PUBLIC_URL + "/assest/Icon/arrow-left.png"
                        }
                        className="left-arrow"
                      />
                    </div>

                    <Form>
                      <Row className="form_container">
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Control
                            type="text"
                            name="name"
                            placeholder="Your name"
                          />
                        </Form.Group>

                        <Form.Group controlId="exampleForm.ControlInput1 ">
                          <Form.Control
                            type="text"
                            name="email"
                            placeholder="Email"
                          />
                        </Form.Group>

                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Control
                            type="text"
                            name="subject"
                            placeholder="Subject"
                          />
                        </Form.Group>

                        <PhoneInput
                          country="in"
                          value={value}
                          onChange={setValue}
                          className="mb-3"
                        />

                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Control
                            type="text"
                            name="message"
                            as="textarea"
                            placeholder="Your Message"
                          />
                        </Form.Group>

                        <Form.Check
                          type="checkbox"
                          label="I have read and agree to the Privacy Policy."
                        />

                        <div className="btn-holder">
                          <button className="sendMessage" type="submit">
                            Send Message
                          </button>
                        </div>
                      </Row>
                    </Form>
                  </div>
                </div>

                <div className="col-lg-7 col-md-6">
                  <div
                    className={`contact-text-holder ${showBg ? "slide-out-right" : ""
                      }`}
                  >
                    <div className="d-flex mb-3">
                      <div className="icon-holder me-3">
                        <FontAwesomeIcon
                          className="call-icon"
                          icon="fa-solid fa-phone"
                        />
                      </div>
                      <div className="address-holder">
                        <p>01123968786</p>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="icon-holder me-3">
                        <FontAwesomeIcon
                          className="call-icon"
                          icon="fa-solid fa-envelope"
                        />
                      </div>
                      <div className="address-holder">
                        <p>support@ninjakraft.com</p>
                      </div>
                    </div>

                    <div className="d-flex">
                      <div className="icon-holder me-3">
                        <FontAwesomeIcon
                          className="call-icon"
                          icon="fa-solid fa-location-dot"
                        />
                      </div>
                      <div className="address-holder">
                        <p>
                          516/4, Gali Rajan Kalan, Kashmere <br /> Gate Delhi,
                          Delhi, 110006
                        </p>
                      </div>
                    </div>

                    <div className="d-flex">
                      <div className="icon-holder me-3">
                        <FontAwesomeIcon
                          className="call-icon"
                          icon="fa-solid fa-map-location-dot"
                        />
                      </div>
                      <div
                        className="address-holder map-text"
                        onClick={handleMapTextClick}
                      >
                        <p>Find Us on Map</p>
                      </div>
                    </div>


                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="">
          <div className="">
            
          </div>
        </div>
      </section>
      {/* <Successfull_Modal show={modalShow} message={"Form Submitted Successfully"} onHide={() => setModalShow(false)} /> */}
    </>
  );
};

export default ContactUs;
