import React, { useState } from 'react';
import './GiftCustomization.css';
import { Button, Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Select from 'react-select';
import { Uploader } from 'uploader';
import { UploadButton } from 'react-uploader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from 'react-bootstrap/Card';
import Talktous from '../Common-Component/Offcanvas/Talktous/Talktous';
import Common_Button from '../Common-Component/Common_Button/Common_Button';

const GiftCustomization = () => {
    const [value, setValue] = useState();
    const [selectedOption, setSelectedOption] = useState(null);

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
    ];

    const uploader = Uploader({ apiKey: 'free' });
    const uploadOptions = { multi: true };

    const customizationSteps = [
        { title: 'Choose', text: 'A Customizable Gift' },
        { title: 'Upload', text: 'Your Artwork' },
        { title: 'Order', text: 'With Confidence' },
    ];

    const sampleGoods = [
        {
            title: 'Customized Sample',
            description: 'This product decorated with your logo or artwork',
            additionalInfo: [
                { heading: 'Receive within', text: '2 to 48 hrs' },
                { heading: 'Recommended If:', text: 'you’re still ideating or want to share with your team for approval' },
            ],
            icon: 'pdf.png',
        },
        {
            title: 'Random Logo Sample',
            description: 'This product decorated with a random company’s logo/design',
            additionalInfo: [
                { heading: 'Receive within', text: '2 to 48 hrs' },
                { heading: 'Recommended If:', text: 'you’re still ideating or want to share with your team for approval' },
            ],
            icon: 'molecule.png',
        },
        {
            title: 'Customized Sample',
            description: 'This product decorated with your logo or artwork',
            additionalInfo: [
                { heading: 'Receive within', text: '2 to 48 hrs' },
                { heading: 'Recommended If:', text: 'you’re still ideating or want to share with your team for approval' },
            ],
            icon: 'pdf.png',
        },
    ];

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <section className='GiftCustomization'>
                <div className='imgMain'>
                    <img src={`${process.env.PUBLIC_URL}/assest/image/Careers/banner.png`} className='bannerImg' alt="Banner" />
                    <div className='container'>
                        <div className='customizingWorking'>
                            <Row>
                                <Col xl={6} md={6}>
                                    <div>
                                        <div className='customizehead'>
                                            <h3>How <br /><b>Customization Works</b></h3>
                                        </div>
                                        <div className='text-center'>
                                            <img src={`${process.env.PUBLIC_URL}/assest/image/Careers/workingImg.png`} className='workingImg' alt="Working" />
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={6} md={6}>
                                    {customizationSteps.map((step, index) => (
                                        <div className='custImgmain' key={index}>
                                            <img src={`${process.env.PUBLIC_URL}/assest/image/Careers/customizeWork.png`} className='rightSideimg' alt={step.title} />
                                            <div className='custImgTxt'>
                                                <h3>{step.title}</h3>
                                                <p>{step.text}</p>
                                            </div>
                                        </div>
                                    ))}
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <div className='container'>
                        <div className='formMain'>
                            <div className='custoHead'>
                                <h3>Customization Form</h3>
                            </div>
                            <Form>
                                <Row>
                                    <Col lg={6} md={6}>
                                        <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                                            <Form.Control type='text' placeholder='Enter Name' />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6} md={6}>
                                        <PhoneInput
                                            country='in'
                                            value={value}
                                            onChange={setValue}
                                            placeholder='Enter Contact no'
                                            className='mb-3'
                                        />
                                    </Col>
                                    <Col lg={6} md={6}>
                                        <Form.Group className='mb-3' controlId='exampleForm.ControlInput2'>
                                            <Form.Control type='email' placeholder='Enter Email Id' />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6} md={6}>
                                        <Form.Group className='mb-3' controlId='exampleForm.ControlInput3'>
                                            <Form.Control type='text' placeholder='Enter Company Name' />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6} md={6}>
                                        <Select
                                            defaultValue={selectedOption}
                                            onChange={setSelectedOption}
                                            options={options}
                                            className='mb-3'
                                            classNamePrefix='manualSelect'
                                        />
                                    </Col>
                                </Row>

                                <div className='Addproduct'>
                                    <div className='fggggg'>
                                        <p>Add Product</p>
                                    </div>
                                    <UploadButton
                                        uploader={uploader}
                                        options={uploadOptions}
                                        onComplete={(files) => alert(files.map(x => x.fileUrl).join('\n'))}
                                    >
                                        {({ onClick }) => (
                                            <button className='uploadDiv' onClick={onClick}>
                                                <FontAwesomeIcon icon='fa-solid fa-circle-plus' />
                                                <p className='mb-1'>Upload here</p>
                                            </button>
                                        )}
                                    </UploadButton>
                                </div>

                                <div className='checkLabel'>
                                    <Form.Check
                                        type='radio'
                                        label={
                                            <div>
                                                <p className='mb-0'>Add Custom Design</p>
                                                <p className='mb-0'>(Add Logo / Artwork)</p>
                                            </div>
                                        }
                                    />
                                </div>

                                <div className='mt-5'>
                                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                                        <Form.Label>Add Special Requests</Form.Label>
                                        <Form.Control as='textarea' />
                                    </Form.Group>
                                </div>
                            </Form>
                        </div>
                    </div>

                    <div className='NeedHelp'>
                        <div className='container'>
                            <div className='Needhelpheading'>
                                <h3><strong>Need Help</strong> With Gifting Programs?</h3>
                                <div className='Talktousbtn'>

                                    <Common_Button
                                        text="TALK TO US"
                                        className='talk-us'
                                        onClick={handleShow}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <Talktous show={show} handleClose={handleClose} placement="end" name="end" />

                    <div className='Samplegoods'>
                        <div className='container'>
                            <div className='sampleHead'>
                                <h3>Sample The Goods <br /><b>Before Sending</b></h3>
                            </div>
                            <div className='sampleRowMain'>
                                <Row>
                                    {sampleGoods.map((good, index) => (
                                        <Col xl={4} lg={4} md={6} key={index}>
                                            <Card className='samplecard'>
                                                <div className='sampleHeadinginfo'>
                                                    <h3>{good.title}</h3>
                                                    <p>{good.description}</p>
                                                </div>
                                                <div className='downside'>
                                                    {good.additionalInfo.map((info, idx) => (
                                                        <div className='d-flex cardtxt' key={idx}>
                                                            <img src={`${process.env.PUBLIC_URL}/assest/image/Careers/clock.png`} className='clockImg' alt="Clock" />
                                                            <div>
                                                                <h3>{info.heading}</h3>
                                                                <p>{info.text}</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className='iconCircle'>
                                                    <img src={`${process.env.PUBLIC_URL}/assest/image/Careers/${good.icon}`} alt={good.title} />
                                                </div>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default GiftCustomization;
