import React from 'react'
import "./AllCategories.css";
import Card from 'react-bootstrap/Card';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../Common-Component/Breadcrumbs/Breadcrumbs';
const AllCategories = () => {
    const products = [
        { id: 1, title: "Trophy", text: "₹. 00.00/-", imgSrc: "/assest/image/AllProducts/ProductImg1.png" },
        { id: 2, title: "Trophy", text: "₹. 00.00/-", imgSrc: "/assest/image/AllProducts/ProductImg1.png" },
        { id: 3, title: "Cotton Polo T-Shirt", text: "₹. 00.00/-", imgSrc: "/assest/image/AllProducts/ProductImg2.png" },
        { id: 4, title: "Ceramic Mugs", text: "₹. 00.00/-", imgSrc: "/assest/image/AllProducts/ProductImg3.png" },
        { id: 5, title: "Trophy", text: "₹. 00.00/-", imgSrc: "/assest/image/AllProducts/ProductImg1.png" },
    ];

    const items = [
        { id: 1, text: "HOME" },
        { id: 1, text: "CATEGORIES" },
    ]
    return (
        <>
            <section className='AllCategory'>
                <div className='container'>
                <Breadcrumbs items={items} />
                    <div className='categoryHead'>
                        <h3>CATEGORIES</h3>
                    </div>
                    <div className='card categoryCard'>
                        <h3>EMPLOYEE APPRECIATION</h3>

                        <div className='viewMore'>
                            <Link to={"/emp-satifaction"}>
                                <p>View More</p>
                            </Link>
                        </div>
                        <div className='row appreciationMain'>
                            {products.map(product => (
                                <Col key={product.id} xl={2} lg={3} md={4} sm={6}>
                                    <Card className='appreciationCard'>
                                        <div className='p-2'>
                                            <Card.Img variant="top" src={process.env.PUBLIC_URL + product.imgSrc} />
                                        </div>
                                        <Card.Body>
                                            <Card.Title>{product.title}</Card.Title>
                                            <Card.Text>
                                                {product.text}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </div>
                    </div>

                    <div className='card categoryCard'>
                        <h3>NEW JOINING</h3>

                        <div className='viewMore'>
                            <Link to={"/emp-satifaction"}>
                                <p>View More</p>
                            </Link>
                        </div>
                        <div className='row appreciationMain'>
                            {products.map(product => (
                                <Col key={product.id} xl={2} lg={3} md={4} sm={6}>
                                    <Card className='appreciationCard'>
                                        <div className='p-2'>
                                            <Card.Img variant="top" src={process.env.PUBLIC_URL + product.imgSrc} />
                                        </div>
                                        <Card.Body>
                                            <Card.Title>{product.title}</Card.Title>
                                            <Card.Text>
                                                {product.text}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </div>
                    </div>


                    <div className='card categoryCard'>
                        <h3>EMPLOYEE PERSONAL GIFTS</h3>

                        <div className='viewMore'>
                            <Link to={"/emp-satifaction"}>
                                <p>View More</p>
                            </Link>
                        </div>
                        <div className='row appreciationMain'>
                            {products.map(product => (
                                <Col key={product.id} xl={2} lg={3} md={4} sm={6}>
                                    <Card className='appreciationCard'>
                                        <div className='p-2'>
                                            <Card.Img variant="top" src={process.env.PUBLIC_URL + product.imgSrc} />
                                        </div>
                                        <Card.Body>
                                            <Card.Title>{product.title}</Card.Title>
                                            <Card.Text>
                                                {product.text}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AllCategories