import React from 'react'
import "./Faq.css";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Accordion from 'react-bootstrap/Accordion';
import Tab from 'react-bootstrap/Tab';
const Faq = () => {
    return (
        <>
            <section className='Faq'>
                <div className='container'>
                    <div className='FaqBannermain'>
                        <div className='bannerImg'>
                            <img src={process.env.PUBLIC_URL + "/assest/image/Faq/faqBannerImg.png"} />
                            <div className='onbannertxt'>
                                <p>Get Answers </p>
                                <p>to your questions</p>
                            </div>
                        </div>
                    </div>


                    <div className='row faqTabs'>

                        <Tab.Container id="left-tabs-example" defaultActiveKey="1">

                            <Nav variant="pills" className="faqtabsNav">
                                <Nav.Item>
                                    <Nav.Link eventKey="1">All</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="2">Customization</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="3">Shipping</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="4">e-Gifts</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="5">Gifting Platform</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="6">Automation</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="7">Marketplace</Nav.Link>
                                </Nav.Item>
                            </Nav>

                            <Tab.Content>
                                <Tab.Pane eventKey="1">
                                    <div className='card accordionCard'>
                                        <div className='card-body'>
                                            <Accordion>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>If I’m not buying for a business, can I still use CorporateGift?</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='faqAccordhead'>
                                                            <p>Absolutely! We welcome all customers who wish to shop at Mittalcraftworks.com. If you’re looking for a single gift, we recommend browsing our No Minimum Quantity.</p>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>Are there any charges for registration?</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='faqAccordhead'>
                                                            <p>Absolutely! We welcome all customers who wish to shop at Mittalcraftworks.com. If you’re looking for a single gift, we recommend browsing our No Minimum Quantity.</p>
                                                        </div>

                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header>Do I have to necessarily register to shop on Local Mandai?</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='faqAccordhead'>
                                                            <p>Absolutely! We welcome all customers who wish to shop at Mittalcraftworks.com. If you’re looking for a single gift, we recommend browsing our No Minimum Quantity.</p>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header>Can I have multiple registrations?</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='faqAccordhead'>
                                                            <p>Absolutely! We welcome all customers who wish to shop at Mittalcraftworks.com. If you’re looking for a single gift, we recommend browsing our No Minimum Quantity.</p>
                                                        </div>

                                                    </Accordion.Body>
                                                </Accordion.Item>

                                                <Accordion.Item eventKey="4">
                                                    <Accordion.Header>Can I add more than one delivery address in an account?</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='faqAccordhead'>
                                                            <p>Absolutely! We welcome all customers who wish to shop at Mittalcraftworks.com. If you’re looking for a single gift, we recommend browsing our No Minimum Quantity.</p>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="2">
                                    <div className='card accordionCard'>
                                        <div className='card-body'>
                                            <Accordion>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>Can I add company branding to my gifts?</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='faqAccordhead'>
                                                            <p>Absolutely! We welcome all customers who wish to shop at Mittalcraftworks.com. If you’re looking for a single gift, we recommend browsing our No Minimum Quantity.</p>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>How do I add my custom design through your portal?</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='faqAccordhead'>
                                                            <p>Absolutely! We welcome all customers who wish to shop at Mittalcraftworks.com. If you’re looking for a single gift, we recommend browsing our No Minimum Quantity.</p>
                                                        </div>

                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header>What printing methods are available?</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='faqAccordhead'>
                                                            <p>Absolutely! We welcome all customers who wish to shop at Mittalcraftworks.com. If you’re looking for a single gift, we recommend browsing our No Minimum Quantity.</p>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header>Which products are available for customization?</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='faqAccordhead'>
                                                            <p>Absolutely! We welcome all customers who wish to shop at Mittalcraftworks.com. If you’re looking for a single gift, we recommend browsing our No Minimum Quantity.</p>
                                                        </div>

                                                    </Accordion.Body>
                                                </Accordion.Item>

                                                <Accordion.Item eventKey="4">
                                                    <Accordion.Header>What are set up fees?</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='faqAccordhead'>
                                                            <p>Absolutely! We welcome all customers who wish to shop at Mittalcraftworks.com. If you’re looking for a single gift, we recommend browsing our No Minimum Quantity.</p>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="3">Second tab content</Tab.Pane>
                                <Tab.Pane eventKey="4">Second tab content</Tab.Pane>
                                <Tab.Pane eventKey="5">Second tab content</Tab.Pane>
                                <Tab.Pane eventKey="6">Second tab content</Tab.Pane>
                                <Tab.Pane eventKey="7">Second tab content</Tab.Pane>
                            </Tab.Content>

                        </Tab.Container>


                    </div>
                </div>
            </section>
        </>
    )
}

export default Faq