import React from "react";
import "./App.css";
import { useLocation } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import AllRoutes from "./components/AllRoutes/AllRoutes";

function App() {
  const location = useLocation();
  const isWelcomePage = location.pathname === '/';
  const isAuthPage = location.pathname === '/login' || location.pathname === '/register' || location.pathname === '/forgetpassword';

  return (
    <>
      <ScrollToTop />
      {!isWelcomePage && !isAuthPage && <Header />}
      <AllRoutes />

      {!isWelcomePage && !isAuthPage && <Footer />}
    </>
  );
}

export default App;
