import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "react-medium-image-zoom/dist/styles.css";
import "./ProductDetails.css";
import Accordion from 'react-bootstrap/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BestSellers from '../BestSellers/BestSellers';
import BestMatched from '../BestMatched/BestMatched';
import RecentlyViewed from '../RecentlyViewed/RecentlyViewed';
import { Link } from 'react-router-dom';
import ReviewVideomodal from "../Common-Component/Commonmodal/ReviewVideomodal/ReviewVideomodal";
import Heart_Component from '../Common-Component/Heart_Component/Heart_Component';
import Breadcrumbs from '../Common-Component/Breadcrumbs/Breadcrumbs';

const ProductDetails = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [swiperInstance, setSwiperInstance] = useState(null);
    const [reviewshow, setReviewshow] = useState(false);
    const handleImageClick = (imageSrc) => {
        setSelectedImage(imageSrc);
    };

    const sizes = [
        { info: 'N/A', size: 'Small', color: "red" },
        { info: 'N/A', size: 'Medium' },
        { info: 'N/A', size: 'Large' },
        { info: 'N/A', size: 'XL' },
        { info: 'N/A', size: '2XL' },
        { info: 'N/A', size: '3XL' },
        { info: 'N/A', size: '4XL' },
        // add more objects if needed
    ];

    const data = [
        { size: '12-24', quantity: '₹ 0000' },
        { size: '12-24', quantity: '₹ 0000' },
        { size: '12-24', quantity: '₹ 0000' },
        { size: '12-24', quantity: '₹ 0000' },
        { size: '12-24', quantity: '₹ 0000' },
        { size: '12-24', quantity: '₹ 0000' },
        { size: '12-24', quantity: 'Get a Quote' },
    ];


    class Zoomable {
        constructor(element, config) {
            this.element = element;
            this.config = this._mergeConfig(config);

            const { initialZoom, minZoom, maxZoom } = this.config;

            this.zoomed = false;
            this.initialZoom = Math.max(Math.min(initialZoom, maxZoom), minZoom);
            this.zoom = this.initialZoom;

            this.img = element.querySelector(".zoomable__img");
            this.img.draggable = false;
            this.element.style.setProperty("--zoom", this.initialZoom);

            this._addEventListeners();
        }

        static get Default() {
            return Default;
        }

        _addEventListeners() {
            this.element.addEventListener("mouseenter", () => this._handleMouseover());
            this.element.addEventListener("mousemove", (evt) =>
                this._handleMousemove(evt)
            );
            this.element.addEventListener("mouseleave", () => this._handleMouseout());
            this.element.addEventListener("wheel", (evt) => this._handleWheel(evt));
        }

        _handleMouseover() {
            if (this.zoomed) {
                return;
            }

            this.element.classList.add("zoomable--zoomed");
            this.zoomed = true;
        }

        _handleMousemove(evt) {
            if (!this.zoomed) {
                return;
            }

            const elPos = this.element.getBoundingClientRect();
            const percentageX = `${((evt.clientX - elPos.left) * 100) / elPos.width}%`;
            const percentageY = `${((evt.clientY - elPos.top) * 100) / elPos.height}%`;

            this.element.style.setProperty("--zoom-pos-x", percentageX);
            this.element.style.setProperty("--zoom-pos-y", percentageY);
        }

        _handleMouseout() {
            if (!this.zoomed) {
                return;
            }

            this.element.style.setProperty("--zoom", this.initialZoom);
            this.element.classList.remove("zoomable--zoomed");
            this.zoomed = false;
        }

        _handleWheel(evt) {
            if (!this.zoomed) {
                return;
            }

            evt.preventDefault();

            const newZoom = this.zoom + evt.deltaY * (this.config.zoomSpeed * -1);
            const { minZoom, maxZoom } = this.config;

            this.zoom = Math.max(Math.min(newZoom, maxZoom), minZoom);
            this.element.style.setProperty("--zoom", this.zoom);
        }

        _mergeConfig(config) {
            return {
                ...this.constructor.Default,
                ...(typeof config === "object" ? config : {}),
            };
        }
    }

    const Default = {
        initialZoom: 3,
        minZoom: 1.25,
        maxZoom: 4,
        zoomSpeed: 0.01,
    };

    const zoomableRef = useRef(null);

    useEffect(() => {
        const element = zoomableRef.current;
        if (!element) return;

        const zoomable = new Zoomable(element);

        return () => {
            element.removeEventListener("mouseenter", zoomable._handleMouseover);
            element.removeEventListener("mousemove", zoomable._handleMousemove);
            element.removeEventListener("mouseleave", zoomable._handleMouseout);
            element.removeEventListener("wheel", zoomable._handleWheel);
        };
    }, []);

    const images = [
        { image: process.env.PUBLIC_URL + "/assest/image/Careers/medal.png" },
        { image: process.env.PUBLIC_URL + "/assest/image/Careers/prod2.png" },
        { image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png" },
        { image: process.env.PUBLIC_URL + "/assest/image/Careers/medal.png" },
        { image: process.env.PUBLIC_URL + "/assest/image/Careers/prod2.png" },
        { image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png" },
    ]



    const breadcrumbItems = [
        { text: "HOME", link: "/home", className: "active" },
        { text: "PROMOTIONAL PRODUCTS", link: "/promotional-product", className: "active" },
        { text: "PRODUCT DETAILS", link: "/product-cart-inner", className: "active" },
    ];
    return (
        <>
            <section className='ProductInner'>
                <div className='container'>
                    <Breadcrumbs items={breadcrumbItems} />
                    <div className='row mt-5'>
                        <Col xxl={6} xl={6} lg={6} md={7}>
                            <div className="row  camera-sec">
                                <Col xl={3} lg={3} md={3} className='verticalSlider'>
                                    <div>
                                        <div className=" camera-slider">
                                            <div className="row p-0 m-0">
                                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                    <Swiper
                                                        slidesPerView={"auto"}
                                                        modules={[Autoplay]}
                                                        onSwiper={(swiper) => setSwiperInstance(swiper)}
                                                        direction='vertical'
                                                        className="mySwiper"
                                                        breakpoints={{
                                                            0: {
                                                                slidesPerView: 3,
                                                                spaceBetween: 10,
                                                            },
                                                            370: {
                                                                slidesPerView: 4,
                                                                spaceBetween: 10,
                                                            },
                                                            485: {
                                                                slidesPerView: 4,
                                                                spaceBetween: 10,
                                                            },
                                                            575: {
                                                                slidesPerView: 4,
                                                                spaceBetween: 30,
                                                            },

                                                            768: {
                                                                slidesPerView: 6,
                                                                spaceBetween: 20,
                                                            },
                                                            992: {
                                                                slidesPerView: 6,
                                                                spaceBetween: 30,
                                                            },
                                                            1024: {
                                                                slidesPerView: 4,
                                                                spaceBetween: 30,
                                                            },
                                                            1200: {
                                                                slidesPerView: 4,
                                                                spaceBetween: 30,
                                                            },
                                                            1440: {
                                                                slidesPerView: 5,
                                                                spaceBetween: 30,
                                                            },
                                                            2000: {
                                                                slidesPerView: 5,
                                                                spaceBetween: 20,
                                                            },
                                                        }}
                                                        navigation
                                                        pagination={{ clickable: true }}

                                                    >
                                                        {images?.map((slide, index) => (
                                                            <SwiperSlide key={index}>
                                                                <div className="Main-div">
                                                                    <div
                                                                        className="camera-imgs"
                                                                        onClick={() =>
                                                                            handleImageClick(slide?.image)
                                                                        }
                                                                    >
                                                                        <img
                                                                            src={slide?.image}
                                                                            className="inner-img"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                        ))}
                                                    </Swiper>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>



                                <Col xl={9} lg={9} md={9}>
                                    <div className="card-sec ">
                                        <Heart_Component />
                                        <div className="product-detail text-center">
                                            {selectedImage ? (
                                                <div ref={zoomableRef}>
                                                    <img
                                                        src={selectedImage}
                                                        className="zoomable__img"
                                                        alt="Selected"
                                                    />
                                                </div>
                                            ) : (
                                                <div className="specs" ref={zoomableRef}>
                                                    <img
                                                        src={process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png"}
                                                        className="zoomable__img"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                </Col>

                                <div className='horizantalSlider'>
                                    <div className=" camera-slider">
                                        <div className="row p-0 m-0">
                                            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                <Swiper
                                                    slidesPerView={"auto"}
                                                    modules={[Autoplay]}
                                                    onSwiper={(swiper) => setSwiperInstance(swiper)}
                                                    breakpoints={{
                                                        0: {
                                                            slidesPerView: 3,
                                                            spaceBetween: 10,
                                                        },
                                                        370: {
                                                            slidesPerView: 4,
                                                            spaceBetween: 10,
                                                        },
                                                        485: {
                                                            slidesPerView: 4,
                                                            spaceBetween: 10,
                                                        },
                                                        575: {
                                                            slidesPerView: 4,
                                                            spaceBetween: 30,
                                                        },

                                                        768: {
                                                            slidesPerView: 4,
                                                            spaceBetween: 20,
                                                        },
                                                        992: {
                                                            slidesPerView: 6,
                                                            spaceBetween: 30,
                                                        },
                                                        1024: {
                                                            slidesPerView: 4,
                                                            spaceBetween: 30,
                                                        },
                                                        1200: {
                                                            slidesPerView: 4,
                                                            spaceBetween: 30,
                                                        },
                                                        1440: {
                                                            slidesPerView: 5,
                                                            spaceBetween: 30,
                                                        },
                                                        2000: {
                                                            slidesPerView: 5,
                                                            spaceBetween: 20,
                                                        },
                                                    }}
                                                    navigation
                                                    pagination={{ clickable: true }}
                                                    className="mySwiper"
                                                >
                                                    {images?.map((slide, index) => (
                                                        <SwiperSlide key={index}>
                                                            <div className="Main-div">
                                                                <div
                                                                    className="camera-imgs"
                                                                    onClick={() =>
                                                                        handleImageClick(slide?.image)
                                                                    }
                                                                >
                                                                    <img
                                                                        src={slide?.image}
                                                                        className="inner-img"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </SwiperSlide>
                                                    ))}
                                                </Swiper>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            {/* Product Review */}
                            <div className='ProductReview'>
                                <h3 className='reviewHeading'>Product Review (5)</h3>
                                <div className='allreviews'>
                                    <div className='row'>
                                        <Col xl={9} lg={9} md={9} xs={9}>
                                            <div className='reviewHead'>
                                                <p className='reviewName'>Shubham Kumar  (<FontAwesomeIcon icon="fa-solid fa-star" /> 4)</p>
                                                <p>Lorem Ipsum is simply dummy text of the printing & typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

                                                <div className='helpReportbtnsec'>
                                                    <Button className='btn helpful'>Helpful?</Button>
                                                    <p>Report</p>
                                                </div>

                                            </div>

                                        </Col>
                                        <Col xl={3} lg={3} md={3} xs={3}>
                                            <div className='reviewImgmain'>
                                                <img src={process.env.PUBLIC_URL + "/assest/image/AllProducts/reviewImg.png"} className='reviewImg' />
                                                <img src={process.env.PUBLIC_URL + "/assest/image/Careers/playIcon.png"} className='playIconimg' onClick={() => setReviewshow(true)} />
                                            </div>
                                        </Col>


                                    </div>

                                    <div className='row'>
                                        <Col xl={9} lg={9} md={9} xs={9}>
                                            <div className='reviewHead'>
                                                <p className='reviewName'>Shubham Kumar  (<FontAwesomeIcon icon="fa-solid fa-star" /> 4)</p>
                                                <p>Lorem Ipsum is simply dummy text of the printing & typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

                                                <div className='helpReportbtnsec'>
                                                    <Button className='btn helpful'>Helpful?</Button>
                                                    <p>Report</p>
                                                </div>

                                            </div>

                                        </Col>
                                        <Col xl={3} lg={3} md={3} xs={3}>
                                            <div className='reviewImgmain'>
                                                <img src={process.env.PUBLIC_URL + "/assest/image/AllProducts/reviewImg.png"} className='reviewImg' />
                                                <img src={process.env.PUBLIC_URL + "/assest/image/Careers/playIcon.png"} className='playIconimg' onClick={() => setReviewshow(true)} />
                                            </div>
                                        </Col>
                                    </div>

                                </div>



                            </div>


                            <BestMatched />

                            <BestSellers />
                            <RecentlyViewed />
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={5}>
                            <Row className=" productInfo justify-content-center">
                                <Col lg={9} xs={12} md={12}>
                                    <Card className="mb-4 productInfocard">
                                        <Card.Body>
                                            <div className='productInfohead'>
                                                <h3>Nike Dri-FIT Legend Tee - Men's</h3>

                                            </div>
                                            <Card.Subtitle className="mb-2 text-muted">₹ 0000</Card.Subtitle>
                                            <Card.Text>
                                                <div className='startpointsmain'>
                                                    <span>⭐ 4.5</span>
                                                    <br />
                                                    <span><img src={process.env.PUBLIC_URL + "/assest/image/Careers/circleStar.png"} className='circleStar' />You will earn 39 Points for purchasing this product.</span>

                                                </div>
                                            </Card.Text>



                                            <div className="ColourMain">
                                                <div className='colourSubhead'>
                                                    <h3>Colours</h3>
                                                    <p>Game Royal</p>
                                                </div>

                                                <div className='colourSection'>
                                                    <div className='productImgcolour'>
                                                        <img src={process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png"} />
                                                    </div>

                                                    <div className='productImgcolour'>
                                                        <img src={process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png"} />
                                                    </div>

                                                    <div className='productImgcolour'>
                                                        <img src={process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png"} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <div className='productSubhead'>

                                                    <Accordion>
                                                        <Accordion.Item eventKey="0">
                                                            <Accordion.Header> <h3>CHOOSE SIZE & QUANTITIES:</h3></Accordion.Header>
                                                            <Accordion.Body>
                                                                <div className='sizeChartMain'>
                                                                    {sizes.map((item, index) => (
                                                                        <div key={index} className='sizechart'>
                                                                            <Form.Control className='chartinfo mb-0' type="number" placeholder='N/A' />
                                                                            <p style={{ color: `${item.color}` }}>{item.size}</p>
                                                                        </div>
                                                                    ))}
                                                                </div>

                                                            </Accordion.Body>
                                                        </Accordion.Item>

                                                    </Accordion>
                                                </div>

                                                <div className='totalQuantitysec'>
                                                    <Form.Group controlId="formTotalQuantity" className='formQuantity'>
                                                        <Form.Control className='mb-3' type="number" placeholder="Total Quantity:" />
                                                    </Form.Group>

                                                    <p>₹ 0000</p>

                                                </div>

                                                <div className='quantityTable'>
                                                    <Row>
                                                        <Col xl={6} xs={6}>
                                                            <div className='quantitymain'>
                                                                <p>Quantity</p>
                                                            </div>
                                                        </Col>
                                                        <Col xl={6} xs={6}>
                                                            <div className='quantitymain text-end'>
                                                                <p>Price Per Unit</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    {data.map((item, index) => (
                                                        <Row key={index} className='quantitySize'>
                                                            <Col xl={3} lg={3} xs={3}>
                                                                <p>{item.size}</p>
                                                            </Col>
                                                            <Col xl={6} lg={6} xs={6} className='my-auto'>
                                                                <div className='productdashedline'></div>
                                                            </Col>
                                                            <Col xl={3} lg={3} xs={3}>
                                                                <p className={item.quantity === "Get a Quote" ? "getUnderline" : "removeUnderline"}>{item.quantity}</p>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                </div>

                                                <div className='customizebtn'>
                                                    <Link to={"/product-customize"}>
                                                        <Button type="button" className='btn'>Customize</Button>
                                                    </Link>
                                                    <p>*Minimum 24 pcs. needed for customisation</p>
                                                    <Link to={"/mycart"}>
                                                        <Button type="button" className='btn'>Add to Cart</Button>

                                                    </Link>
                                                </div>

                                                <div className='accordionProdInfo'>
                                                    <Accordion>
                                                        <Accordion.Item eventKey="0">
                                                            <Accordion.Header>PRODUCT DESCRIPTION</Accordion.Header>
                                                            <Accordion.Body>
                                                                <div className='accordionContent'>
                                                                    <p>Lorem Ipsum is simply dummy text of the printing & typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="1">
                                                            <Accordion.Header>PRODUCT CARE</Accordion.Header>
                                                            <Accordion.Body>
                                                                <div className='accordionContent'>
                                                                    <p>Lorem Ipsum is simply dummy text of the printing & typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="2">
                                                            <Accordion.Header>DELIVERY & RETURNS</Accordion.Header>
                                                            <Accordion.Body>
                                                                <div className='accordionContent'>
                                                                    <p>Lorem Ipsum is simply dummy text of the printing & typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="3">
                                                            <Accordion.Header>GIFTING</Accordion.Header>
                                                            <Accordion.Body>
                                                                <div className='accordionContent'>
                                                                    <p>Lorem Ipsum is simply dummy text of the printing & typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                </div>

                                                <div className='belowHEading'>
                                                    <p className='mb-1'>Product Reference: H457740HA1OLA  </p>
                                                    <p>Like To Know More? Contact Customer Service</p>
                                                </div>


                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <ReviewVideomodal show={reviewshow} onHide={() => setReviewshow(false)} />
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProductDetails