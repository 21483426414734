import React, { useState } from 'react'
import "./SubmitSuggestion.css";
import Form from 'react-bootstrap/Form';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Button } from 'react-bootstrap';
import Common_Button from '../Common-Component/Common_Button/Common_Button';
const SubmitSuggestion = () => {
    const [value, setValue] = useState();
    return (
        <>
            <section className='SubmitSuggestion'>
                <div className='suggestionForm'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-4 col-md-6'>
                                <div className='formCard'>
                                    <Form>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Control type="email" placeholder="Your Name" />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Control type="email" placeholder="Email" />
                                        </Form.Group>



                                        <PhoneInput
                                            country="in"
                                            value={value}
                                            onChange={setValue}
                                            placeholder='Enter Mobile Number'
                                            className="mb-3"
                                        />


                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Control type="email" placeholder="Enter Mobile Number" />
                                        </Form.Group>

                                        <div className='submitbtnsec'>
                                            <Common_Button type="button" className="btn" text={"Send Message"} />
                                        </div>

                                    </Form>

                                </div>
                            </div>

                            <div className='col-lg-8 col-md-6'>
                                <div className='suggestiontxt'>
                                    <p>Let's make things </p>
                                    <p>better together!!!</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section >
        </>
    )
}

export default SubmitSuggestion