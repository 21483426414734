import React, { useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import "./MultiRecipient.css";
import WeExpress from '../home/WeExpress/WeExpress';
import Customerlove from '../home/Customerlove/Customerlove';
import CountUp from "react-countup";
import Talktous from '../Common-Component/Offcanvas/Talktous/Talktous';
import Common_Button from '../Common-Component/Common_Button/Common_Button';
const MultiRecipient = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const cardsData = [
        {
            key: 1,
            count: 50,
            suffix: " K",
            title: "Happy Customer",
            description: "buy, manage, and distribute HR gifts",
        },
        {
            key: 2,
            count: 10,
            suffix: " M",
            title: "Hours Saved",
            description: "buy, manage, and distribute HR gifts",
        },
        {
            key: 3,
            count: 5,
            suffix: " M",
            title: "Grateful Recipient",
            description: "buy, manage, and distribute HR gifts",
        },
    ];
    return (
        <>
            <section className="AboutUs">
                <div className="AboutusMain">
                    <div className="container">
                        <div className="aboutushead">
                            <h3>Multi-recipient Sending</h3>
                            <p>
                                <span><strong>Ship direct</strong> to as many contacts </span>
                                <span>
                                    as you need, <strong>all at once</strong>.
                                </span>
                            </p>
                        </div>
                        <Row>
                            {cardsData.map((card) => (
                                <Col lg={4} md={6} key={card.key}>
                                    <div className="aboutuscard">
                                        <div className="cardContent text-center">
                                            <h3>
                                                <CountUp
                                                    start={0}
                                                    end={card.count}
                                                    suffix={card.suffix}
                                                    duration={3.5}
                                                />
                                            </h3>
                                            <h4>{card.title}</h4>
                                            <p>{card.description}</p>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>

                        <div className="paraInfo">
                            <p>
                                It took the vision of an innovator & the knowledge of experts to
                                build a virtual market where people could explore, discover & buy
                                various kinds of genuine coins, with guaranteed peace of-mind, in
                                their own luxurious confines.
                            </p>
                        </div>
                    </div>
                </div>

                <div className='automation-works'>
                    <div className='container'>
                        <div className='workHead'>
                            <h3>How <br /> <strong>Multi-Recipient Sending Works</strong></h3>
                        </div>
                        <div className='row PathsectionMain'>
                            <Col xl={8} md={8} className='mx-auto'>
                                <div className='pathImg'>
                                    <img src={process.env.PUBLIC_URL + "/assest/image/Gift_automation/pathImg1.png"} />
                                    <Row>
                                        <Col lg={4} md={4} xs={4}>
                                            <div className='automationtxt'>
                                                <h3>Select A Preset Campaign </h3>
                                                <p>Choose The Way You Want To Send It.</p>
                                            </div>
                                        </Col>
                                        <Col lg={4} md={4} xs={4}>
                                            <div className='automationtxt'>
                                                <h3>Enter Your Contacts & The Date</h3>
                                                <p>Enter your contacts and the date you want the gift to go out.</p>
                                            </div>
                                        </Col>
                                        <Col lg={4} md={4} xs={4}>
                                            <div className='automationtxt'>
                                                <h3>Add A Greeting And Send</h3>
                                                <p>Your brand logo will automatically populate the greeting and sender's name.</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                            </Col>
                        </div>
                    </div>
                </div>

                <div className='NeedHelp'>
                    <div className='container'>
                        <div className='Needhelpheading'>
                            <h3><strong>Need Help</strong> With Gifting Programs?</h3>
                            <div className='Talktousbtn'>
                                <Common_Button className="btn" onClick={handleShow} text={"TALK TO US "} />
                            </div>
                        </div>
                    </div>
                </div>

                <WeExpress />
                <Customerlove />
                <Talktous show={show} handleClose={handleClose} placement="end" name="end" />
            </section>
        </>
    )
}

export default MultiRecipient