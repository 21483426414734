import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'

import "./Heart_Component.css"
import { Button, Card } from "react-bootstrap";

function Heart_Component() {
  // const [likedProducts, setLikedProducts] = useState(false); // State to track liked products

  // const handleLike = () => {
  //   setLikedProducts(!likedProducts);
  // };

  const [heartactive, setheartActive] = useState(false);
  const handleClickes = () => {
    setheartActive(!heartactive);
  };
  return (
   <>
     {/* <div className="background like-heart">
          <Button
            variant="light"
            className="button-holder"
            type="button"
            onClick={handleLike}
          >
            <FontAwesomeIcon
              icon={faHeart}
              className={likedProducts ? "heart-icon active" : "heart-icon"}
            />
          </Button>
        </div> */}

        <div className="hearticn">
                  <FontAwesomeIcon
                    icon={faHeart}
                    onClick={handleClickes}
                    className={heartactive ? "gobiheartss" : "gobiheart"}
                  />
                </div>
   </>
  )
}

export default Heart_Component