import React, { useState } from 'react'
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { Row } from "react-bootstrap";
import ProductCard from '../Common-Component/ProductCard/ProductCard';
import "../BestMatched/BestMatched.css"
const BestSellers = () => {
    const [swiperInstance, setSwiperInstance] = useState(null);
    const [swiperInstancenew, setSwiperInstancenew] = useState(null);
    const orders = [
        {
            id: 1,
            image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
            name: 'Nike Dri-FIT Legend Tee - Men\'s',
            date: '3.JUN.24',
            price: '0000'
        },
        {
            id: 2,
            image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
            name: 'White Coffee Mug',
            date: '2.JUN.24',
            price: '0000'
        },
        {
            id: 3,
            image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
            name: 'Lather Cover Executive Diary and 2 more items',
            date: '1.JUN.24',
            price: '0000'
        },
        {
            id: 4,
            image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
            name: 'Lather Cover Executive Diary and 2 more items',
            date: '1.JUN.24',
            price: '0000'
        }

    ];
    return (
        <>
            <section className='BestMatchedMain'>
                <div className='BestMatched'>
                    <div className='bestMatchedhead'>
                        <h3>Best Sellers</h3>
                    </div>
                    <Row>
                        <Swiper
                            slidesPerView={"auto"}
                            modules={[Autoplay, Pagination]}
                            onSwiper={(swiper) => setSwiperInstance(swiper)}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                370: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                485: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                575: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                },

                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                992: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                                1024: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                },
                                1200: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                                1440: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                                2000: {
                                    slidesPerView: 3,
                                    spaceBetween: 20,
                                },
                            }}
                            navigation
                            pagination={{ clickable: true }}
                            className="mySwiper"
                        >
                            {orders.map(order => (
                                <SwiperSlide>
                                    <ProductCard order={order} />


                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Row>
                </div>

            </section>
        </>
    )
}

export default BestSellers