import React from 'react';
import "./ValueSection.css";
const values = [
    {
        title: "Be Grateful",
        description: "We deeply appreciate our customers and focus on fostering trust and dependability.",
        image: "/assest/image/Aboutus/valueIcon1.png"
    },
    {
        title: "Be Innovative",
        description: "We constantly seek new and creative solutions to meet our customers' needs.",
        image: "/assest/image/Aboutus/valueIcon2.png"
    },
    {
        title: "Be Collaborative",
        description: "We believe in the power of teamwork and strive to build strong partnerships.",
        image: "/assest/image/Aboutus/valueIcon3.png"
    },
    {
        title: "Be Passionate",
        description: "We are passionate about our work and committed to excellence.",
        image: "/assest/image/Aboutus/valueIcon4.png"
    }
];

const ValuesSection = () => {
    return (
        <div className="container">
            <div className="valueStandby">
                <div className="valueStandbyHead">
                    <h3>The<b className='valuetext'> values</b> we stand by</h3>
                </div>
                <div className="row">
                    {values.map((value, index) => (
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mx-auto" key={index}>
                            <div className="card valuestandCard">
                                <div className="cardImgsec">
                                    <img src={process.env.PUBLIC_URL + value.image} alt={value.title} />
                                </div>
                                <div className="cardHead">
                                    <h3>{value.title}</h3>
                                    <p>{value.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ValuesSection;
