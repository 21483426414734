import React from 'react'
import "./LogoutModal.css";
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
const LogoutModal = (props) => {
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="logOutmodal"
            >

                <Modal.Body>
                    <section className='LogoutMain'>
                        <div className='logoutinfo text-center'>
                            <p>{props.text}</p>
                        </div>

                        <div className='yesNobtnMain'>
                            <Link to={"/login"}>
                                <Button className='btn yesNobtn' >Yes </Button>
                            </Link>
                            <Button className='btn yesNobtn'>No</Button>
                        </div>
                    </section>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default LogoutModal