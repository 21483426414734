
import {
    getData,
    postData,
    putData,
    editStatusData,
    deleteData,
    getDownloadDataExcel,
} from "../../api";

export const occasion = async (id) => {
    try {
        return await getData(`/home/occasion`);
    } catch (error) {
        console.log(error);
    }
};

export const gratitude = async (id) => {
    try {
        return await getData(`/home/gratitude`);
    } catch (error) {
        console.log(error);
    }
};

export const adBanner = async (id) => {
    try {
        return await getData(`/home/ad-banner`);
    } catch (error) {
        console.log(error);
    }
};

export const banner = async (id) => {
    try {
        return await getData(`/home/banner`);
    } catch (error) {
        console.log(error);
    }
};

export const sustainable_products = async (id) => {
    try {
        return await getData(`/home/sustainable-products`);
    } catch (error) {
        console.log(error);
    }
};

export const promotional_products = async (id) => {
    try {
        return await getData(`/home/promotional-products`);
    } catch (error) {
        console.log(error);
    }
};

export const blog = async (id) => {
    try {
        return await getData(`/home/promotional-products`);
    } catch (error) {
        console.log(error);
    }
};