import React, { useEffect, useState } from 'react'
import './PromotionalProduct.css'
import { Col, Container, Row } from 'react-bootstrap'
import Popular_Product_Card from '../../Common-Component/Popular_Product_Card/Popular_Product_Card'
import { Link } from 'react-router-dom'
import { promotional_products } from '../../../utils/apis/Home/Homeapi'
const PromotionalProduct = () => {
    const [promoProduct, setPromoproduct] = useState([]);
    const getPromotional = async () => {
        const res = await promotional_products();
        if (res?.success) {
            setPromoproduct(res?.data)
        }
    }

    useEffect(() => {
        getPromotional();
    }, [])
    return (
        <>
            <section className='promotional-product'>
                <Container>
                    <Row>
                        <div className='heading-holder mb-5'>
                            <div className='d-flex'>
                                <h2 className='first-heading'>Shop</h2>
                                <h3 className='second-heading'>Promotional Products</h3>
                            </div>
                            <span className='text-para'>Unleash Your Brand's Potential With Our Irresistible Promotional Products!</span>
                        </div>
                        <Col lg={6}>
                            <Row>
                                <Col lg={6} md={6} sm={6} xs={6}>
                                    <Popular_Product_Card
                                        className="main-section "
                                        image={process.env.PUBLIC_URL + "/assest/image/home/image-jacket.png"}
                                        imgClassName="seller-img"
                                        overlaycolor="bg-overlay-one"
                                        mainimagesec="image-box"
                                        icon="fa-solid fa-arrow-right"
                                    />
                                    <Popular_Product_Card
                                        className="main-section "
                                        image={process.env.PUBLIC_URL + "/assest/image/home/image-mug.png"}
                                        imgClassName="seller-img"
                                        overlaycolor="bg-overlay-one"
                                        mainimagesec="image-box"
                                        icon="fa-solid fa-arrow-right"
                                    />

                                </Col>
                                <Col lg={6} md={6} sm={6} xs={6}>
                                    <Popular_Product_Card
                                        className="main-section "
                                        image={process.env.PUBLIC_URL + "/assest/image/home/iamge-lap.png"}
                                        imgClassName="seller-img"
                                        overlaycolor="bg-overlay-one"
                                        mainimagesec="image-box"
                                        icon="fa-solid fa-arrow-right"
                                    />
                                    <Popular_Product_Card
                                        className="main-section "
                                        image={process.env.PUBLIC_URL + "/assest/image/home/image-jacket.png"}
                                        imgClassName="seller-img"
                                        overlaycolor="bg-overlay-one"
                                        mainimagesec="image-box"
                                        icon="fa-solid fa-arrow-right"
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6}>
                            <Popular_Product_Card
                                className="main-section "
                                image={process.env.PUBLIC_URL + "/assest/image/home/image-asse.png"}
                                imgClassName="seller-img"
                                content="VIEW ALL"
                                textClassName="local-text"
                                overlaycolor="bg-overlay"
                                link="/promotional-product"
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default PromotionalProduct
