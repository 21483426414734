import React from 'react'
import "./ReviewVideomodal.css"
import Modal from 'react-bootstrap/Modal';
const ReviewVideomodal = (props) => {
    return (
        <>
            <section>
                <Modal
                    {...props}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className='ReviewModalvideo'
                >

                    <Modal.Body>
                        <video src={process.env.PUBLIC_URL + "/assest/video/revieVideo.mp4"} muted controls loop autoPlay className='videoModal'></video>
                    </Modal.Body>

                </Modal>
            </section>
        </>
    )
}

export default ReviewVideomodal