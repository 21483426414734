import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';
import "./AddNewaddress.css";

const AddNewaddress = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSave = () => {
        const newAddressData = {
            hometype: "Home",
            image: process.env.PUBLIC_URL + "/assest/image/Profile/map.png",
            address: "E/92 Apmc Market Yard, Turbhe, Vashi, Mumbai, Maharashtra - 400703",
        };
        props.onSave(newAddressData);
    };

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
    ];

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='Addnewaddress'
            >
                <Modal.Body>
                    <div className='formSection'>
                        <Form>
                            <Form.Group controlId="formFirstName">
                                <div className="formflex">
                                    <div className="nameLabel mb-3">
                                        <Form.Label className="emailtxt">
                                            Prefix*
                                        </Form.Label>
                                        <Form.Select
                                            className="form-control"
                                            aria-label="Default select example"
                                            placeholder="Select Prefix"
                                        >
                                            <option>Select Prefix</option>
                                            <option value="1">Ms</option>
                                            <option value="2">Mrs</option>
                                            <option value="3">Mr</option>
                                        </Form.Select>
                                    </div>
                                    <div className="nameLabel">
                                        <Form.Label className="emailtxt">
                                            First Name*
                                        </Form.Label>
                                        <Form.Control
                                            className="mb-3"
                                            type="text"
                                            placeholder="Enter First Name"
                                        />
                                    </div>
                                    <div className="nameLabel">
                                        <Form.Label className="emailtxt">
                                            Middle Name*
                                        </Form.Label>
                                        <Form.Control
                                            className="mb-3"
                                            type="text"
                                            placeholder="Enter Middle Name"
                                        />
                                    </div>
                                    <div className="nameLabel">
                                        <Form.Label className="emailtxt">
                                            Last Name*
                                        </Form.Label>
                                        <Form.Control
                                            className="mb-3"
                                            type="text"
                                            placeholder="Enter Last Name"
                                        />
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group controlId="formMiddleName">
                                <Form.Label className="emailtxt">Company</Form.Label>
                                <div className="formfieldregister">
                                    <Form.Control
                                        className="mb-3"
                                        type="text"
                                        placeholder="Enter Company Name"
                                    />

                                </div>
                            </Form.Group>

                            <Form.Group controlId="formFirstName">
                                <Form.Label className="emailtxt">Address*</Form.Label>
                                <Form.Control
                                    className="mb-3"
                                    type="text"
                                    placeholder="Enter Address"
                                />
                            </Form.Group>

                            <Row>
                                <Col lg={4}>
                                    <Form.Group controlId="formFirstName">
                                        <Form.Label className="emailtxt">Country*</Form.Label>
                                        <Select
                                            defaultValue={selectedOption}
                                            onChange={setSelectedOption}
                                            options={options}
                                            className='mb-3'
                                            classNamePrefix="manualSelect"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group controlId="formFirstName">
                                        <Form.Label className="emailtxt">State/Province*</Form.Label>
                                        <Select
                                            defaultValue={selectedOption}
                                            onChange={setSelectedOption}
                                            options={options}
                                            className='mb-3'
                                            classNamePrefix="manualSelect"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group controlId="formFirstName">
                                        <Form.Label className="emailtxt">City*</Form.Label>
                                        <Form.Control
                                            className="mb-3"
                                            type="text"
                                            placeholder="Enter City"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group controlId="formFirstName">
                                        <Form.Label className="emailtxt">Telephone*</Form.Label>
                                        <Form.Control
                                            className="mb-3"
                                            type="text"
                                            placeholder="Enter Telephone"
                                        />
                                    </Form.Group>
                                </Col>
                                <div className='checksRadios'>
                                    <Form.Check type="radio" label="Home" className='stepOnecheckbox mb-2' name="Home" />
                                    <Form.Check type="radio" label="Work" className='stepOnecheckbox mb-2' name="Home" />

                                </div>
                                <Col lg={12} className="text-end mt-3">
                                    <div className='btnsec'>
                                        <Button className='btn me-4' type="button">Save</Button>
                                        <Button className='btn' type="button" onClick={handleSave}>Continue with this address</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AddNewaddress;
