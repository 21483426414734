import React, { useEffect, useRef, useState } from "react";
import "./Header.css";
import { Container, InputGroup, Nav, NavDropdown, Navbar, Row, Form, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faHeart, fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'react-bootstrap/Dropdown';
import Returnmodal from "../Common-Component/Commonmodal/Returnmodal/Returnmodal";
import HeaderOffcanvas from "../Common-Component/Offcanvas/HeaderOffcanvas/HeaderOffcanvas";
import LogoutModal from "../Common-Component/Commonmodal/LogoutModal/LogoutModal";
library.add(fas)
function Header() {

  const location = useLocation();
  const [activeItem, setActiveItem] = useState(() => {
    return localStorage.getItem("activeItem") || "home";
  });

  const [show, setShow] = useState(false);
  const [logoutshow, setLogoutshow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const path = location.pathname.substr(1);
    updateActiveItem(path || "home");
  }, [location]);

  const updateActiveItem = (newActiveItem) => {
    localStorage.setItem("activeItem", newActiveItem);
    setActiveItem(newActiveItem);
  };
  const [activeLink, setActiveLink] = useState("home");
  useEffect(() => {
    const path = location.pathname.substring(1);
    setActiveLink(path || "home");
  }, [location.pathname]);
  const handleNavItemClick = (selectedLink) => {
    setActiveLink(selectedLink);
    const navbarToggle = document.querySelector(".navbar-toggler");
    if (navbarToggle && window.innerWidth <= 992) {
      navbarToggle.click();
    }
  };

  const [searchToggled, setIsToggled] = useState(false);
  const [showReturn, setShowreturn] = useState(false);
  const searchRef = useRef(null);
  const toggleClick = () => {
    setIsToggled(!searchToggled);
  };
  return (
    <>
      <section className='Main-header'>
        <div className="container-fluid p-0">
          {/* <!-- start header all section  --> */}
          <div className="header-all-section">
            {/* <!--top-header section start--> */}
            <section className="top-header ">
              <div className="container-fluid">
                <div className="row">
                  <marquee direction="left">
                    <span className="marquee-content">
                      "Discover The Perfect Gifts For Every Occasion With Our New And Exciting Product Lineup!"
                    </span>
                    <span className="marquee-content">
                      "Discover The Perfect Gifts For Every Occasion With Our New And Exciting Product Lineup!"
                    </span>
                    <span className="marquee-content">
                      "Discover The Perfect Gifts For Every Occasion With Our New And Exciting Product Lineup!"
                    </span>
                    <span className="marquee-content">
                      "Discover The Perfect Gifts For Every Occasion With Our New And Exciting Product Lineup!"
                    </span>
                    <span className="marquee-content">
                      "Discover The Perfect Gifts For Every Occasion With Our New And Exciting Product Lineup!"
                    </span>
                    <span className="marquee-content">
                      "Discover The Perfect Gifts For Every Occasion With Our New And Exciting Product Lineup!"
                    </span>
                  </marquee>
                </div>
              </div>
            </section>
            {/* start-second-header */}

            <section className="second-header">
              <Container className='' >
                <div className="top-second-header">
                  <Row className="">

                    <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-5 col-5">
                      <div className="logo">
                        <Link to="/home">
                          <img className='headlogo' src={process.env.PUBLIC_URL + '/assest/image/logo/header-logo.png'} alt="Logo" />
                        </Link>
                      </div>
                    </div>
                    <div className="col-xxl-7 col-xl-7 col-lg-6 col-md-6 col-sm-2 col-1">
                      <div className="desktop-view-search">
                        <InputGroup className="">
                          <Form.Control
                            placeholder="what are you looking for?"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          />
                          <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon="fa-solid fa-magnifying-glass" /></InputGroup.Text>
                        </InputGroup>
                      </div>
                    </div>

                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-5 col-6">
                      <div className="cart-logo d-flex ">

                        <div className="mobile-view-search text-center">
                          <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" onClick={toggleClick} />

                        </div>
                        <div className="icon-cart">
                          <Link to={"/mycart"}>
                            <FontAwesomeIcon icon="fa-solid fa-cart-shopping" /><span className="ms-lg-2 ms-md-1 ms-sm-1 ms-2">(0)</span>

                          </Link>
                        </div>

                        <div className="icon-cart ">
                          <Link to={"/wishlist"}>
                            <FontAwesomeIcon icon="fa-solid fa-heart" /><span className="ms-lg-2 ms-md-1 ms-sm-1 ms-2">(0)</span>

                          </Link>
                        </div>

                        <div className="icon-cart ms-lg-2 ms-md-2 ms-sm-1 ms-1">
                          <Dropdown>
                            <Dropdown.Toggle className="usertogggle" id="dropdown-basic">
                              <FontAwesomeIcon icon="fa-solid fa-user" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="profile-logout-drop">

                              <Dropdown.Item as={Link} to="/profile">Profile</Dropdown.Item>
                              <Dropdown.Item href="#/action-2" onClick={() => setLogoutshow(true)}>Log-out</Dropdown.Item>

                            </Dropdown.Menu>
                          </Dropdown>

                        </div>
                      </div>
                    </div>

                    {searchToggled && (
                      <div ref={searchRef} className="input-group-box mb-3">
                        <Form.Control type="text" placeholder="Search here" />
                      </div>
                    )}

                  </Row>
                </div>
                <Navbar expand="lg" className="">
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />

                  <Navbar.Collapse id="basic-navbar-nav" className='menus-main'>
                    <Nav className="">
                      <Nav.Link as={Link} to="/home" active={activeItem === "home"} onClick={handleNavItemClick} className="commonLink" >
                        Home
                      </Nav.Link>

                      <NavDropdown title="Medals" id="basic-nav-dropdown" className="me-xxl-3 me-xl-3 me-lg-1">
                        <NavDropdown.Item as={Link} to="/shop-promotional" onClick={handleNavItemClick}>Silver</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/shop-promotional" onClick={handleNavItemClick}>Bronze</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/shop-promotional" onClick={handleNavItemClick}>
                          Copper
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/product-customize" onClick={handleNavItemClick}>Customize</NavDropdown.Item>
                      </NavDropdown>

                      <NavDropdown title="Trophies" id="basic-nav-dropdown" className="me-xxl-3 me-xl-3 me-lg-1">
                        <NavDropdown.Item as={Link} to="/product-customize" onClick={handleNavItemClick}>Customize</NavDropdown.Item>
                      </NavDropdown>

                      <NavDropdown title="Drinkware" id="basic-nav-dropdown" className="me-xxl-3 me-xl-3 me-lg-1">
                        <NavDropdown.Item as={Link} to="/product-customize" onClick={handleNavItemClick}>Customize</NavDropdown.Item>
                      </NavDropdown>

                      <NavDropdown title="Photoframes" id="basic-nav-dropdown" className="me-xxl-3 me-xl-3 me-lg-1">
                        <NavDropdown.Item as={Link} to="#" onClick={handleNavItemClick}>Customize</NavDropdown.Item>
                      </NavDropdown>

                      <Nav.Link as={Link} to="#" active={activeItem === ""} onClick={handleNavItemClick} className="commonLink">
                        Silver Plated Artefacts
                      </Nav.Link>

                      <NavDropdown title="Shop Promotional Products" id="basic-nav-dropdown" className="me-xxl-3 me-xl-3 me-lg-1">
                        <NavDropdown.Item as={Link} to={'/shop-promotional'} onClick={handleNavItemClick}>Coins</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={'/shop-promotional'} onClick={handleNavItemClick}>Medals</NavDropdown.Item>
                        <NavDropdown.Item onClick={handleNavItemClick}>
                          Plaques
                        </NavDropdown.Item>
                        <NavDropdown.Item onClick={handleNavItemClick}> Gadgets</NavDropdown.Item>
                        <NavDropdown.Item onClick={handleNavItemClick}>Trophies</NavDropdown.Item>
                        <NavDropdown.Item onClick={handleNavItemClick}> Drinkware</NavDropdown.Item>
                        <NavDropdown.Item onClick={handleNavItemClick}> Wardrobe</NavDropdown.Item>
                        <NavDropdown.Item onClick={handleNavItemClick}>Home Decor</NavDropdown.Item>
                        <NavDropdown.Item onClick={handleNavItemClick}>Gourmet Store</NavDropdown.Item>
                        <NavDropdown.Item onClick={handleNavItemClick}>Bags & Luggage</NavDropdown.Item>
                        <NavDropdown.Item onClick={handleNavItemClick}> Office Essentials</NavDropdown.Item>
                        <NavDropdown.Item onClick={handleNavItemClick}> Health and Wellness</NavDropdown.Item>
                        <NavDropdown.Item onClick={handleNavItemClick}>Sustainable Products</NavDropdown.Item>
                        <NavDropdown.Item onClick={handleNavItemClick}>Gift Hampers and Sets</NavDropdown.Item>
                      </NavDropdown>

                      <NavDropdown title="Shop by" id="basic-nav-dropdown" className="me-xxl-3 me-xl-3 me-lg-1">
                        <NavDropdown.Item as={Link} to="#" onClick={handleNavItemClick}>Price</NavDropdown.Item>
                        <NavDropdown.Item onClick={handleNavItemClick}>Interest</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={"/all-category"} onClick={handleNavItemClick}>  Categories </NavDropdown.Item>
                        <NavDropdown.Item onClick={handleNavItemClick}> Best Sellers</NavDropdown.Item>
                        <NavDropdown.Item onClick={handleNavItemClick}>Occasions / Events</NavDropdown.Item>
                      </NavDropdown>
                      <Nav.Link as={Link} to="/customizable-product" active={activeItem === "customise"} onClick={handleNavItemClick} className="commonLink">
                        Customise
                      </Nav.Link>
                      <Nav.Link as={Link} to="/offers" active={activeItem === " offers"} onClick={handleNavItemClick} className="commonLink">
                        Offers
                      </Nav.Link>
                    </Nav>


                  </Navbar.Collapse>

                </Navbar>

              </Container>
            </section>

            {/* Mobile Header start */}

            <section className="mobileSecond-header">
              <div className="secondHeaderMain">
                <Container className='' >
                  <div className="top-second-header">

                    <div className="logo">
                      <Link to="/home">
                        <img className='headlogo' src={process.env.PUBLIC_URL + '/assest/image/logo/header-logo.png'} alt="Logo" />
                      </Link>
                    </div>


                    <Row className="pb-1">
                      <Col xs={4}>
                        <FontAwesomeIcon icon="fa-solid fa-bars" onClick={handleShow} className="barsIcon" />
                      </Col>
                      <Col xs={8}>
                        <div className=" cart-logo d-flex ">
                          <div className="icon-cart mobile-view-search text-center">
                            <Link to={"/search-products"}>
                              <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" onClick={toggleClick} className="commonIcon" />
                            </Link>
                          </div>
                          <div className="icon-cart">
                            <Link to={"/mycart"}>
                              <div className="bageicn_mian">
                                <FontAwesomeIcon icon="fa-solid fa-cart-shopping" className="commonIcon" />
                                <div className="bageicn">1</div>
                              </div>
                            </Link>
                          </div>

                          <div className="icon-cart ">
                            <Link to={"/wishlist"}>
                              <div className="bageicn_mian">
                                <FontAwesomeIcon icon={faHeart} className="icon commonIcon" />
                                <div className="bageicn">1</div>
                              </div>
                            </Link>
                          </div>

                          <div className="icon-cart ms-lg-3 ms-md-2 ms-sm-1 ms-1">
                            <Dropdown>
                              <Dropdown.Toggle className="icon-cart usertogggle" id="dropdown-basic">
                                <FontAwesomeIcon icon="fa-solid fa-user" className="commonIcon" />
                              </Dropdown.Toggle>

                              <Dropdown.Menu className="profile-logout-drop">

                                <Dropdown.Item as={Link} to="/profile">Profile</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Log-out</Dropdown.Item>

                              </Dropdown.Menu>
                            </Dropdown>

                          </div>
                        </div>

                        {/* {searchToggled && (
                          <div ref={searchRef} className="input-group-box mb-3">
                            <Form.Control type="text" placeholder="Search here" />
                          </div>
                        )} */}

                      </Col>
                    </Row>



                  </div>


                </Container>

              </div>
            </section>

            <HeaderOffcanvas placement="start" name={"start"} show={show} handleClose={handleClose} />
            <LogoutModal show={logoutshow} onHide={() => setLogoutshow(false)} text={'Are You Sure You want to Log Out'} />
            {/* Mobile Header start */}
            {/* end-second-header */}
            {/* <!-- end header all section  --> */}
          </div>
        </div>
      </section>



    </>
  );
}

export default Header;
