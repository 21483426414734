import React, { useState } from "react";
import './Payment_checkout.css'
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../Common-Component/Breadcrumbs/Breadcrumbs";
import Billing_information from "./step1/Billing_information";
import Shipping_information from "./step2/Shipping_information";
import Shipping_method from "./step3/Shipping_method";
import Payment_info from "./step4/Payment_info";
import Order_review from "./step5/Order_review";
import RewardDetails from "../RewardDetails/RewardDetails";


const Payment_checkout = () => {
    const [current, setCurrent] = useState(1);
    const [addressSelect, setAddressSelect] = useState("");
    const [checkout, setCheckout] = useState(true);
    const [paymentMethod, setPaymentMethod] = useState("online");
    const stepLabels = [
        { id: 1, label: "Billing Information" },
        { id: 2, label: "Shipping Information" },
        { id: 3, label: "Shipping Method" },
        { id: 4, label: "Payment Information" },
        { id: 5, label: "Order Review" },
    ];

    const handleNext = () => {
        if (current < 5) {
            setCurrent(current + 1);
        }
    };

    const handlePrevious = () => {
        if (current > 1) {
            setCurrent(current - 1);
        }
    };

    const isStepCompleted = (step) => {
        return step < current;
    };

    const breadcrumbItems = [
        { text: "HOME", link: "/", className: "active" },
        { text: "MY CART", link: "/mycart", className: "active" },
        { text: "CHECKOUT", link: "/product-cart-inner", className: "active" },
    ];

    return (
        <>
            <section className='Payment_checkout-section'>
                <div className="container">
                    {checkout ? (
                        <>
                            <Breadcrumbs items={breadcrumbItems} />

                            <div className="">
                                <section className="Payment_stepform_sec">
                                    <Form>
                                        <div className="signup-form ">
                                            <div className="progress-bar-container">
                                                <div className="step-row">
                                                    {stepLabels.map((item, index) => (
                                                        <div key={index + 1} className={`step-container ${index + 1 === current ? "active" : ""}`}>
                                                            <div className="main-flex-div">
                                                                <div className="circle-center">
                                                                    <div
                                                                        className={`line ${isStepCompleted(index + 1)
                                                                            ? "completed"
                                                                            : ""
                                                                            } ${index + 1 === current ? "active" : ""}`}
                                                                    >
                                                                        <FontAwesomeIcon icon={faCheck} className="checkIcon" />
                                                                    </div>
                                                                </div>
                                                                <div className="centerDivcircle">
                                                                    <span className={`step-text ${isStepCompleted(index + 1) ? "completed" : ""} ${index + 1 === current ? "active" : ""}`}>{item.label}</span>
                                                                </div>
                                                            </div>
                                                            <div className='circle-center'>
                                                                <div className={`circle ${isStepCompleted(index + 1) ? "completed" : ""}`}></div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wizard-form mt-3">
                                            {current === 1 && (
                                                <Billing_information handleNext={handleNext} addressSelect={addressSelect} setAddressSelect={setAddressSelect} />
                                            )}
                                            {current === 2 && (
                                                <Shipping_information
                                                    handleNext={handleNext}
                                                    handlePrevious={handlePrevious}
                                                    addressSelect={addressSelect}
                                                    paymentMethod={paymentMethod}
                                                    setPaymentMethod={setPaymentMethod}
                                                />
                                            )}
                                            {current === 3 && (
                                                <Shipping_method
                                                    handleNext={handleNext}
                                                    handlePrevious={handlePrevious}
                                                    addressSelect={addressSelect}
                                                    paymentMethod={paymentMethod}
                                                />
                                            )}
                                            {current === 4 && (
                                                <Payment_info
                                                    handleNext={handleNext}
                                                    handlePrevious={handlePrevious}
                                                    addressSelect={addressSelect}
                                                    paymentMethod={paymentMethod}
                                                    setCheckout={setCheckout}
                                                    checkout={checkout}
                                                />
                                            )}
                                            {current === 5 && (
                                                <Order_review
                                                    handleNext={handleNext}
                                                    handlePrevious={handlePrevious}
                                                    addressSelect={addressSelect}
                                                    paymentMethod={paymentMethod}
                                                />
                                            )}
                                        </div>
                                    </Form>
                                </section>
                            </div>
                        </>
                    ) : (
                        <RewardDetails setCheckout={setCheckout} />
                    )}
                </div>
            </section>
        </>
    );
}

export default Payment_checkout;
