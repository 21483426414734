import React, { useState } from "react";
import "./Register.css";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { Form, Button, InputGroup, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import SellerStepform from "./SellerStepform/SellerStepform";
import { userRegister } from "../../utils/apis/login/Login";
import Cookies from "js-cookie";
import { useForm } from "react-hook-form";

const Register = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [value, setValue] = useState(false);
    const [showEmail, setShowEmail] = useState(true);
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");
    const [phoneValue, setPhoneValue] = useState("");
    const navigate = useNavigate();
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
        reset,
        trigger,
    } = useForm();

    const onSubmit = async (data) => {
        if (data.password === data.re_password) {
            // data.seller_id = sellerId;
            data.contact_no = phoneValue;
            const res = await userRegister(data);
            if (res?.success) {
                await Cookies.set("ecommerce2_security", res?.data?.token);
                // setSignin(true);
                reset();
                navigate("/");
            } else {
                if (res?.message?.both) {
                    setError("email", { message: res?.message?.both });
                    setError("contact_no", { message: "" });
                }
                if (res?.message?.email) {
                    setError("email", { message: res?.message?.email });
                }
                if (res?.message?.contact_no) {
                    setError("contact_no", { message: res?.message?.contact_no });
                }
            }
        } else {
            setError("password", { message: "Password does not match" });
        }
    };
    return (
        <div className="login-container">
            <div className="register-box">
                <div className="logo-register">
                    <img
                        src={process.env.PUBLIC_URL + "/assest/image/logo/header-logo.png"}
                        className="logo"
                    />
                    <Link to={"/"}>
                        <p className="backtohome">Back to Home</p>

                    </Link>
                </div>
                <Row className="justify-content-center">
                    <Col xxl={6} xl={6} md={8}>
                        <div className="login-header">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Nav variant="pills">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first">
                                                    Create User Account
                                                </Nav.Link>
                                            </Nav.Item>
                                        </div>
                                        <div className="col-lg-6">
                                            <Nav.Item>
                                                <Nav.Link eventKey="second">
                                                    Create Seller Account
                                                </Nav.Link>
                                            </Nav.Item>
                                        </div>
                                    </div>
                                </Nav>

                                <Tab.Content >
                                    <Tab.Pane eventKey="first" className="tablogin">
                                        <Form>
                                            <Form.Group controlId="formFirstName">
                                                <div className="d-flex">
                                                    <div>
                                                        <Form.Label className="emailtxt">
                                                            Prefix*
                                                        </Form.Label>
                                                        <Form.Select
                                                            className="form-control"
                                                            aria-label="Default select example"
                                                            placeholder="Select Prefix"
                                                        >
                                                            <option>Select Prefix</option>
                                                            <option value="1">Ms</option>
                                                            <option value="2">Mrs</option>
                                                            <option value="3">Mr</option>
                                                        </Form.Select>
                                                    </div>
                                                    <div className="ms-4 nameLabel">
                                                        <Form.Label className="emailtxt">
                                                            First Name*
                                                        </Form.Label>
                                                        <Form.Control
                                                            className="mb-3"
                                                            type="text"
                                                            placeholder="Enter Email or Mobile Number"
                                                        />
                                                    </div>
                                                </div>
                                            </Form.Group>

                                            <Form.Group controlId="formMiddleName">
                                                <Form.Label className="emailtxt">
                                                    Middle Name / Initial
                                                </Form.Label>
                                                <div className="formfieldregister">
                                                    <Form.Control
                                                        className="mb-3"
                                                        type={showPassword ? "text" : "password"}
                                                        placeholder="Enter Password"
                                                    />

                                                    <div
                                                        className="eyeIcon"
                                                        onClick={togglePasswordVisibility}
                                                    >
                                                        {showPassword ? "🙈" : "👁️"}
                                                    </div>
                                                </div>
                                            </Form.Group>

                                            <Form.Group controlId="formFirstName">
                                                <Form.Label className="emailtxt">Last Name*</Form.Label>
                                                <Form.Control
                                                    className="mb-3"
                                                    type="text"
                                                    placeholder="Enter Last Name"
                                                />
                                            </Form.Group>

                                            <Form.Group controlId="formFirstName">
                                                <Form.Label className="emailtxt">Email*</Form.Label>
                                                <Form.Control
                                                    className="mb-2"
                                                    type="text"
                                                    placeholder="Enter Email"
                                                />
                                                <div className="checkMain">
                                                    <Form.Check
                                                        type="checkbox"
                                                        label="Sign Up for Newsletter"
                                                        className="checkNews"

                                                    />

                                                </div>
                                            </Form.Group>

                                            <Form.Group controlId="formFirstName">
                                                <Form.Label className="emailtxt">Password*</Form.Label>
                                                <div className="formfieldregister">
                                                    <Form.Control
                                                        className="mb-3"
                                                        type={showPassword ? "text" : "password"}
                                                        placeholder="Enter Password"
                                                    />

                                                    <div
                                                        className="eyeIcon"
                                                        onClick={togglePasswordVisibility}
                                                    >
                                                        {showPassword ? "🙈" : "👁️"}
                                                    </div>
                                                </div>
                                            </Form.Group>

                                            <Form.Group controlId="formFirstName">
                                                <Form.Label className="emailtxt">
                                                    Confirm Password*
                                                </Form.Label>
                                                <div className="formfieldregister">
                                                    <Form.Control
                                                        className="mb-3"
                                                        type={showPassword ? "text" : "password"}
                                                        placeholder="Enter Confirm Password"
                                                    />

                                                    <div
                                                        className="eyeIcon"
                                                        onClick={togglePasswordVisibility}
                                                    >
                                                        {showPassword ? "🙈" : "👁️"}
                                                    </div>
                                                </div>
                                            </Form.Group>

                                            <Form.Label className="emailtxt">Contact*</Form.Label>
                                            <PhoneInput
                                                country="in"
                                                value={value}
                                                onChange={setValue}
                                            />

                                            <Form.Group className="forgot-password">
                                                <Link href="#">Forgot Password?</Link>
                                            </Form.Group>
                                            <div className="text-center">
                                                <Button className="createaccountbtn" type="submit">
                                                    Create Account
                                                </Button>
                                            </div>
                                        </Form>
                                        <div className="signup-link">
                                            <p>
                                                By signing up, I agree to the{" "}
                                                <Link to={"/terms-condition"}>
                                                    <span style={{ color: "#234C9E" }}>Terms and Conditions</span>
                                                </Link>
                                            </p>
                                            <p>
                                                Already have an account?{" "}
                                                <Link to={"/login"}><span style={{ color: "#234C9E", fontWeight: "bold" }}>Login</span></Link>
                                            </p>
                                        </div>
                                        <div className="social-login">
                                            <p>You can also login by using your</p>
                                            <div className="social-icons">
                                                <img
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        "/assest/image/login/facebook.png"
                                                    }
                                                    alt="Google"
                                                />
                                                <img
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        "/assest/image/login/google.png"
                                                    }
                                                    alt="Google"
                                                />
                                                <img
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        "/assest/image/login/twitter.png"
                                                    }
                                                    alt="Google"
                                                />
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <SellerStepform />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </Col>
                </Row>

            </div>
        </div>
    );
};

export default Register;
