import React, { useEffect, useState } from 'react'
import "./Blogs.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../Common-Component/Breadcrumbs/Breadcrumbs';

const articlesData = [
    {
        id: 1,
        imgSrc: process.env.PUBLIC_URL + "/assest/image/home/articlesImg2.png",
        title: "Luxury Articles to Gift",
        description: "Indulge in the art of gifting with our exquisite selection of luxury articles, meticulously curated to elevate any occasion.",
        date: "Jun 02, 2024"
    },
    {
        id: 2,
        imgSrc: process.env.PUBLIC_URL + "/assest/image/Blogs/blogImg1.png",
        title: "Luxury Articles to Gift",
        description: "Indulge in the art of gifting with our exquisite selection of luxury articles, meticulously curated to elevate any occasion.",
        date: "Jun 02, 2024"
    },
    {
        id: 2,
        imgSrc: process.env.PUBLIC_URL + "/assest/image/Blogs/blogImg2.png",
        title: "Luxury Articles to Gift",
        description: "Indulge in the art of gifting with our exquisite selection of luxury articles, meticulously curated to elevate any occasion.",
        date: "Jun 02, 2024"
    },
    {
        id: 2,
        imgSrc: process.env.PUBLIC_URL + "/assest/image/Blogs/blogImg1.png",
        title: "Luxury Articles to Gift",
        description: "Indulge in the art of gifting with our exquisite selection of luxury articles, meticulously curated to elevate any occasion.",
        date: "Jun 02, 2024"
    },

];

const Blogs = () => {

    const [blog, setBlog] = useState([]);
    const getBlog = async () => {
        const res = await blog();
        if (res?.success) {
            setBlog(res?.data)
        }
    }

    useEffect(() => {
        getBlog();
    }, [])

    const items = [
        { id: 1, text: "HOME", link: "/home" },
        { id: 1, text: "BLOG"},
    ]
    return (
        <>
            <section className='Blogs'>
                <div className='container'>
                    <Breadcrumbs items={items} />
                    <div className='row'>
                        <div className='col-lg-9 col-md-7'>
                            <Link to={"/blog-details"}>
                                <div className='row'>
                                    {articlesData.map(article => (
                                        <div className='col-lg-6' key={article.id}>
                                            <div className='articlesImg1'>

                                                <img src={article.imgSrc} alt={article.title} />


                                                <div className='overlay-color'></div>
                                                <div className='imageinfo1'>
                                                    <h3>{article.title}</h3>
                                                    <p>{article.description}</p>
                                                    <Row>
                                                        <Col xl={6}>
                                                            <p>Read More <FontAwesomeIcon icon="fa-solid fa-angle-right" /></p>

                                                        </Col>
                                                        <Col xl={6}>
                                                            <p className='posteddate'>Posted date: Jun 02, 2024</p>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Link>

                            <div className='blogBanner'>
                                <img src={process.env.PUBLIC_URL + "/assest/image/Blogs/blogbanner.png"} className='blogbanimg' />
                                <div className='bannertxt'>
                                    <p>Surprise Your Team with</p>
                                    <h3>Best Corporate Gifts</h3>
                                    <p>with Our Exciting Offers</p>
                                </div>
                            </div>


                            <Row>
                                <div className='col-lg-6'>
                                    <div className='articlesImg1'>
                                        <img src={process.env.PUBLIC_URL + "/assest/image/home/articlesImg2.png"} />
                                        <div className='overlay-color'></div>
                                        <div className='imageinfo1'>
                                            <h3>Luxury Articles to Gift</h3>
                                            <p>Indulge in the art of gifting with our exquisite selection of luxury articles,<br /> meticulously curated to elevate any occasion.</p>
                                            <p>Read More <FontAwesomeIcon icon="fa-solid fa-angle-right" /></p>
                                        </div>

                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='articlesImg1'>
                                        <img src={process.env.PUBLIC_URL + "/assest/image/home/articlesImg2.png"} />
                                        <div className='overlay-color'></div>
                                        <div className='imageinfo1'>
                                            <h3>Luxury Articles to Gift</h3>
                                            <p>Indulge in the art of gifting with our exquisite selection of luxury articles,<br /> meticulously curated to elevate any occasion.</p>
                                            <p>Read More <FontAwesomeIcon icon="fa-solid fa-angle-right" /></p>
                                        </div>

                                    </div>
                                </div>

                            </Row>

                        </div>
                        <div className='col-lg-3 col-md-5'>
                            <div className='recentUpload'>
                                <div className='recentuploadHead'>
                                    <h3>Recent Uploads</h3>
                                </div>
                                <div className='recentBlog'>
                                    {articlesData.map(article => (
                                        <div className='articles2' key={article.id}>
                                            <img src={article.imgSrc} alt={article.title} />
                                            <div className='overlay-color'></div>
                                            <div className='imageinfo3'>
                                                <h3>{article.title}</h3>
                                                <p>{article.description}</p>
                                                <p className='recentedPostedDate'>Posted date: <b>{article.date}</b></p>
                                            </div>
                                        </div>
                                    ))}
                                </div>


                            </div>

                            <div className='blogBanner'>
                                <img src={process.env.PUBLIC_URL + "/assest/image/Offer/OfferImg1.png"} className='blogrecentbanimg' />
                            </div>

                            <div className='blogBanner'>
                                <img src={process.env.PUBLIC_URL + "/assest/image/Offer/OfferImg2.png"} className='blogrecentbanimg' />
                            </div>
                        </div>
                    </div>



                    <Pagination>
                        <Pagination.First />
                        <Pagination.Prev />
                        <Pagination.Item>{1}</Pagination.Item>
                        <Pagination.Ellipsis />

                        <Pagination.Item>{10}</Pagination.Item>
                        <Pagination.Item>{11}</Pagination.Item>
                        <Pagination.Item active>{12}</Pagination.Item>
                        <Pagination.Item>{13}</Pagination.Item>
                        <Pagination.Item disabled>{14}</Pagination.Item>

                        <Pagination.Ellipsis />
                        <Pagination.Item>{20}</Pagination.Item>
                        <Pagination.Next />
                        <Pagination.Last />
                    </Pagination>
                </div>
            </section>
        </>
    )
}

export default Blogs;
