import React, { useState } from 'react';
import { Container, Row, Col, Nav, Tab, NavDropdown, Button } from 'react-bootstrap';
import './MyOrders.css';
import Breadcrumbs from "../Common-Component/Breadcrumbs/Breadcrumbs";
import ProductCard from '../Common-Component/ProductCard/ProductCard';
import { faXmark, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OrdersFilter from '../Common-Component/Offcanvas/OrdersFilter/OrdersFilter';

const orders = [
    {
        id: 1,
        image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
        name: 'Nike Dri-FIT Legend Tee - Men\'s',
        date: '3.JUN.24',
        status: 'Ordered',
        price: '0000'
    },
    {
        id: 2,
        image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
        name: 'White Coffee Mug',
        date: '2.JUN.24',
        status: 'Ordered',
        price: '0000'
    },
    {
        id: 3,
        image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
        name: 'Lather Cover Executive Diary and 2 more items',
        date: '1.JUN.24',
        status: 'Cancelled',
        price: '0000'
    },
    {
        id: 4,
        image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
        name: 'Nike Dri-FIT Legend Tee - Men\'s',
        date: '25.MAY.24',
        status: 'Returned',
        price: '0000'
    },
    {
        id: 5,
        image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
        name: 'Blue T-shirt',
        date: '20.MAY.24',
        status: 'Shipped',
        price: '0000'
    },
    {
        id: 6,
        image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
        name: 'Red Mug',
        date: '19.MAY.24',
        status: 'Cancelled',
        price: '0000'
    },
    {
        id: 7,
        image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
        name: 'Green Diary',
        date: '18.MAY.24',
        status: 'Shipped',
        price: '0000'
    },
    {
        id: 8,
        image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
        name: 'Blue T-shirt',
        date: '17.MAY.24',
        status: 'Delivered',
        price: '0000'
    },
    {
        id: 9,
        image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
        name: 'Red Mug',
        date: '16.MAY.24',
        status: 'Returned',
        price: '0000'
    },
    {
        id: 10,
        image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
        name: 'Red Mug',
        date: '16.MAY.24',
        status: 'Delivered',
        price: '0000'
    },
    {
        id: 11,
        image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
        name: 'Red Mug',
        date: '16.MAY.24',
        status: 'Returned',
        price: '0000'
    }
];

const items = [
    { id: 1, text: "HOME" },
    { id: 2, text: "PROFILE" },
];

const filterOptions = [
    { eventKey: '1', label: 'Shipped' },
    { eventKey: '2', label: 'Delivered' },
    { eventKey: '3', label: 'Cancelled' },
    { eventKey: '4', label: 'Returned' },
];

const timeOptions = [
    { eventKey: '11', label: 'Last 30 Days' },
    { eventKey: '12', label: 'Last 6 Mths.' },
    { eventKey: '13', label: '2023' },
    { eventKey: '14', label: '2022' },
    { eventKey: '15', label: '2020' },
    { eventKey: '16', label: 'Older' },
];

const MyOrders = () => {
    const [activeTab, setActiveTab] = useState('0');
    const [rating, setRating] = useState(0);
    const [activeKeys, setActiveKeys] = useState(['1']);

    const handleRating = (rate) => {
        setRating(rate);
    };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const getTitle = () => {
        const titles = {
            '1': 'Ordered',
            '2': 'Shipped',
            '3': 'Delivered',
            '4': 'Returned',
            '5': 'Cancelled',
            '0': 'All Orders'
        };
        return titles[activeTab];
    };

    const handleSelect = (key) => {
        setActiveKeys(prevKeys =>
            prevKeys.includes(key) ? prevKeys.filter(activeKey => activeKey !== key) : [...prevKeys, key]
        );
    };

    return (
        <section className='Myorders'>
            <Container className="my-4">
                <Row className='mb-4'>
                    <Col xl={6} md={6} xs={6}>
                        <Breadcrumbs items={items} />
                    </Col>
                    <Col xl={6} md={6} xs={6}>
                        <div className='sortingsec'>
                            <NavDropdown
                                title={<p>FILTER <img src={`${process.env.PUBLIC_URL}/assest/image/Careers/bars.png`} className='barsImage' /></p>}
                                id="basic-nav-dropdown"
                            >
                                <div className='filterDropsec'>
                                    <div className='Filterdropdownmain'>
                                        <p className='clearfiltertxt'>Clear Filter</p>
                                        <div className='orderstatus'>
                                            <p><b>ORDER STATUS</b></p>
                                        </div>
                                        <Tab.Container id="left-tabs-example" activeKey={activeKeys} onSelect={handleSelect}>
                                            <Nav variant="pills" className="flex-row filterDrop">
                                                {filterOptions.map(option => (
                                                    <Nav.Item key={option.eventKey}>
                                                        <Nav.Link eventKey={option.eventKey} active={activeKeys.includes(option.eventKey)}>
                                                            {option.label} <span><FontAwesomeIcon icon={activeKeys.includes(option.eventKey) ? faPlus : faXmark} className='x-mark' /></span>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                ))}
                                            </Nav>
                                        </Tab.Container>
                                    </div>
                                    <div className='Filterdropdownmain'>
                                        <div className='orderstatus'>
                                            <p><b>ORDER TIME</b></p>
                                        </div>
                                        <Tab.Container id="left-tabs-example" activeKey={activeKeys} onSelect={handleSelect}>
                                            <Nav variant="pills" className="flex-row filterDrop">
                                                {timeOptions.map(option => (
                                                    <Nav.Item key={option.eventKey}>
                                                        <Nav.Link eventKey={option.eventKey} active={activeKeys.includes(option.eventKey)}>
                                                            {option.label} <span><FontAwesomeIcon icon={activeKeys.includes(option.eventKey) ? faPlus : faXmark} className='x-mark' /></span>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                ))}
                                            </Nav>
                                            <div className='btnMainsec'>
                                                <Button className='cancelbtn'>Cancel</Button>
                                                <Button className='savebtn'>Save</Button>
                                            </div>
                                        </Tab.Container>
                                    </div>
                                </div>
                            </NavDropdown>
                        </div>

                        <div className='filtericonmain text-end'>
                            <FontAwesomeIcon icon="fa-solid fa-filter" onClick={handleShow} />
                        </div>
                    </Col>
                </Row>
                <div className='myorderstitle'>
                    <Row>
                        <Col xxl={2} xl={2} md={2} xs={2}>
                            <h2>{getTitle()} ({orders.length})</h2>
                        </Col>
                        <Col xxl={2} xl={2} md={2} xs={2}>
                            <hr className="divider" />
                        </Col>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="0" onSelect={(k) => setActiveTab(k)}>
                            <Col xxl={8} xl={8} md={8} xs={8}>
                                <Nav variant="pills" className="flex-row orderRow">
                                    {['All Orders', 'Ordered', 'Shipped', 'Delivered', 'Returned', 'Cancelled'].map((label, index) => (
                                        <Nav.Item key={index}>
                                            <Nav.Link eventKey={index.toString()}>{label} ({orders.filter(order => label === 'All Orders' || order.status === label).length})</Nav.Link>
                                        </Nav.Item>
                                    ))}
                                </Nav>
                            </Col>
                            <Col md={12}>
                                <Tab.Content>
                                    {['0', '1', '2', '3', '4', '5'].map((key, index) => (
                                        <Tab.Pane eventKey={key} key={index}>
                                            <Row>
                                                {orders.filter(order => key === '0' || order.status === getTitle()).map(order => (
                                                    <Col xl={3} md={4} xs={6} key={order.id}>
                                                        <ProductCard
                                                            order={order}
                                                            showRating={order.status === 'Delivered'}
                                                            handleRating={handleRating}
                                                            rating={rating}
                                                            review={order.status === 'Delivered' ? "Add Review" : ""}
                                                        />
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Tab.Pane>
                                    ))}
                                </Tab.Content>
                            </Col>
                        </Tab.Container>
                    </Row>
                </div>
            </Container>

            <OrdersFilter placement="start" name={"start"} show={show} handleClose={handleClose} />
        </section>


    );
};

export default MyOrders;
