import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
// import './Login.css';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Link } from 'react-router-dom';
const ForgetPassword = () => {
    const [newpass, setNewpass] = useState(true);
    return (
        <div className="login-container">

            <div className="login-box">
                <div className='logosection'>
                    <img src={process.env.PUBLIC_URL + "/assest/image/logo/header-logo.png"} />
                    <p className='backtohome' onClick={() => setNewpass(true)}>Back</p>


                </div>

                <Row className="justify-content-center">
                    <Col xxl={4} xl={4} md={8}>
                        <div className="login-header">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                                <Nav variant="pills">
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <Nav.Item>
                                                <Nav.Link eventKey="first">Reset Your Password</Nav.Link>
                                            </Nav.Item>

                                        </div>
                                    </div>
                                </Nav>

                                <Tab.Content className='tablogin'>
                                    <Tab.Pane eventKey="first">
                                        {newpass ? (
                                            <>
                                                <div>
                                                    <p className='firstparatxt'>You will receive an email from us requesting you to reset your password.</p>
                                                    <Form className='resetForm'>
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>Enter Email or Mobile Number</Form.Label>
                                                            <Form.Control type="email" placeholder="Email or Mobile Number" />
                                                        </Form.Group>

                                                        <div className='submitbtnsec text-center'>
                                                            <Button type="button" className="btn" onClick={() => setNewpass(false)}>Submit</Button>
                                                        </div>

                                                        <div className='canceltxtsec text-center'>
                                                            <p>Cancel</p>
                                                        </div>

                                                    </Form>

                                                </div>

                                            </>
                                        ) : (
                                            <>
                                                <div>
                                                    <div className='setNewpass'>
                                                        <h3>Set New Password</h3>
                                                    </div>
                                                    <p className='firstparatxt'>Your new password must be different to previously used password.</p>
                                                    <Form className='resetForm'>
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>Enter Password</Form.Label>
                                                            <div className='password-container'>
                                                                <Form.Control type="email" placeholder="Email Password" />
                                                                <span className="password-icon">👁️</span>
                                                            </div>
                                                        </Form.Group>

                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>Confirm New Password</Form.Label>
                                                            <div className='password-container'>
                                                                <Form.Control type="email" placeholder="Enter Password to Confirm" />
                                                                <span className="password-icon">👁️</span>
                                                            </div>
                                                        </Form.Group>

                                                        <div className='submitbtnsec text-center'>
                                                            <Button type="button" className="btn" >Reset Password</Button>
                                                        </div>

                                                        <div className='canceltxtsec text-center'>
                                                            <p>Didn't receive code? <span style={{ color: "#234C9E" }}>Click to resend</span></p>
                                                        </div>

                                                    </Form>

                                                </div>
                                            </>
                                        )}

                                    </Tab.Pane>

                                </Tab.Content>

                            </Tab.Container>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ForgetPassword;
