import React, { useState } from 'react'
import "./Profile.css";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Button, ProgressBar } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Common_Button from '../Common-Component/Common_Button/Common_Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Editproflemodal from '../Common-Component/Commonmodal/Editprofilemodal/Editproflemodal';
import SuccessfullyModal from '../Common-Component/Commonmodal/SuccessfullyModal/SuccessfullyModal';
import AddNewaddress from "../Common-Component/Commonmodal/AddNewaddress/AddNewaddress";
const Profile = () => {
    const [editshow, setEditshow] = useState(false);
    const [newAddress, setNewaddress] = useState(false);
    const [emailModal, setEmailmodal] = useState(false);
    const [passwordModal, setPasswordModal] = useState(false);

    const emailClose = () => {
        setEmailmodal(true);
        setTimeout(() => {
            setEmailmodal(false);
        }, 3000)
    }

    const passwordClose = () => {
        setPasswordModal(true);
        setTimeout(() => {
            setPasswordModal(false);
        }, 3000)
    }

    const navItems = [
        { eventKey: "1", title: "Personal Details" },
        { eventKey: "2", title: "My Orders" },
        { eventKey: "3", title: "Loyalty Points" },
        { eventKey: "4", title: "Manage Address" },
        { eventKey: "5", title: "Update Email Address" },
        { eventKey: "6", title: "Update Password" }
    ];

    const loyaltyCards = [
        { points: 4, progress: 30, imgSrc: "/assest/image/Profile/mittallogo.png", valid: "Invalid" },
        { points: 4, progress: 30, imgSrc: "/assest/image/Profile/mittallogo.png", valid: "SHOP NOW" },
        { points: 4, progress: 30, imgSrc: "/assest/image/Profile/mittallogo.png", valid: "Invalid" }
    ];

    const addresses = [
        { type: "Home", name: "John Doe", address: "E/92 Apmc Market Yard, Turbhe, Vashi, Mumbai , Maharashtra - 400703" },
        { type: "Work", name: "John Doe", address: "E/92 Apmc Market Yard, Turbhe, Vashi, Mumbai , Maharashtra - 400703" }
    ];

    return (
        <>
            <section className='Profile'>
                <div className='img-div'>
                    <img className='prof-top-bannimg' src={process.env.PUBLIC_URL + '/assest/image/Profile/profilebanner.png'} alt="Profile Banner" />
                </div>
                <div className='container'>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                        <Row>
                            <Col lg={3} md={4}>
                                <div className='profilecontainer'>
                                    <div className='top-contt'>
                                        <p>Hello</p>
                                        <h1>Aditya</h1>
                                        <h1>Jagtap</h1>
                                    </div>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="1"><img src={process.env.PUBLIC_URL + "/assest/image/Careers/Personaldetails.png"} className='personalicon' />Personal Details</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link as={Link} to={"/myOrders"} eventKey="2"><img src={process.env.PUBLIC_URL + "/assest/image/Careers/Personaldetails.png"} className='personalicon' />My Orders</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="3"><img src={process.env.PUBLIC_URL + "/assest/image/Careers/token.png"} className='personalicon' />Loyalty Points</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="4"><img src={process.env.PUBLIC_URL + "/assest/image/Careers/Personaldetails.png"} className='personalicon' />Manage Address</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="5"><img src={process.env.PUBLIC_URL + "/assest/image/Careers/Personaldetails.png"} className='personalicon' />Update Email Address</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="6"><img src={process.env.PUBLIC_URL + "/assest/image/Careers/lock.png"} className='personalicon' />Update Password</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </Col>
                            <Col md={8} lg={9}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="1">
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <div className='row personalDetails'>
                                                    <div className='col-lg-4'>
                                                        <p className='detailshead'>Name:</p>
                                                    </div>
                                                    <div className='col-lg-8'>
                                                        <p>Shubhamkumar Yadav</p>
                                                    </div>
                                                </div>
                                                <div className='row personalDetails'>
                                                    <div className='col-lg-4'>
                                                        <p className='detailshead'>Contact: </p>
                                                    </div>
                                                    <div className='col-lg-8'>
                                                        <p>9858596965</p>
                                                    </div>
                                                </div>
                                                <div className='editProfile'>
                                                    <Common_Button className="editbtn" text={"Edit Profile"} onClick={() => setEditshow(true)} />

                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="3">
                                        <div className='loyalitypoint'>
                                            <div className='loyalityhead'>
                                                <h3>Loyalty Points</h3>
                                                <p>Earn loyalty points for every rupee you spend with us.</p>
                                            </div>
                                            <div className='loyalitycardmain'>
                                                <div className='row loyalityrow'>
                                                    {loyaltyCards.map((card, index) => (
                                                        <div className='col-lg-4 col-md-6' key={index}>
                                                            <Card className="loyalitycard">
                                                                <div className='clip'>
                                                                    <img src={process.env.PUBLIC_URL + card.imgSrc} className='mittalImage' alt="Loyalty Card" />
                                                                </div>
                                                                <Card.Body>
                                                                    <div className='loyalitycardbody'>
                                                                        <p className='text-center mb-1'>{card.points}PT</p>
                                                                        <ProgressBar now={card.progress} label={`${card.progress}%`} />
                                                                    </div>
                                                                    <p className='text-center mb-1'>{card.points} Point</p>
                                                                    <p className='invalidtxt mb-0'>{card.valid}</p>
                                                                </Card.Body>
                                                            </Card>
                                                        </div>
                                                    ))}
                                                </div>

                                                <div className='recentTransaction'>
                                                    <h3>RECENT 10 TRANSACTIONS</h3>
                                                    <p>There is no transaction history</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="4">
                                        <div className='ManageAddress'>
                                            <div className='row manageaddressrow'>
                                                {addresses.map((address, index) => (
                                                    <div className='col-lg-6' key={index}>
                                                        <div className='row manageaddresscard'>
                                                            <div className='col-lg-3 col-md-3 col-sm-3 col-3'>
                                                                <div className='loyalimgsection'>
                                                                    <img src={process.env.PUBLIC_URL + "/assest/image/Profile/map.png"} className='' alt="Map Icon" />
                                                                    <FontAwesomeIcon icon="fa-solid fa-location-dot" className="locationdot" />
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-7 col-md-7 col-sm-7 col-7'>
                                                                <div className='loyaladdress'>
                                                                    <h3>{address.type}</h3>
                                                                    <p>{address.name}</p>
                                                                    <span>{address.address}</span>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-2 col-md-2 col-sm-2 col-2 text-end'>
                                                                <Form>
                                                                    <div className="mb-3">
                                                                        <Form.Check name="name" type="radio" id={`radio-${index}`} />
                                                                    </div>
                                                                </Form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}

                                                <div className='addAddressbtn'>
                                                    <Button className='addNewaddress' onClick={() => setNewaddress(true)}>Add New Address</Button>
                                                    <Button className='deleteaddress'>Delete Address</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="5">
                                        <div className='changeEmail'>
                                            <Form>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>New Email Address</Form.Label>
                                                    <Form.Control type="email" placeholder="Enter New Email Address" />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Enter Password to Save</Form.Label>
                                                    <Form.Control type="email" placeholder="Enter Password" />
                                                </Form.Group>

                                                <Button className='updatebtn' onClick={emailClose}>Update</Button>
                                            </Form>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="6">
                                        <div className='changeEmail'>
                                            <Form>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Current Password</Form.Label>
                                                    <div className='formfieldMain'>
                                                        <Form.Control type="email" placeholder="Enter New Email Address" />
                                                        <FontAwesomeIcon icon="fa-solid fa-eye" className='eyeIcon' />
                                                    </div>
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Enter New Password</Form.Label>
                                                    <div className='formfieldMain'>
                                                        <Form.Control type="email" placeholder="Enter Password" />
                                                        <FontAwesomeIcon icon="fa-solid fa-eye" className='eyeIcon' />
                                                    </div>
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Confirm New Password</Form.Label>
                                                    <div className='formfieldMain'>
                                                        <Form.Control type="email" placeholder="Enter Password" />
                                                        <FontAwesomeIcon icon="fa-solid fa-eye" className='eyeIcon' />

                                                    </div>
                                                </Form.Group>

                                                <Button className='updatebtn' onClick={passwordClose}>Update</Button>
                                            </Form>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>

                    <Editproflemodal show={editshow} onHide={() => setEditshow(false)} setEditshow={setEditshow} />
                    <AddNewaddress show={newAddress} onHide={() => setNewaddress(false)} />
                    <SuccessfullyModal show={emailModal} onHide={() => setEmailmodal(false)} text={"Email Updated Successfully"} />
                    <SuccessfullyModal show={passwordModal} onHide={() => setPasswordModal(false)} text={"Password Updated Successfully"} />

                </div>
            </section >
        </>
    )
}

export default Profile;
